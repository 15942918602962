import React, { useEffect, useState } from 'react'
import './LogInPopUp.css'
import BlackX from '../images/icons/blackX.png'
import LeftArrow from '../images/icons/blackLeftArrow.png'
import InputPassword from './form-components/InputPassword'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import useAuth from '../auth/useAuth'
import { jwtDecode } from 'jwt-decode'

const ROLES = {
    User: "ROLE_USER",
    Agency: "ROLE_AGENCY"
}

export default function LogInPopUp(props) {

    const { setAuth, persist, setPersist } = useAuth()
    const navigate = useNavigate()
    const {loginPopup, setLoginPopup, loginSignupPopup, setLoginSignupPopup, userEmail, setUserEmail} = props

    // Form Input Variables
    const [password, setPassword] = useState('')
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [errMsgm, setErrMsg] = useState('')

    // Clearing the Inputs
    useEffect(() => {
        if(!loginPopup && !loginSignupPopup){
            setPassword('')
            setIsPasswordValid(true)
        }
    },[loginPopup,loginSignupPopup])

    const handleLogIn = async (e) => {
        e.preventDefault();

        const formDataLI = JSON.stringify({
            username: userEmail,
            password: password
        })

        try {
            const response = await axios.post( '/auth/login', formDataLI, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            console.log(response.data)

            const accessToken = response.data.data.accessToken;
            
            const decode = jwtDecode(accessToken, process.env.ACCESS_TOKEN_SECRET_KEY)
            console.log("decode: ", decode)

            const roles = decode?.roles || []

            if(response.data.success){

                setAuth({ accessToken })
                
                setLoginPopup(false)
                
                setUserEmail('')
                setPassword('')

                if(roles.includes(ROLES.User)){
                    navigate("/")
                }
                else if(roles.includes(ROLES.Agency)) {
                    navigate("/live/dashboard")
                }
                // window.location.reload(); //TODO: nopt sure if its needed

            }
        } catch (error) {
            console.error(error)

            if(!error?.response) {
                setErrMsg('No Server Response')
            }
            else if(error.response?.status === 400) {
                setErrMsg('Invalid Password')
            }
            else if(error.response?.status === 401) {
                setErrMsg('Unauthorized')
            }
            else {
                setErrMsg('Login Failed')
            }
            setIsPasswordValid(false)
        }
    }

    // ===== use this only if we will implement `trust this connection` checkbox
    const togglePersist = () => {
        setPersist(prev => !prev)
    }

    useEffect(() => {
        localStorage.setItem("persist",persist)
    },[persist])
    // =====

    return (
        loginPopup &&
            <div className='LogInPopUpDarkBackground' onClick={()=>{setLoginPopup(false);setLoginSignupPopup(true)}}>
                <div className='LogInPopUp' onClick={(e) => e.stopPropagation()}>
                    <div className='LogInPopUpHeader'>
                        <div className='LogInPopUpHeaderImg' onClick={()=>{setLoginPopup(false);setLoginSignupPopup(true)}}>
                            <img alt='' src={LeftArrow} />
                        </div>
                        <span className='TwentyPx'>Log In</span>
                        <div className='LogInPopUpHeaderImg' onClick={()=>setLoginPopup(false)}>
                            <img alt='' src={BlackX} />
                        </div>
                    </div>

                    <form 
                        className='LogInPopUpContent'
                        onSubmit={handleLogIn}
                    >
                        <div style={{marginBottom:"2rem"}}>
                            <InputPassword
                                placeholder = 'Password'
                                id = 'password'
                                name = 'password'
                                value = {password}
                                setValue = {setPassword}
                                isValid = {isPasswordValid}
                                setIsValid = {setIsPasswordValid}
                                errorMessage = {errMsgm}
                            />
                        </div>
                        <button className='LogInPopUpContentBtn'>
                            <span>Log In</span>
                        </button>
                    </form>

                    <div className='LogInPopUpFooter'>
                        <span>Forgot your password?</span>
                    </div>
                </div>
            </div>
    )
}
