import React, { useEffect, useState } from 'react'
import './OtpPopUp.css'
import BlackX from '../images/icons/blackX.png'
import { useNavigate } from 'react-router-dom'
import InputNumber from './form-components/InputNumber'
import axios from '../api/axios'

const iconSize = {
    width: "20px",
    height: "20px"
}

export default function OtpPopUp(props) {

    const navigate = useNavigate();
    const {otpPopup, setOtpPopup, path, formData, agencyCase} = props

    const [otpNumber, setOtpNumber] = useState('')
    const [resendDisabled, setResendDisabled] = useState(false)
    const [timer, setTimer] = useState(119); // 2 minutes in seconds
    const [display, setDisplay] = useState(formatTime(timer));

    const infoValidation = {
        otp: otpNumber,
        username: formData.username
    }

    // == CountDown
    // For starting the timer when the user clicks on Resend
    useEffect(() => {
        let intervalId;

        if(resendDisabled){
            intervalId = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 0) {
                        clearInterval(intervalId);
                        setResendDisabled(false)
                        setTimer(119)
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [resendDisabled]);

    // For update the display timer
    useEffect(() => {
        setDisplay(formatTime(timer));
    }, [timer]);

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' + minutes : minutes}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
    }
    //  ==

    // ===== Functions =====
    const handleNext = () => {
        verifyOTP()
    }

    const handleResend = () => {
        setResendDisabled(true)
        signUp()
    }

    const verifyOTP = async () => {
        const urlPart = agencyCase ? 'agencies' : 'user'
        try {
            const response = await axios.post(`/${urlPart}/verifyOtp`, infoValidation);
            console.log(response)

            if(response.data.success){
                setOtpPopup(false)
                // Login function here
            }
        } catch (error) {
            // console.error(error)
            console.log(error.response.data.message)
        }
    }

    const signUp = async () => {
        const urlPart = agencyCase ? 'sendAgency' : 'sendUser'
        try {
            const response = await axios.post(`/otp/${urlPart}`, formData);
            console.log(response.data)

        } catch (error) {
            // console.error(error)
            console.log(error.response.data.message)
        }
    }

    return (
        otpPopup &&
            <div className='OtpPopUpDarkBackground' onClick={()=>setOtpPopup(false)}>
                <div className='OtpPopUp' onClick={(e) => e.stopPropagation()}>
                    <div className='OtpPopUpHeader'>
                        <span className='TwentyPx'>Confirm your email</span>
                        <div className={'OtpPopUpHeaderImg'} onClick={()=>setOtpPopup(false)}>
                            <img alt='' src={BlackX} style={{ width: iconSize.width, height: iconSize.height }} />
                        </div>
                    </div>
                    <div className='OtpPopUpContent'>
                        <span>Enter the code we've sent via email:</span>
                        <div className='OtpPopUpContentOTP'>
                            <InputNumber
                                placeholder = 'Enter the code'
                                id = 'otpNumber'
                                name = 'otpNumber'
                                value = {otpNumber}
                                setValue = {setOtpNumber}
                                width = "15rem"
                                maxNumbers = {6}
                            />
                        </div>
                        <div 
                            className='OtpPopUpContentResend'
                            onClick={()=>!resendDisabled && handleResend()}
                            style={{opacity: resendDisabled && ".7", cursor: resendDisabled && "not-allowed"}}
                        >
                            <span>Resend the code</span>
                            <span style={{marginLeft:"1rem"}}>{resendDisabled && display}</span>
                        </div>
                    </div>
                    <div className='OtpPopUpFooter'>
                        <div className='OtpPopUpFooterBtn' onClick={handleNext}>
                            <span>{"Confirm"}</span>
                        </div>
                    </div>
                </div>
            </div>
    )
}
