import React from 'react'
import './SettingsDetails.css'
import UserMenu from '../components/UserMenu'
import { Outlet } from 'react-router-dom'
import Notification from '../images/icons/notification.png'
import Security from '../images/icons/security.png'
import BlackPerson from '../images/icons/blackperson.png'
import Preferences from '../images/icons/preferences.png'

export default function SettingsDetails() {
    
    const parentUrl = "/account-settings"
    const pages = [
        {
            img: BlackPerson,
            name: "Personal Details",
            url: `${parentUrl}/personal_details`
        },
        {
            img: Preferences,
            name: "Preferences",
            url: `${parentUrl}/preferences`
        },
        {
            img: Security,
            name: "Security",
            url: `${parentUrl}/security`
        },
        {
            img: Notification,
            name: "Email Notifications",
            url: `${parentUrl}/email_notifications`
        },
    ]


    return (
        <div className='SettingsDetails'>
            <UserMenu 
                title = "User Profile Management"
                pages = {pages}
            />

            <Outlet context={{

                            }} 
            />
        </div>
    )
}
