import React from 'react'
import './Search.css'
import SearchBar from './SearchBar'


export default function Search() {
    
    return (
        <div className='Search'>
            <div className='Cover'>
                <div className='Quote'>
                    <span style={{marginBottom:".2rem"}}>Life is an adventure</span>
                    <span>Make the best of it!</span>
                </div>
                <div className='PaddingContent'>
                        
                        <SearchBar 
                            withSelect = {true}
                        />
                    
                </div>
            </div>
        </div>
    )
}