import React from 'react'
import './Administrative.css'
import AdministrativeMenu from '../components/AdministrativeMenu'
import { Outlet } from 'react-router-dom'
import Topbar from '../../components/Topbar'

export default function Administrative() {
    return (
        <div className='Administrative'>

            <div style={{borderBottom: "1px solid rgba(0,0,0,0.1)"}}>
                <Topbar 
                    // setLoginSignupPopup = {setLoginSignupPopup}
                />
            </div>

            <div className='AdministrativeContent'>
                <AdministrativeMenu />

                <div className='AdministrativeRightContent'>
                    <div className='AdministrativeRightContentPanel'>
                        <Outlet context={{
                                        // overviewDetails: overviewDetails,
                                    }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
