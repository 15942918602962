import React from 'react'
import './ReservationSumUp.css'
import Star from '../images/icons/star.png'

export default function ReservationSumUp(props) {

    const { name, cities, countries, currency, days, nights, rate, agency, packagePhoto } = props?.packageInfo
    const { packagePrice } = props

    return (
        <div className='ReservationSumUp'>
            <div className='ReservationSumUpRow'>
                <div className='ReservationSumUpImg'>
                    <img alt='' src={packagePhoto} />
                </div>
                <div className='ReservationSumUpFirstRowContent'>
                    <span style={{fontSize:"18px", fontWeight:"600"}}>{name}</span>
                    <span>{days} {days > 0 ? "days" : "day"} - {nights} {nights > 0 ? "nights" : "night"} - {countries} {countries > 0 ? "Countries" : "Country"} - {cities} {cities > 0 ? "Cities" : "City"}</span>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <img alt='' src={Star} />
                        <span style={{marginLeft:".5rem"}}>{rate?.toFixed(1)}</span>
                    </div>
                    <span>By {agency}</span>
                </div>
            </div>
            <div className='ReservationSumUpSecRow'>
                <span style={{fontSize:"20px",fontWeight:"600",margin:"1.5rem 0"}}>Price details</span>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>Package Price</div>
                    <div>{currency} {packagePrice.toFixed(2)}</div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <span style={{height:"3rem",display:"flex",alignItems:"center",textDecoration:"underline",cursor:"pointer"}}>Holipack fixed service fee</span>
                    <span style={{height:"3rem",display:"flex",alignItems:"center"}}>{currency} 5.00</span>
                </div>
            </div>
            <div className='ReservationSumUpThirdRow'>
                <span>Total (EUR)</span>
                <span>{currency} {(parseInt(packagePrice,10) + 5).toFixed(2)}</span>
            </div>
        </div>
    )
}
