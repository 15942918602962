import React, { useEffect, useState } from 'react';
import './DateRangePicker.css'; // Styles for the component
import LeftArrow from '../images/icons/leftArrow.png'
import RightArrow from '../images/icons/rightArrow.png'

const iconSize = {
    width: "20px",
    height: "20px"
}

const DateRangePicker = (props) => {

    const {startDate, endDate, setStartDate, setEndDate} = props

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [nextMonth, setNextMonth] = useState(getNextMonth(currentMonth));

    // Function to get the next month
    function getNextMonth(date) {
      const nextDate = new Date(date);
      nextDate.setMonth(nextDate.getMonth() + 1);
      return nextDate;
    }

    // Function to handle date selection
    function handleDateSelection(date) {
        if (isBeforeCurrentDay(date)) {
            return; // Prevent selection of disabled dates
        }

        if (!startDate || (startDate && endDate)) {
            // Selecting the start date
            setStartDate(date);
            setEndDate(null);
        } else if (date > startDate) {
            // Selecting the end date
            setEndDate(date);
        } else {
            // Start a new selection if selecting a date before the start date
            setStartDate(date);
            setEndDate(null);
        }
    }

    // Function to check if a date is selected
    function isDateSelected(date) {
        return (
            (startDate && date.toDateString() === startDate.toDateString()) ||
            (endDate && date.toDateString() === endDate.toDateString())
        );
    }

    // Function to check if a date is within the selected range
    function isDateInRange(date) {
        return startDate && endDate && date > startDate && date < endDate;
    }

    // Function to check if a date is before the current day
    function isBeforeCurrentDay(date) {
        const today = new Date();
        return date < today.setHours(0, 0, 0, 0);
    }

    // Function to render the calendar grid
    function renderCalendarGrid(month) {
        const days = [];
        const firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
        const lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        const startDateIndex = firstDay.getDay(); // Index of the first day of the month
        const totalDays = lastDay.getDate(); // Total days in the month

        for (let i = 1; i <= totalDays; i++) {
            const currentDate = new Date(month.getFullYear(), month.getMonth(), i);
            days.push(
                <div
                    key={currentDate.toISOString()}
                    className={`day 
                        ${isDateSelected(currentDate) ? 'selected' : ''} 
                        ${isDateInRange(currentDate) ? 'in-range' : ''} 
                        ${isBeforeCurrentDay(currentDate) ? 'disabled' : ''} 
                        ${startDate && currentDate.toDateString() === startDate.toDateString() ? 'start-date' : ''} 
                        ${endDate && currentDate.toDateString() === endDate.toDateString() ? 'end-date' : ''}`}
                    onClick={() => handleDateSelection(currentDate)}
                >
                    {i}
                </div>
            );
        }

        // Add empty cells for days before the start of the month
        for (let i = 0; i < startDateIndex; i++) {
            days.unshift(<div key={`empty-${i}`} className="empty-cell"></div>);
        }

        return days;
    }

    // Function to handle month navigation
    function handlePrevMonth(e) {
        e.preventDefault();
        e.stopPropagation();

        if(currentMonth.getMonth() === new Date().getMonth() && currentMonth.getFullYear() === new Date().getFullYear())
            return
        
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setCurrentMonth(prevMonth);
        setNextMonth(getNextMonth(prevMonth));
    }

  function handleNextMonth(e) {
    e.preventDefault();
    e.stopPropagation();

    const next = getNextMonth(nextMonth);

    // Check if the next month is within 2 years from now
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 2);
    if (next < maxDate) {
        setCurrentMonth(nextMonth);
        setNextMonth(getNextMonth(nextMonth));
    }
}

    return (
        <div className="date-range-picker">
            <div className="calendar">
                <div className="month">
                    <div className='month-title-btn'>
                        {(currentMonth.getMonth() === new Date().getMonth() && currentMonth.getFullYear() === new Date().getFullYear()) ? 
                          null
                        :
                          <div className='month-btn' style={{left:"-10px"}} onClick={handlePrevMonth}>
                            <img alt='' src={LeftArrow} style={{ width: iconSize.width, height: iconSize.height }}/>
                          </div>
                        }
                        <div className='month-title'>
                            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </div>
                    </div>
                    <div className="days">
                        <div className="day-header">SUN</div>
                        <div className="day-header">MON</div>
                        <div className="day-header">TUE</div>
                        <div className="day-header">WED</div>
                        <div className="day-header">THU</div>
                        <div className="day-header">FRI</div>
                        <div className="day-header">SAT</div>
                        {renderCalendarGrid(currentMonth)}
                    </div>
                </div>
                <div className="month">
                    <div className='month-title-btn'>
                        {(currentMonth.getMonth() === (new Date().getMonth()-1) && currentMonth.getFullYear() === (new Date().getFullYear()+2)) ? 
                        null
                        :
                        <div className='month-btn' style={{right:"-10px"}} onClick={handleNextMonth}>
                            <img alt='' src={RightArrow} style={{ width: iconSize.width, height: iconSize.height }}/>
                        </div>
                        }
                        <div className='month-title'>
                            {nextMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </div>
                    </div>
                    <div className="days">
                        <div className="day-header">SUN</div>
                        <div className="day-header">MON</div>
                        <div className="day-header">TUE</div>
                        <div className="day-header">WED</div>
                        <div className="day-header">THU</div>
                        <div className="day-header">FRI</div>
                        <div className="day-header">SAT</div>
                        {renderCalendarGrid(nextMonth)}
                    </div>
                </div>
            </div>

            {/* <div className="selected-dates">
              <p>Selected Dates:</p>
              <p>Start Date: {startDate ? startDate.toLocaleDateString() : 'None'}</p>
              <p>End Date: {endDate ? endDate.toLocaleDateString() : 'None'}</p>
            </div> */}
        </div>
    );
};

export default DateRangePicker;
