import React, { useEffect, useState } from 'react'
import './Overview.css'
import Pdf from '../images/icons/pdf.png'
import Info from '../images/icons/info.png'
import Highlights from '../images/icons/highlight.png'
import GreenRightArrow from '../images/icons/greenrightarrow.png'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from '../api/axios'

const iconSize = {
    width: "24px",
    height: "24px"
}

const pdfIconSize = {
    width: "30px",
    height: "30px"
}

export default function Overview() {
    
    const navigate = useNavigate();
    const {packageId,dateParam} = useOutletContext()

    const [overviewDetails, setOverviewDetails] = useState({})
    const {overview, places, startingPoints, departureAirport, finalArrivalAirport, finalSubmissionDate, programFile, highlights} = overviewDetails

    const fetchOverviewData = async () => {
        try {

            const response = await axios.get(`/packages/${packageId}/overview?date=${dateParam}`);
  
            setOverviewDetails(response.data);
            // console.log("PackageData", response.data)

        } catch (error) {
            navigate("/")
            console.error(error)
        }
    };

    useEffect(() => {
        fetchOverviewData();
    }, [packageId,dateParam]);

    const handleLinkClick = () => {
        // Open the PDF in a new tab
        window.open(programFile, '_blank');
    };

    const date = finalSubmissionDate ? new Date(finalSubmissionDate) : ""

    const formattedDate = new Intl.DateTimeFormat(undefined, { // undefined -> 'el'
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
        // timeZoneName: 'short',
    }).format(date);

    return (
        <div className='Overview'>
            <span className='OverviewSummary'>
                {overview}
            </span>
            <div className='TourInfoTitle'>
                <span className='TwentyPx Bold' style={{marginRight:"1rem"}}>Tour Info</span>
                <img alt='' src={Info} style={{width: iconSize.width, height: iconSize.height, opacity:"70%"}} />
            </div>
            <div className='TourInfoContent'>
                <div>
                    <span className='Bold' style={{marginRight:"1rem"}}>Place Covered:</span>
                    <span>
                        {places?.length > 0 && places.slice(0, -1).join(" • ") + (places.length > 1 ? " • " : "") + places[places.length - 1]}
                    </span>
                </div>
                <div>
                    <span className='Bold' style={{marginRight:"1rem"}}>Starting Points:</span>
                    <span>
                        {startingPoints?.length > 0 && startingPoints.slice(0, -1).join(" • ") + (startingPoints.length > 1 ? " • " : "") + startingPoints[startingPoints.length - 1]}
                    </span>
                </div>
                <div>
                    <span className='Bold' style={{marginRight:"1rem"}}>Departure Airport:</span>
                    <span>
                        {departureAirport}
                    </span>
                </div>
                <div>
                    <span className='Bold' style={{marginRight:"1rem"}}>Final Arrival Airport:</span>
                    <span>
                        {finalArrivalAirport}
                    </span>
                </div>
                <div>
                    <span className='Bold' style={{marginRight:"1rem"}}>Final Submission Date:</span>
                    <span>
                        {formattedDate}
                    </span>
                </div>
            </div>
            <div className='Document' onClick={handleLinkClick}>
                <img alt='' src={Pdf} style={{width: pdfIconSize.width, height: pdfIconSize.height}} />
                <span style={{marginLeft:"1rem"}}>Program</span>
            </div>
            <div className='TourHighlightsTitle'>
                <span className='TwentyPx Bold' style={{marginRight:"1rem"}}>Tour Highlights</span>
                <img alt='' src={Highlights} style={{width: iconSize.width, height: iconSize.height}} />
            </div>
            <div className='TourHighlightsContent'>
                {
                    highlights?.map((highlight,index) => {
                        return <div 
                                    key={index}
                                    className='TourHighlightsContentRow'
                                >
                                    <img alt='' src={GreenRightArrow} style={{width: iconSize.width, height: iconSize.height}} />
                                    <span style={{marginLeft:"1rem",width:"45rem"}} >{highlight}</span>
                                </div>
                    })
                }
            </div>
        </div>
    )
}
