import axios from "axios";

// const BASE_URL = 'http://localhost:3001'
//const BASE_URL = 'https://Holipack-env.eba-izrzputp.eu-west-1.elasticbeanstalk.com'
const BASE_URL = 'https://domfortest.gr'

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
})