import React, { useState } from 'react'
import './AddPackage.css'
import StepsMenu from '../components/StepsMenu'
import StepDate from '../components/StepDate'
import StepBasicInfo from '../components/StepBasicInfo'
import StepDay from '../components/StepDay'
import StepInclusionsAndNecessary from '../components/StepInclusionsAndNecessary'
import Media from '../components/Media'
import Save from '../../images/icons/save.png'
import Preview from '../../images/icons/preview.png'
import WhiteSave from '../../images/icons/white_save.png'
import WhitePreview from '../../images/icons/white_preview.png'
import Button from '../../components/form-components/Button'
import NotFound from '../../pages/NotFound'

export default function AddPackage() {

    /*
        == Tabs ==
        1. Package Info
        2. Media
    */
    const [currentTab, setCurrentTab] = useState('PackageInfo')
    /* 
        == Steps ==
        1. Basic Info
        2. Dates
            1. Date 1
            2. Date 2
            3. ...
        3. Days
            1. Day 1
            2. Day 2
            3. ...
        4. Inclusions & Necessary
    */
    const [currentStep , setCurrentStep] = useState('BasicInfo')
    const [currentDateIndex , setCurrentDateIndex] = useState(0)
    const [currentDayIndex , setCurrentDayIndex] = useState(0)

    const [saveIsHovered, setSaveIsHovered] = useState(false);
    const [previewIsHovered, setPreviewIsHovered] = useState(false);

    // Child Components Variables
    const [packageInfo, setPackageInfo] = useState({
        title: "",
        overview: "",
        dates: [
            {
                date: "",
                packagePrice: "",
                submissionDate: "",
                days: "",
                nights: "",
                totalSeats: "",
                highlights: [],

                daysInfo: [
                    // {
                    //     shortDesc: "",
                    //     description: "",
                    //     visitedAreas: [],
                    //     destinations: [],
                    //     flights: [
                    //         {
                    //             departureDatetime: "",
                    //             arrivalDatetime: "",
                    //             departureAirport: "",
                    //             arrivalAirport: "",
                    //             airline: "",
                    //             flightCode: ""
                    //         }
                    //     ],
                    //     hotelCheckInDatetime: "",
                    //     hotelCheckOutDatetime: "",
                    //     hotels: [
                    //         {
                    //              hotelId: "",
                    //              hotelName: "",
                    //         }
                    //     ]
                    // }
                ]
            }
        ],
        startingPoints: [
            {
                sp: "",
                extraPrice: ""
            }
        ],
        programFile: "",

        inclusions: [],
        exclusions: [],
        optionals: [],
        necessary: [],
        notes: []
    })
    // console.log(packageInfo.dates)
    // console.log('currentDateIndex:', currentDateIndex, 'currentDayIndex:', currentDayIndex)

    // Mouse Listeners
    const handleSaveMouseEnter = () => {
        setSaveIsHovered(true);
    };
    const handleSaveMouseLeave = () => {
        setSaveIsHovered(false);
    };
    const handlePreviewMouseEnter = () => {
        setPreviewIsHovered(true);
    };
    const handlePreviewMouseLeave = () => {
        setPreviewIsHovered(false);
    };

    const handleSetCurrentStep = (step) => {
        setCurrentStep(step)
    }
    
    return (
        <div className='AddPackage'>
            <div className='AddPackageTabMenu'>
                <div style={{display:"flex"}}>
                    <div 
                        className='AddPackageTab' 
                        onClick={()=>setCurrentTab("PackageInfo")}
                    >
                        Package Info
                    </div>
                    <div 
                        className='AddPackageTab' 
                        onClick={()=>setCurrentTab("Media")}
                    >
                        Media
                    </div>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                    <div 
                        className='AddPackageTabImg' 
                        onMouseEnter={handleSaveMouseEnter} 
                        onMouseLeave={handleSaveMouseLeave}
                    >
                        <img alt='' src={saveIsHovered ? WhiteSave : Save} />
                    </div>
                    <div 
                        className='AddPackageTabImg'
                        onMouseEnter={handlePreviewMouseEnter} 
                        onMouseLeave={handlePreviewMouseLeave}
                    >
                        <img alt='' src={previewIsHovered ? WhitePreview : Preview} />
                    </div>

                    <div style={{marginLeft:"2rem"}}>
                        <Button 
                            height = "2.5rem"
                            name = "Publish"
                        />
                    </div>
                </div>
            </div>
            { 
            currentTab === "PackageInfo" ?
                <div className='AddPackagePackageInfo'>
                    <StepsMenu 
                        currentStep = {currentStep}
                        handleSetCurrentStep = {handleSetCurrentStep}
                        currentDateIndex = {currentDateIndex}
                        setCurrentDateIndex = {setCurrentDateIndex}
                        currentDayIndex = {currentDayIndex}
                        setCurrentDayIndex = {setCurrentDayIndex}

                        dates = { packageInfo.dates }
                    />
                    {
                    currentStep === "BasicInfo" ?
                        <StepBasicInfo 
                            handleSetCurrentStep = {handleSetCurrentStep}
                            setPackageInfo = {setPackageInfo}
                            setCurrentDateIndex = {setCurrentDateIndex}
                            setCurrentDayIndex = {setCurrentDayIndex}

                            title = {packageInfo.title}
                            overview = {packageInfo.overview}
                            dates = {packageInfo.dates}
                            startingPoints ={packageInfo.startingPoints}
                            programFile = {packageInfo.programFile}
                        />
                    :
                    currentStep === "NoDates" ?
                        <div>
                            You must select the date of the travel
                        </div>
                    :
                    currentStep === "Dates" ?
                        <StepDate 
                            handleSetCurrentStep = {handleSetCurrentStep}
                            setPackageInfo = {setPackageInfo}
                            currentDate = {packageInfo.dates[currentDateIndex].date}

                            currentDateIndex = {currentDateIndex}
                            setCurrentDateIndex = {setCurrentDateIndex}
                            setCurrentDayIndex = {setCurrentDayIndex}

                            dateLength = {packageInfo.dates.length}
                            days = { packageInfo.dates[currentDateIndex].daysInfo.length }
                            highlights = {packageInfo.dates[currentDateIndex].highlights}
                        />
                    :
                    currentStep === "Day" ?
                        <StepDay 
                            handleSetCurrentStep = {handleSetCurrentStep}
                            setPackageInfo = {setPackageInfo}
                            currentDate = {packageInfo.dates[currentDateIndex].date}

                            currentDateIndex = {currentDateIndex}
                            setCurrentDateIndex = {setCurrentDateIndex}
                            currentDayIndex = {currentDayIndex}
                            setCurrentDayIndex = {setCurrentDayIndex}

                            dates = { packageInfo.dates }
                            dayInfo = {packageInfo.dates[currentDateIndex].daysInfo[currentDayIndex]}
                        />
                    :
                    currentStep === "Inclusions&Necessary" ?
                        <StepInclusionsAndNecessary 
                            setPackageInfo = {setPackageInfo}
                            inclusions = {packageInfo.inclusions}
                            exclusions = {packageInfo.exclusions}
                            optionals = {packageInfo.optionals}
                            necessary = {packageInfo.necessary}
                            notes = {packageInfo.notes}
                        />
                    :
                        <NotFound />
                    }
                    </div>
            :
                <div className='AddPackageMedia'>
                    <Media />
                </div>
            }

        </div>
    )
}
