import React, { useEffect, useState } from 'react';
import './Autocomplete.css';
import useOutsideClick from '../../utilities/useOutsideClick';
import BlackArrowDown from '../../images/icons/arrowDown.png';
import BlackArrowUp from '../../images/icons/arrowUp.png';
import BlackX from '../../images/icons/blackX.png';
import FontPlus from '../../images/icons/font_color_plus.png';
import ThemePlus from '../../images/icons/theme_color_plus.png';
import Backdrop from '../Backdrop';
import Button from './Button';

export default function Autocomplete(props) {

    const {
        id,
        items = ['item 1', 'item 2', 'item 3', 'item 4', 'item 2', 'item 3', 'item 4', 'item 2', 'item 3', 'item 4', 'item 2', 'item 3', 'item 4', 'item 2', 'item 3', 'item 4', 'item 2', 'item 3', 'item 4', 'item 2', 'item 3', 'item 4'],
        placeholder = 'Search item',
        width = "100%",
        label = null,
        value,
        onChange,
        dropdownImg = true,
        isOpen,
        setOpen,

        addNew = true,
        addNewTitle = "Add New",
        addNewContent,
        handleInitialize = null,
        handleCancel = null,
        handleSubmit = null
    } = props;

    // Internal Variables
    const ref = useOutsideClick(() => {
        setPopupIsOpen(false)
        setOpen && setOpen(id, false)
    });

    const [popupIsOpen, setPopupIsOpen] = useState(isOpen || false);
    const [addNewPopupIsOpen, setAddNewPopupIsOpen] = useState(false);
    const [addNewIsHovered, setAddNewIsHovered] = useState(false);

    const [inputValue, setInputValue] = useState(value || '');

    useEffect(()=> {
        setPopupIsOpen(isOpen || false)
    },[isOpen])
    useEffect(()=> {
        setInputValue(value || '')
    },[value])

    // Functions
    const handleAddNewMouseEnter = () => {
        setAddNewIsHovered(true);
    };

    const handleAddNewMouseLeave = () => {
        setAddNewIsHovered(false);
    };

    const handleOpenAddNewPopup = () => {
        handleInitialize && handleInitialize(inputValue)
        setAddNewPopupIsOpen(true)
    }
    const handleCloseAddNewPopup = () => {
        handleCancel && handleCancel()
        setAddNewPopupIsOpen(false)
    }
    const handleAddNew = () => {
        handleSubmit && handleSubmit()
        handleCloseAddNewPopup()
    }

    const handleSelect = (selectedItem) => {
        setInputValue(selectedItem)
        onChange && onChange(selectedItem)
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;

        setInputValue(newValue);
        onChange && onChange(newValue)
    };


    // Filtering
    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(inputValue.toLowerCase())
    );

    return (
        <div className='Autocomplete' style={{ width: width }}>

            { label && <label style={{ marginBottom: "1rem" }}> {label} </label> }

            <div 
                ref={ref} 
                className='AutocompleteContainer' 
                onClick={() => {
                    setPopupIsOpen(v => !v)
                    setOpen && setOpen(id, !popupIsOpen)
                }}
            >
                <div className='AutocompleteContainerInput'>
                    <input
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={(e) => {
                            e.stopPropagation();
                            setPopupIsOpen(true);
                            setOpen && setOpen(id, true)
                        }}
                    />
                    { dropdownImg &&
                        <img className='AutocompleteImg' alt='' src={(popupIsOpen && items.length > 0) ? BlackArrowUp : BlackArrowDown} />
                    }
                </div>

                { (popupIsOpen && items.length > 0) &&
                    <div className='AutocompletePopup'>
                        <div className='AutocompletePopupContainer'>
                            {filteredItems.map((item, index) => (
                                <span
                                    key={index}
                                    className='AutocompletePopupRow'
                                    onClick={() => handleSelect(item)}
                                >
                                    {item}
                                </span>
                            ))}
                        </div>

                        { addNew &&
                            <div 
                                className='AutocompletePopupRowAddNew'
                                onMouseEnter={handleAddNewMouseEnter} 
                                onMouseLeave={handleAddNewMouseLeave}
                                onClick={handleOpenAddNewPopup}
                            >
                                <div className='AutocompletePopupRowAddNewImg'>
                                    <img alt='' src={ addNewIsHovered ? ThemePlus : FontPlus} />
                                </div>
                                <span>{addNewTitle}</span>
                            </div>
                        }
                    </div>
                }

            </div>

            { addNewPopupIsOpen &&
                <>
                    {addNewPopupIsOpen && <Backdrop />}
                    <div className='AutocompleteAddNewPopup'>
                        <div className='AutocompleteAddNewPopupHeader'>
                            <span className='TwentyPx'>{addNewTitle}</span>
                        </div>
                        <div className='AutocompleteAddNewPopupContent'>
                        {
                            !addNewContent ?
                                <div>
                                    Hello,
                                    <br/>
                                    This is a temp content
                                    <br/>
                                    <br/>
                                </div>
                                :
                                addNewContent
                        }
                        </div>
                        <div className='AutocompleteAddNewPopupFooter'>
                            <div onClick={handleCloseAddNewPopup}>
                                <span style={{textDecoration: 'underline', textUnderlineOffset: '2px', cursor:"pointer"}}>Cancel</span>
                            </div>
                            <Button 
                                name = "Save"
                                handleSubmit = {handleAddNew}
                            />
                        </div>
                    </div>
                </>
            }
            
            
        </div>
    );
}
