import React from 'react'
import Home from '../pages/Home'
import PackageDetails from '../pages/PackageDetails'
import AgentDetails from '../pages/AgentDetails'
import Overview from '../pages/Overview'
import Itinerary from '../pages/Itinerary'
import InclusionsExclusions from '../pages/InclusionsExclusions'
import NecessaryNotes from '../pages/NecessaryNotes'
import PackageReviews from '../pages/PackageReviews'
import { Route, Routes } from 'react-router-dom'
import Packages from '../pages/Packages'
import SignUpPro from '../pages/SignUpPro'
import Dashboard from '../administrative/pages/Dashboard'
import AdminPackages from '../administrative/pages/AdminPackages'
import AgentAbout from '../pages/AgentAbout'
import AgentPackages from '../pages/AgentPackages'
import AgentReviews from '../pages/AgentReviews'
import AgentRefundPolicy from '../pages/AgentRefundPolicy'
import SettingsDetails from '../pages/SettingsDetails'
import PersonalDetails from '../pages/PersonalDetails'
import Preferences from '../pages/Preferences'
import Security from '../pages/Security'
import EmailNotifications from '../pages/EmailNotifications'
import MyPacksDetails from '../pages/MyPacksDetails'
import Wishlist from '../pages/Wishlist'
import Inquiries from '../pages/Inquiries'
import Bookings from '../pages/Bookings'
import Profile from '../pages/Profile'
import Administrative from '../administrative/pages/Administrative'
import AdminReviews from '../administrative/pages/AdminReviews'
import AdminBookings from '../administrative/pages/AdminBookings'
import Clients from '../administrative/pages/Clients'
import AdminSettings from '../administrative/pages/AdminSettings'
import AddPackage from '../administrative/pages/AddPackage'
import Reserve from '../pages/Reserve'
import AppLayout from './AppLayout'
import RequireAuth from '../auth/RequireAuth'
import NotFound from '../pages/NotFound'
import Unauthorized from '../pages/Unauthorized'
import PersistLogin from '../components/PersistLogin'
import AdminHotels from '../administrative/pages/AdminHotels'

const ROLES = {
    User: "ROLE_USER",
    Agency: "ROLE_AGENCY"
}

export default function AppRouter() {
    
    return (
        <Routes>
            <Route element={<PersistLogin />}>
                <Route path="/" element={<AppLayout />} >
                    {/* public routes */}
                    <Route index element={<Home />} />
                    <Route path="/package/:id" element={<PackageDetails />}>
                        <Route index path="overview" element={<Overview />} />
                        <Route path="itinerary" element={<Itinerary />} />
                        <Route path="inclusions&exclusions" element={<InclusionsExclusions />} />
                        <Route path="necessary&notes" element={<NecessaryNotes />} />
                        <Route path="reviews" element={<PackageReviews />} />
                    </Route>
                    <Route path="/reserve/:id" element={<Reserve />} />
                    <Route path="/packages" element={<Packages />} />
                    
                    <Route path="/agencies/:agencyId" element={<AgentDetails />} >
                        <Route index path="about" element={<AgentAbout />} />
                        <Route path="packages" element={<AgentPackages />} />
                        <Route path="reviews" element={<AgentReviews />} />
                        <Route path="refund_policy" element={<AgentRefundPolicy />} />
                    </Route>
                    <Route path="/agent_registration" element={<SignUpPro />} />
                    
                    <Route path="/unauthorized" element={<Unauthorized />} />

                    {/* protected routes */}
                    {/* <Route element={<PersistLogin />}> */}
                        
                        
                        <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Agency]}/>}>
                            <Route path="/profile" element={<Profile />} />

                            <Route path="/account-settings" element={<SettingsDetails />} >
                                <Route index path="personal_details" element={<PersonalDetails />} />
                                <Route path="preferences" element={<Preferences />} />
                                <Route path="security" element={<Security />} />
                                <Route path="email_notifications" element={<EmailNotifications />} />
                            </Route>

                            <Route path="/" element={<MyPacksDetails />} >
                                <Route index path="wishlist" element={<Wishlist />} />
                                <Route path="inquiries" element={<Inquiries />} />
                                <Route path="bookings" element={<Bookings />} />
                            </Route>
                        </Route>
                    {/* </Route> */}

                    {/* catch all */}
                    <Route path="*" element={<NotFound />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Agency]}/>}>
                    <Route path="/live" element={<Administrative />}>
                        <Route index path="dashboard" element={<Dashboard />} />
                        <Route path="packages" element={<AdminPackages />} />
                        <Route path="bookings" element={<AdminBookings />} />
                        <Route path="clients" element={<Clients />} />
                        <Route path="hotels" element={<AdminHotels />} />
                        <Route path="reviews" element={<AdminReviews />} />
                        <Route path="settings" element={<AdminSettings />} />
                        <Route path="newpackage" element={<AddPackage />} />
                    </Route>
                </Route>

            </Route>
        </Routes>
            
    )
}
