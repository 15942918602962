import React, { useEffect, useState } from 'react'
import './PaymentHotels.css'
import Select from './form-components/Select'
import RatingStars from './RatingStars'
import Location from '../images/icons/location.png'
import Button from './form-components/Button'
import Plus from '../images/icons/blackplus.png'
import BlackX from '../images/icons/blackX.png'
import InputNumber from './form-components/InputNumber'

export default function PaymentHotels(props) {

    const {
        selectedHotels,
        setSelectedHotels,

        handleSelectChange,
        
        locationsChoices
    } = props

    console.log("Payment Hotels: ",selectedHotels)

    const handleHotels = (locationIndex,hotelId,roomId) => {

        const newSelectedHotel = {
            selectedHotel: { 
                hotelId: hotelId,
                isHotelValid: true
            },
            selectedRooms: [
                {
                    roomId: roomId,
                    quantity: 1,
                    isQtyValid: true
                }
            ]
        };

        const updatedHotels = [...selectedHotels];

        updatedHotels[locationIndex] = newSelectedHotel

        console.log(updatedHotels)

        setSelectedHotels(updatedHotels);
    }

    const hotelsPerLocation = (hotelList,locationIndex) => {

        const hotelsResult = hotelList.map((hotel,hotelIndex) => {
            const {hotelId, hotelName, rooms, stars} = hotel
            return (
                <div 
                    key={hotelIndex}
                    className={`PaymentHotelsLocationHotelTitle ${(selectedHotels[locationIndex]?.selectedHotel?.hotelId === hotelId) && "PaymentHotelsLocationHotelTitleActive"}` }
                    onClick={()=>handleHotels(locationIndex,hotelId,rooms[0].roomId)}
                >
                    <span className='PaymentHotelsLocationHotelTitleName'>{hotelName}</span>
                    <RatingStars rate={stars} />
                </div>
            )
        })
        
        return hotelsResult
    }

    const roomsPerHotel = (rooms) => {

        const roomsResult = rooms.map((room,index) => {
            const {roomType, roomLayout} = room
            return (roomType.split(',')[0] + " " + roomLayout)
        })
        return roomsResult
    }

    const handleRoomQuantity = (locationIndex, roomId, newQuantity) => {
        const updatedHotels = selectedHotels.map((hotel, idx) => {
            if (idx === locationIndex) {
                const updatedRooms = hotel.selectedRooms.map(room => {
                    if (room.roomId === roomId) {
                        // Update the quantity
                        if(parseInt(newQuantity) >= 1 && !isNaN(parseInt(newQuantity))) // Update the isQtyValid only when is true for showing the invalidity on save
                            return { ...room, quantity: parseInt(newQuantity), isQtyValid: parseInt(newQuantity) >= 1 && !isNaN(parseInt(newQuantity)) };
                        else
                            return { ...room, quantity: parseInt(newQuantity) }
                    }
                    return room;
                });
                return { ...hotel, selectedRooms: updatedRooms }; // Return updated hotel object
            }
            return hotel;
        });
        console.log("qty",updatedHotels)
        setSelectedHotels(updatedHotels);
    }

    const addRoom = (locationIndex,hotel) => {

        let hotelRoomIds = hotel.rooms.map(room => room.roomId);
        let selectedRoomIds = selectedHotels[locationIndex].selectedRooms.map(room => room.roomId);

        let roomIds = hotelRoomIds.filter(roomId => !selectedRoomIds.includes(roomId));

        const newRoom = { 
            roomId: roomIds[0], 
            quantity: 1,
            isQtyValid: true
        };

        // Update the state with the new room added to the specified hotel
        const updatedHotels = selectedHotels.map((hotel, idx) => {
            if (idx === locationIndex) {
                return {
                    ...hotel,
                    selectedRooms: [...hotel.selectedRooms, newRoom]
                };
            }
            return hotel;
        });
        console.log("addRoom",updatedHotels)
        setSelectedHotels(updatedHotels);
    }

    const deleteRoom = (locationIndex,roomIndex) => {

        // console.log("selectedHotels",selectedHotels)
        // console.log("locationIndex,roomId",locationIndex,roomId)

        const updatedHotels = selectedHotels.map((hotel, idx) => {
            if (idx === locationIndex) {
                const updatedRooms = hotel.selectedRooms.filter((_,roomIdx) => roomIdx !== roomIndex);
                console.log("updatedRooms",roomIndex,updatedRooms)

                return  updatedRooms.length>0 ? 
                            { 
                                ...hotel, 
                                selectedRooms: updatedRooms 
                            } 
                        : 
                            {
                                selectedHotel: {
                                    ...hotel.selectedHotel,
                                    hotelId: -1
                                },
                                selectedRooms: []
                            };
            }
            return hotel;
        });

        console.log("updatedHotels",updatedHotels)

        // Set the new state
        setSelectedHotels(updatedHotels);
    }

    return (
        <div className='PaymentHotels'>
            {   
            locationsChoices &&
                locationsChoices.map((locationChoices,locationIndex) => {

                    const {hotelList,hotelsLocation} = locationChoices
                    
                    return (
                        <div 
                            key={locationIndex}
                            className='PaymentHotelsLocation'
                        >
                            <div className='PaymentHotelsLocationName'>
                                <img alt='' src={Location} />
                                <span style={{marginLeft:".5rem"}}>{hotelsLocation}</span>
                            </div>
                            {hotelsPerLocation(hotelList,locationIndex)}

                            { 
                            // Display Error message if the user hasnt picked hotels for all locations
                            !selectedHotels[locationIndex].selectedHotel.isHotelValid &&
                                <div style={{marginBottom:"1rem",fontSize:"12px",color:"red"}}>You need to choose a hotel</div>
                            }
                            
                            {
                            hotelList.map((hotel,hotelIndex) => {
                                return (selectedHotels[locationIndex]?.selectedHotel?.hotelId === hotel.hotelId && 
                                        selectedHotels[locationIndex].selectedRooms.length > 0) && 
                                <div 
                                    key={hotelIndex}
                                    className='PaymentHotelsLocationHotelLine'
                                >
                                    
                                    {selectedHotels[locationIndex].selectedRooms.map((room,roomIndex) => {
                                        const defaultRoomIndex = hotel?.rooms.findIndex(hroom => room.roomId === hroom.roomId)
                                        // console.log("defaultRoomIndex",defaultRoomIndex)
                                        return  <div 
                                                    key={`${locationIndex}-${hotelIndex}-${roomIndex}`}
                                                    className='PaymentHotelsLocationHotelAndQty'
                                                >
                                                    <Select 
                                                        key={`${locationIndex}-${hotelIndex}-${roomIndex}-${defaultRoomIndex}`}
                                                        defaultIndex = {defaultRoomIndex !== -1 ? defaultRoomIndex : 0}
                                                        items = {roomsPerHotel(hotel?.rooms)}
                                                        onSelect = {(selectedIndex) => handleSelectChange(locationIndex, hotelIndex, roomIndex, selectedIndex)}
                                                        width = "75%"
                                                    />
                                                    <InputNumber 
                                                        width = "15%"
                                                        placeholder = "Qty"
                                                        value = {room?.quantity}
                                                        onChange = {(newValue)=>handleRoomQuantity(locationIndex, hotel?.rooms[roomIndex].roomId, newValue)}
                                                        isValid = {room?.isQtyValid}
                                                    />
                                                    <div 
                                                        style={{cursor:"pointer"}}
                                                        onClick={() => deleteRoom(locationIndex,roomIndex)}
                                                    >
                                                        <img alt='' src={BlackX} style={{width:"1rem",height:"1rem"}} />
                                                    </div>
                                                </div>
                                    })}

                                    <Button 
                                        width = "100%"
                                        height = "2rem"
                                        btnClass = "secondary"
                                        name = {<img className='PaymentHotelsLocationHotelBtnIcon' alt='' src={Plus} />}
                                        handleSubmit = {() => (hotel.rooms.length - 1) >= selectedHotels[locationIndex].selectedRooms.length && addRoom(locationIndex, hotel)}
                                    />
                                </div>
                            })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
