import React from 'react'
import './UserMenu.css'
import { NavLink } from 'react-router-dom'

const iconSize = {
    width: "16px",
    height: "16px"
}

export default function UserMenu(props) {

    const {title,pages} = props

    return (
        <div className='UserMenu'>
            <div className='UserMenuTitle Bold TwentyPx'>{title}</div>
            <div className='UserMenuLinks'>
                {
                    pages.map((page,index) => {
                        const {img,name,url} = page
                        return  <NavLink 
                                    key={ index }
                                    to={ url }
                                    className='UserMenuLink'
                                    style={({isActive}) => {
                                        return isActive ? {backgroundColor: "#e7e5e5", fontWeight:"bold", opacity:"1" } : {}
                                    }}
                                >
                                    <img alt='' src={img} style={{ width: iconSize.width, height: iconSize.height,marginRight: ".5rem" }} />
                                    <span>{name}</span>
                                </NavLink>
                    })
                }
            </div>
        </div>
    )
}
