import React from 'react'
import '../pages/InclusionsExclusions.css'

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function InclusionExclusion(props) {

    const {name,icon,items} = props

    return (
        <div className='InclusionsExclusionsGroup'>
            <span className='TwentyPx Bold'>{name}</span>
            <div className='InclusionsExclusionsContent'>
                {items?.map((item,index) => {
                    return  <div 
                                key={index}
                                className='InclusionsExclusionsContentItem'
                            >
                                <img alt='' src={icon} style={{width: iconSize.width, height: iconSize.height}} />
                                <span style={{marginLeft:"1.5rem"}}>{item}</span>
                            </div>
                })
                }
                
            </div>
        </div>
    )
}
