import React from 'react'
import './StatBox.css'

export default function StatBox(props) {

    const {icon, bgColor, statNumber, statTitle} = props

    return (
        <div className='StatBox'>
            <div className='StatBoxImg' style={{backgroundColor: bgColor}}>
                <img alt='' src={icon} />
            </div>

            <div className='StatBoxStat'>
                <span className='Bold' style={{fontSize:"36px"}}>{statNumber}</span>
                <span className='FourteenPx'>{statTitle}</span>
            </div>
        </div>
    )
}
