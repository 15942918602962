import React from 'react'
import './Filters.css'
import FiltersImg from '../images/icons/filters.png'

export default function Filters() {
    return (
        <div className='Filters'>
            <div className='FiltersBtn'>
                <img alt='' src={FiltersImg} />
                <span>Filters</span>
                {false && <div className='FiltersBtnNotification'> 1 </div>}
            </div>
        </div>
    )
}
