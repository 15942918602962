import React, { useEffect, useState } from 'react'
import './PackageDetailsPhotos.css'
import ImageSlider from './ImageSlider';
import './ImageSlider.css'
import axios from '../api/axios';

export default function PackageDetailsPhotos(props) {

    const { packageId } = props
    // const packagePhotos = packageData ? packageData.packagePhotos : undefined;
    // console.log(packagePhotos)
    const [packagePhotos, setPackagePhotos] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const getPhotos = async () => {
        try {
            const response = await axios.get(`/packages/${packageId}/photos`)
            
            // console.log("Package Photos:",response.data)
            setPackagePhotos(response.data.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getPhotos()
    }, [packageId]);


    const openPopup = (index) => {
        setSelectedImage(index);
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const nextImage = () => {
        setSelectedImage((prevIndex) => (prevIndex + 1) % packagePhotos.length);
    };

    const prevImage = () => {
        setSelectedImage((prevIndex) =>
        prevIndex === 0 ? packagePhotos.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className='PackageDetailsPhotos'>
            <div className='MainPhoto'>
                {
                    packagePhotos && packagePhotos[0] !== '' ?
                        <img alt='' src={packagePhotos[0]} className='MainPhotoImg' onClick={()=>openPopup(0)}/>
                    :
                        <div className='MainPhotoImg' style={{ border: "1px solid rgba(0, 0, 0, 0.10)" }}></div>
                }
            </div>
            <div className='FourPhotos'>
            { 
                Array.from({ length: 4 }).map((_, index) => (
                    packagePhotos &&
                    (packagePhotos.length-1) > index ? 
                    <img key={index} alt='' src={packagePhotos[index+1]} className='FourPhotosImg' onClick={()=>openPopup(index+1)} />
                    :
                    <div key={index} className='FourPhotosImg' style={{ border: "1px solid rgba(0, 0, 0, 0.10)"}} ></div>
                ))
            }
            </div>
            
            <ImageSlider
                images={packagePhotos}
                showPopup={showPopup}
                selectedImage={selectedImage}
                closePopup={closePopup}
                nextImage={nextImage}
                prevImage={prevImage}
            />
        </div>
    )
}
