import React, { useEffect, useState } from 'react'
import './PackageDetails.css'
import PackageDetailsPhotos from '../components/PackageDetailsPhotos'
import PackageDetailsCard from '../components/PackageDetailsCard'
import PackageDetailsPages from '../components/PackageDetailsPages'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import axios from '../api/axios'

export default function PackageDetails() {

    // const navigate = useNavigate();
    const { id } = useParams()

    // const [searchParams, setSearchParams] = useSearchParams();
    // const dateParam = searchParams.get('date');

    

    return (
        <div className='PackageDetails'>
            <div className='PhotosAndCard'>
                <PackageDetailsPhotos 
                    packageId = {id}
                />
                <PackageDetailsCard
                    packageId = {id}
                />
            </div>
            <PackageDetailsPages 
                packageId = {id}
            />
        </div>
    )
}
