import React, { useEffect, useState } from 'react'
import './InputTextArea.css'

export default function InputTextArea(props) {
    const { 
        label = null, // name of label
        placeholder = '', 
        id,
        name,
        value,
        onChange,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false,
    } = props

    const [internalValue, setInternalValue] = useState(value || '')

    useEffect(() => {
        setInternalValue(value || '')
    },[value])

    const handleChange = (e) => {
        const newValue = e.target.value;

        setInternalValue(newValue)

        onChange && onChange(newValue)
    }

    return (
        <div className='InputTextArea' style={{width: width}}>
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}
            <textarea
                id={id}
                placeholder={placeholder}
                name={name}
                value={internalValue}
                onChange={handleChange}
                disabled={disabled}
                style={{height: height}}
            >
            </textarea>
        </div>
    )
}
