import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../auth/useRefreshToken";
import useAuth from "../auth/useAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                // if (err.response && err.response.status === 401) {
                //     // Do nothing or handle silently
                // } else {
                //     console.error(err);
                // }
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    return (
        <>
            {/* <Outlet /> */}
            {!persist
                ? <Outlet />
                : isLoading
                    ? <></>//<p>Loading...</p>
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin