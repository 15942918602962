import React, { useEffect, useState } from 'react'
import './InputNumber.css'
import CountryCode from '../CountryCode'

export default function InputNumber(props) {

    const { 
        label = null, // name of label
        placeholder = '', 
        id,
        name,
        value,
        onChange,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false,
        isTelephoneNumber = false,
        maxNumbers = 0,
        // Validation Variables
        isValid = true,
        setIsValid,
        errorMessage = "The field is required"
    } = props

    const [internalValue, setInternalValue] = useState(value || '');

    useEffect(()=>{
        setInternalValue(value || '')
    },[value])

    const handleChange = (e) => {
        let newValue = e.target.value;
        if (maxNumbers !== 0) {
            newValue = e.target.value.slice(0, maxNumbers);
        }
        setInternalValue(newValue);
        // setIsValid && setIsValid(true); // ??

        onChange && onChange(newValue); // Call the onChange callback with the new value
    };

    return (
        <div className='InputNumberAndLabel' style={{width: width}}>
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}
            <div className='InputNumberRow'>
                {
                    isTelephoneNumber && <CountryCode />
                }
                <div className='InputNumber' style={{height: height, borderColor: isValid ? 'rgba(0, 0, 0, 0.2)' : 'red' }}>
                    <input 
                        type='number'
                        maxLength={6}
                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                        id={id}
                        placeholder={placeholder}
                        name={name}
                        value={internalValue}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    {!isValid && <div className='InputNumberErrorMessage'>{errorMessage}</div>}
                </div>
            </div>
        </div>
    )
}
