import React, { useEffect, useState } from 'react'
import './DayTour.css'
import ArrowUp from '../images/icons/arrowUp.png'
import ArrowDown from '../images/icons/arrowDown.png'
import DayTourFlight from './DayTourFlight'
import DayTourHotels from './DayTourHotels'
import DayTourOverview from './DayTourOverview'

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function DayTour(props) {

    const { dayDetails,daysLength,currDay,setCurrDay } = props
    const { day, title, flightList, hotelList, hotelsCheckInDate, hotelsLocation, hotelsNights, description } = dayDetails

    const [expanded, setExpanded] = useState(true);

    useEffect(()=>{
        if(currDay === day)
            setExpanded(true)
        else
            setExpanded(false)
    },[currDay,day])

    const daySelection = () => {
        setExpanded(v=>!v)
        setCurrDay(day)
    }

    return (
        <div className={`DayTour ${expanded ? 'expanded' : ''}`}>
            <div className='DayTourHeader' onClick={daySelection}>
                <div className='DayTourHeaderTitle'>
                    <span className='Bold'>DAY {day}:</span>
                    <span style={{marginLeft:".5rem"}}> {title} </span>
                </div>
                <img alt='' src={expanded ? ArrowUp : ArrowDown} style={{width: iconSize.width, height: iconSize.height}} />
            </div>
            <div className={`DayTourContent ${expanded ? 'expanded' : ''}`}>

                {flightList
                    .sort((a, b) => a.flightOrder - b.flightOrder)
                    .map((flight,index) => {
                        // const rotateAirplane = (daysLength === 1 && index === flightList.length) ? true : (daysLength > 1 && day === daysLength && index === flightList.length ) ? true : false

                        return  <DayTourFlight 
                                    key = {index}
                                    flight = {flight}
                                />
                    })
                }
                <DayTourHotels 
                    hotelList = {hotelList}
                    hotelsCheckInDate = {hotelsCheckInDate}
                    hotelsLocation = {hotelsLocation}
                    hotelsNights = {hotelsNights}
                />
                <DayTourOverview 
                    description = {description}
                />
            </div>
            <div className={`DayTourFooter ${expanded ? 'expanded' : ''}`} >
                <div className='NextDayBtn' style={{marginRight:"1rem"}}>
                    <span>PREVIOUS DAY</span>
                </div>
                <div className='NextDayBtn' onClick={()=>setCurrDay(currDay+1)}>
                    {daysLength !== currDay ?
                        <span>NEXT DAY</span>
                    :
                        <span>END OF TOUR</span>
                    }
                </div>
                <div className='NextDayBtn' style={{marginLeft:"1rem"}}>
                    <span>Book Now</span>
                </div>
            </div>
        </div>
    )
}
