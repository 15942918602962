import React from 'react'
import './DestinationPopUp.css'
import Location from '../images/icons/location.png'
import Europe from '../images/europe.png'

export default function DestinationPopUp(props) {

    const { popupIsOpen, setPopupIsOpen, popularDestinations, destination, destinations, setDestination, setPlaceId } = props

    const continents = ['Asia', 'Europe', 'America', 'Africa', 'Antarctica', 'Australia']

    return (
        popupIsOpen &&
            <>
                {
                !destination ?
                    <div className='DestinationPopUp DestinationPopUpFirst'>
                        <div className='PopularDestinations'>
                            <span className='DestinationsTitle'>Popular Destinations</span>
                            {
                                popularDestinations.map((dest,index)=>{
                                    const {city,country} = dest
                                    return  <div 
                                                key={index}
                                                className='PopularDestinationItem'
                                            >
                                                <div className='PopularDestinationItemImg'>
                                                    <img alt='' src={Location}/>
                                                </div>
                                                <div className='PopularDestinationItemTitles'>
                                                    <span className='PopularDestinationItemTitle'>{city}</span>
                                                    <span className='PopularDestinationItemSubTitle'>{country}</span>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                        <div className='GeneralDestinations'>
                            <span className='DestinationsTitle'>Continents</span>
                            <div className='GeneralDestinationItems'>
                                {
                                    continents.map((cont,index) => {
                                        return  <div 
                                                    key={index}
                                                    className='GeneralDestinationItem'
                                                >
                                                    <div className='GeneralDestinationItemImg'>
                                                        <img alt='' src={Europe}/>
                                                    </div>
                                                    <span>{cont}</span>
                                                </div>
                                    })
                                
                                }
                            </div>
                        </div>
                    </div>
                :
                    <div className='DestinationPopUp DestinationPopUpSecond'>

                        {
                            destinations.map((dest,index) => {

                                const {placeId, placeName} = dest


                                const parts = placeName.split(', ')

                                const lastCommaIndex = placeName.lastIndexOf(", ");
                                const firstElem = lastCommaIndex === -1 ? parts[0] : placeName.substring(0, lastCommaIndex).replace(/"/g, '') // The replace is for removing the quotes
                                
                                const secElem = parts.length !== 1 ? parts[parts.length-1] : null
                                
                                return  <div 
                                            key={index}
                                            className='PopularDestinationItem'
                                            onClick={()=>{setDestination(placeName);setPlaceId(secElem ? placeId : firstElem);setPopupIsOpen(false)}}
                                        >
                                            <div className='PopularDestinationItemImg'>
                                                <img alt='' src={Location}/>
                                            </div>
                                            <div className='PopularDestinationItemTitles'>
                                                <span className='PopularDestinationItemTitle'>{firstElem}</span>
                                                {secElem && <span className='PopularDestinationItemSubTitle'>{secElem}</span>}
                                            </div>
                                        </div>
                            })
                        }

                    </div>
                }
            </>
    )
}
