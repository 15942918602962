import React, { useState } from 'react'
import './StepDate.css'
import InputNumber from '../../components/form-components/InputNumber'
import Button from '../../components/form-components/Button'
import NewLineTable from './NewLineTable'
import DatePicker from '../../components/form-components/DatePicker'

export default function StepDate(props) {

    const {
        currentDateIndex,
        setCurrentDateIndex,
        setCurrentDayIndex,
        currentDate,
        handleSetCurrentStep,
        setPackageInfo,
        dateLength,
        days,
        highlights
    } = props

    // Handling Days
    const handleSetDays = (newValue) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];

            const updatedDaysInfo = []

            Array.from({ length: parseInt(newValue,10) }, (_, i) => {
                updatedDaysInfo.push({
                    shortDesc: "",
                    description: "",
                    visitedAreas: [],
                    destinations: [],
                    flights: [
                        {
                            departureDatetime: "",
                            arrivalDatetime: "",
                            departureAirport: "",
                            arrivalAirport: "",
                            airline: "",
                            flightCode: ""
                        }
                    ],
                    hotels: [
                        {
                            hotelName: "",
                            checkInDatetime: "",
                            checkOutDatetime: ""
                        }
                    ]
                })
            })

            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                days: newValue,
                daysInfo: updatedDaysInfo
            };
            return { ...prev, dates: updatedDates };
        });
    }

    // Handling Highlights
    const handleAddHighlight = (newValue) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedHighlights = [...updatedDates[currentDateIndex].highlights, newValue];
            
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                highlights: updatedHighlights
            };
            
            return { ...prev, dates: updatedDates };
        });
    }

    const handleRemoveHighlight = (index) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedHighlights = updatedDates[currentDateIndex].highlights.filter((_, idx) => idx !== index);
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                highlights: updatedHighlights
            };
            return { ...prev, dates: updatedDates };
        });
    }

    // Check Validations to continue
    const checkValidationFormInputs = () => {
        
        // Days validation 
        let isDaysValid = days > 0 && days <= 30 // TODO: Check how many days will be as maximum

        
        let nextStep;
        if(isDaysValid){
            nextStep = 'Day'
            setCurrentDayIndex(0)
        } else if (currentDateIndex+1 < dateLength){
            nextStep = 'Dates'
            setCurrentDateIndex(prev => prev+1)
        } else {
            nextStep = 'Inclusions&Necessary'
            setCurrentDateIndex(0)
            setCurrentDayIndex(0)
        }
        
        handleSetCurrentStep(nextStep)
    }

    return (
        <div className='StepDate'>
            <div className='StepDateTitle'>
                {`Date ${currentDateIndex + 1}${currentDate ? ` - ${currentDate}` : ''}`}
            </div>

            <form>
            <div style={{marginTop:"1.5rem"}}>
            <InputNumber
                label = "Full Package Price"
                placeholder = "Full Package Price"
            />
            </div>

            <div style={{marginTop:"1.5rem"}}>
            <DatePicker 
                label = "Final submission date"
                // value = {item}
                // onChange = {(value)=>handleSetDate(value, index)}
                // isValidDate = {}
                // setIsValidDate = {}
            />
            </div>
            
            <div style={{marginTop:"1.5rem",display:"flex",alignItems:"center"}}>
                <div style={{marginRight:".5rem",width:"100%"}}>
                    <InputNumber 
                        label = "Days"
                        placeholder = "Days"
                        value = {days}
                        onChange = {(newValue) => handleSetDays(newValue)}
                    />
                </div>
                <div style={{marginLeft:".5rem",width:"100%"}}>
                    <InputNumber 
                        label = "Nights"
                        placeholder = "Nights"
                    />
                </div>
            </div>

            <div style={{marginTop:"1.5rem"}}>
            <InputNumber
                label = "Total Seats"
                placeholder = "Total Seats"
            />
            </div>
            
            <div style={{marginTop:"1.5rem"}}>
                <NewLineTable 
                    placeholder = "Highlights"
                    label = "Highlights"
                    items = {highlights}
                    handleAdd = {handleAddHighlight}
                    handleRemove = {handleRemoveHighlight}
                />
            </div>

            <div style={{marginTop:"5rem",display:"flex",justifyContent:"flex-end"}}>
                <Button 
                    name = "NEXT"
                    btnClass = "dark"
                    handleSubmit = {checkValidationFormInputs}
                />
            </div>
            </form>

        </div>
    )
}
