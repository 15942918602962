import React, { useState } from 'react'
import './InputEmail.css'

export default function InputEmail(props) {

    const { 
        label = null, // name of label
        icon = '',
        placeholder = '', 
        id,
        name,
        value,
        setValue,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false,
        
        // Validation Variables
        isValid = true,
        setIsValid,
        errorMessage = "Valid email is required"
    } = props

    return (
        <div className='InputEmailAndLabel' style={{width: width}}>
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}
            <div className='InputEmail' style={{ height: height, borderColor: isValid ? 'rgba(0, 0, 0, 0.2)' : 'red' }}>
                {icon && 
                <div className='InputEmailImg'>
                    <img alt='' src={icon} />
                </div>
                }
                <input 
                    type='email' 
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={(e)=>{setValue(e.target.value);setIsValid && setIsValid(true);}}
                    disabled={disabled}
                    style={{paddingLeft: icon && "0"}}
                />
                {!isValid && <div className='InputEmailErrorMessage'>{errorMessage}</div>}
            </div>
        </div>
    )
}
