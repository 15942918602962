import React from 'react'
import './Reviews.css'
import ReviewStatistics from './ReviewStatistics'
import OrderBy from './OrderBy'
import ReviewItem from './ReviewItem'

export default function Reviews(props) {

    const {rate, ratingsCountResponseList, reviews, reviewTypeName} = props

    return (
        <div className='Reviews'>

                <ReviewStatistics 
                    rate = {rate}
                    ratingsCountResponseList = {ratingsCountResponseList}
                    reviewTypeName = {reviewTypeName}
                />
                
                <OrderBy />

                <div className='ReviewsGroup'>
                    { 
                        reviews?.map((review,index) => {
                            return  <ReviewItem 
                                        key = {index}
                                        review = {review}
                                    />  
                        })
                    }

                    <div>
                        <span className='ReviewsGroupBtn'>Show More</span>
                    </div>

                </div>

            </div>
    )
}
