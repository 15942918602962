import React from 'react'
import './AgentOffice.css'

export default function AgentOffice(props) {

    const { index } = props
    const { countryCode, phone, email, floor, workingHours, country, city, streetAddress, streetNumber, postalCode, state } = props.office

    return (
        <div className='AgentOffice'>
            <span className='Bold' style={{width:"100%", textAlign:"center",marginBottom:"1rem"}}>{index === 0 ? "MAIN OFFICE" : ("OFFICE " + state)}</span>
            <span style={{marginBottom:"1rem"}}>{streetAddress} {streetNumber}, ZIP: {postalCode}, {city} {country}</span>
            <span style={{marginBottom:"1rem"}}>Floor: {floor}</span>
            <span style={{marginBottom:"1rem"}}>Open Hours: {workingHours}</span>
            <span style={{marginBottom:"1rem"}}>T: {countryCode} {phone}</span>
            <span>Email: {email}</span>
        </div>
    )
}
