import React, { useState } from 'react'
import './PaymentDates.css'
import { formatDate } from '../utilities/dateUtils'

export default function PaymentDates(props) {

    const {
        dates = ["13/10/2023", "21/11/2021", "11/03/2021", "22/03/2021", "08/03/2021"],
        selectedDate,
        handleSelectedDate
    } = props

    const [internalSelectedDate, setInternalSelectedDate] = useState(selectedDate);

    const handleDateClick = (date) => {
        setInternalSelectedDate(date);
        handleSelectedDate && handleSelectedDate(date);
    };

    return (
        
        <div className='PaymentDates'>
            {
                dates && dates.map((item,index) => {
                    return  <div 
                                key={index}
                                className={'PaymentDateBox ' + (internalSelectedDate === item ? "PaymentDateBoxSelected" : "PaymentDateBoxDefault")}
                                onClick={()=>handleDateClick(item)}
                            >
                                {formatDate(item)}
                            </div>
                })
            }
        </div>
    )
}
