import React, { useEffect, useState } from 'react'
import './Bookings.css'
// image imports
import { Link, useNavigate } from 'react-router-dom';
import Heart from '../images/icons/heart.png'
import FilledHeart from '../images/icons/filledheart.png'
import StarRate from '../images/icons/star.png'
import { formatDate } from '../utilities/dateUtils';
import useAxiosPrivate from '../auth/useAxiosPrivate';
import useAuth from '../auth/useAuth';

// image variables
const heartIconSize = {
    width: "24px",
    height: "24px"
}
const starIconSize = {
    width: "16px",
    height: "16px"
}

export default function Bookings() {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth()

    const [bookings, setBookings] = useState([])
    const [loading, setLoading] = useState(true);

    // image variables
    const [liked, setLiked] = useState(false)
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get(`/packages/searchByFilters?page=1&pageSize=16`);

            setBookings(response.data);

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [auth?.accessToken]);

    // image functions
    const handleClickAgent = (agencyId) => {
        navigate(`/agencies/${agencyId}/about`)
    }

    const handleClickLike = () => {
        // if(!loggedIn)
        // setLoginSignupPopup(true)
        // else
        // setLiked(v=>!v)
    }

    const handleClickPackage = (event) => {
        event.preventDefault();

        if (event.target.closest('.BookingLike')) {
            event.preventDefault();
        } else if (event.target.closest('.BookingAgentLogoContainer')) {
            event.preventDefault();
        } else {
            const link = event.currentTarget;
            window.open(link.href, '_blank');
        }
    }

    return (
        <div className='Bookings'>
            <div className='BookingsHeader'>
                <span className='TwentyPx Bold'>Bookings</span>
            </div>

            <div className='BookingsContent'>
                {bookings.map((booking,index) => {
                    
                    const {packageId, date, packagePhoto, agencyPhoto, rate, days, nights, name, priceFrom, currency, agencyId} = booking

                    return  <div 
                                key={index}
                                className='BookingContainer'
                            >
                                {/* image */}
                                <Link 
                                    to={`/package/${packageId}/overview?date=${date}`} 
                                    className='BookingPhotoContainer' 
                                    onClick={handleClickPackage}
                                >
                                    <img className='BookingPhoto' alt='' src={packagePhoto} />
                                    <div className='BookingPhotoContent'>
                                        <div 
                                            className='BookingAgentLogoContainer'
                                            onClick={() => handleClickAgent(agencyId)}
                                        >
                                            <img className='BookingAgentLogo' alt='' src={agencyPhoto} />
                                        </div>
                                        <div className='BookingLike' onClick={handleClickLike}>
                                            <img alt='' src={ !liked ? Heart : FilledHeart } style={{width: heartIconSize.width, height: heartIconSize.height}} />
                                        </div>
                                    </div>
                                </Link>
                                {/* -- */}
                                <div className='BookingContainerInfo'>
                                    <div className='BookingContainerInfoColumn'>
                                        <span className='Bold' style={{marginBottom:".5rem",width:"fit-content"}}>{name}</span>
                                        <div className='Rate'>
                                            <img alt='' src={StarRate} style={{width: starIconSize.width, height: starIconSize.height}} />
                                            <span>{rate.toFixed(1)}</span>
                                        </div>
                                    </div>
                                    <div className='BookingContainerInfoBottom'>
                                        <div className='BookingContainerInfoColumn'>
                                            <div style={{marginBottom:".5rem"}}>
                                                <span style={{marginRight:".3rem"}}>From</span>
                                                <span className='Bold'>{priceFrom?.toString()}{" " + currency && currency}</span>
                                            </div>
                                            <span className='FourteenPx'>{days} days • {nights} nights</span>
                                        </div>
                                        <div className='BookingContainerInfoColumn' style={{alignItems:"center"}}>
                                            <div className='BookingContainerInfoLabel'>Booked on</div>
                                            {true ? formatDate(date) : <span className='FourteenPx Bold' style={{color:"#DA2828"}}>EXPIRED</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                })
                }
            </div>
        </div>
    )
}
