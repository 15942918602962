import React from 'react'
import './Button.css'

export default function Button(props) {

    const {
        icon = "",
        id,
        name = "Button",
        type = "button",
        btnClass = "primary",
        height = null,
        width = null,
        disabled = false,
        handleSubmit = null,
    } = props

    const buttonClass = (btnClass === "primary" || btnClass === "secondary" || btnClass === "dark" || btnClass === "danger") ? btnClass : "primary"

    return (
        <button 
            id={id}
            className={`Button ${buttonClass}`}
            type={type} 
            style={{
                width: width ? width : "max-content",
                height: height ? height: "max-content",
                padding: ((!height && width) ? "1rem 0" : (!width && height) ? "0 1.5rem" : (!height && !width) ? "1rem 1.5rem" : "0")
            }}
            disabled = {disabled}
            onClick={handleSubmit}
        >
            {
            icon ? 
                <img alt='' src={icon} className='ButtonIcon'/> 
                : 
                name
            }
        </button>
    )
}
