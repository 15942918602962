import React, { useEffect, useState } from 'react'
import '../pages/Home.css'
import Search from '../components/Search'
import PackagesCarousel from '../components/PackagesCarousel'
import axios from '../api/axios'
import SearchBar from '../components/SearchBar'
import { useOutletContext } from 'react-router-dom'
import useAuth from '../auth/useAuth'
import useAxiosPrivate from '../auth/useAxiosPrivate'

export default function Home() {

    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate();

    const { setLoginSignupPopup } = useOutletContext()

    const [topRated, setTopRated] = useState([])
    const [offers, setOffers] = useState([])
    const [agents, setAgents] = useState([])

    const [loadingPackages, setLoadingPackages] = useState(true);
    const [loadingOffers, setLoadingOffers] = useState(true);
    const [loadingAgents, setLoadingAgents] = useState(true);

    useEffect(() => {


        const fetchData = async () => {
            // Top Rated API Call
            try {
                const response = await axios.get('/packages/topRated?pageSize=7&page=1');
                setTopRated(response.data);
                setLoadingPackages(false);
            } catch (error) {
                console.error("TopRated " + error)
            }
            // Top Offers API Call
            try {
                const responseO = await axios.get('/packages/topOffers?pageSize=7&page=1');
                setOffers(responseO.data)
                setLoadingOffers(false);
            } catch (error) {
                console.error("TopOffers " + error)
            }
            // Agents API Call
            try {
                const responseA = await axios.get('/agencies/topRated');
                setAgents(responseA.data)
                setLoadingAgents(false);
            } catch (error) {
                console.error("Agents " + error)
            }

        };

        const fetchDataPrivate = async () => {

            setLoadingPackages(true);
            setLoadingOffers(true);
            setLoadingAgents(true);

            setTopRated([]);
            setOffers([]);
            setAgents([]);

            // Top Rated API Call
            try {
                const response = await axiosPrivate.get('/packages/topRated?pageSize=7&page=1');
                setTopRated(response.data);
                setLoadingPackages(false);
            } catch (error) {
                console.error("TopRated " + error)
            }
            // Top Offers API Call
            try {
                const responseO = await axiosPrivate.get('/packages/topOffers?pageSize=7&page=1');
                setOffers(responseO.data)
                setLoadingOffers(false);
            } catch (error) {
                console.error("TopOffers " + error)
            }
            // Agents API Call
            try {
                const responseA = await axiosPrivate.get('/agencies/topRated');
                setAgents(responseA.data)
                setLoadingAgents(false);
            } catch (error) {
                console.error("Agents " + error)
            }

        };

        auth?.accessToken ? fetchDataPrivate() : fetchData()
        
    }, [auth?.accessToken]);

    return (
        <div className='Home'>

            {/* <div style={{width:"100%",display:"flex",justifyContent:"center"}}> */}
            <Search />
            {/* </div> */}
            <PackagesCarousel 
                title = "Top Rated"
                packages = {topRated}
                loading = {loadingPackages}
                setLoginSignupPopup = {setLoginSignupPopup}
            />
            <PackagesCarousel 
                title = "Offers"
                packages = {offers}
                loading = {loadingOffers}
                setLoginSignupPopup = {setLoginSignupPopup}
            />
            <PackagesCarousel 
                title = "Agents"
                packages = {agents}
                loading = {loadingAgents}
            />

        </div>
    )
}
