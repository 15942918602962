import React, { useEffect, useState } from 'react'
import './StepBasicInfo.css'
import InputText from '../../components/form-components/InputText'
import InputTextArea from '../../components/form-components/InputTextArea'
import Button from '../../components/form-components/Button'
import TrashCan from '../../images/icons/white_trash_can.png'
import InputNumber from '../../components/form-components/InputNumber'
import InputFile from '../../components/form-components/InputFile'
import DatePicker from '../../components/form-components/DatePicker'
import Autocomplete from '../../components/form-components/Autocomplete'
import axios from '../../api/axios'

export default function StepBasicInfo(props) {

    const { 
        handleSetCurrentStep,
        setPackageInfo,
        setCurrentDateIndex,
        setCurrentDayIndex,
        title,
        overview,
        dates,
        startingPoints,
        programFile
    } = props

    const [startingPointsResults, setStartingPointsResults] = useState([]) // For google place api -> Result: {placeName: "", placeId: 1} 
    const [startingPointsPlaceNames, setStartingPointsPlaceNames] = useState([]) // For giving the right values to autocomplete component -> ["Athens","Madrid",...]

    const [openPopupId, setOpenPopupId] = useState(null); // Ensuring only one popup will be opened

    const handleSetOpen = (id, isOpen) => {
        setOpenPopupId(isOpen ? id : null);
    };
    
    // Initialize popup content based on focused input id
    useEffect(() => {
        const index = openPopupId ? openPopupId.split(' ')[2] : 0
        const newValue = startingPoints[index].sp
        if(newValue !== ''){
            fetchStartingPointsData(newValue);
        } else {
            setStartingPointsResults([])
        }
    },[openPopupId])

    // For giving the right values to autocomplete component
    useEffect(() => {
        let temp = []
        startingPointsResults.map(item => {
            temp.push(item.placeName)
        })

        setStartingPointsPlaceNames(temp)
    },[startingPointsResults])

    const fetchStartingPointsData = async (newValue) => {
        try {
            const response = await axios.get(`/search/autocompletePlace/${newValue.replace(/ /g, "%20")}`);
            // console.log(response.data)
            setStartingPointsResults(response.data)
        } catch (error) {
            console.error("StartingPoints API " + error)
        }
    }

    // Handling Title
    const handleSetTitle = (newValue) => {
        setPackageInfo(prev => {
            return { ...prev, title: newValue };
        });
    }

    // Handling Overview
    const handleSetOverview = (newValue) => {
        setPackageInfo(prev => {
            return { ...prev, overview: newValue };
        });
    }

    //  Handling Date
    const handleSetDate = (newValue, index, isValid) => {

        if(!isValid){
            newValue = ''
        }

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            updatedDates[index] = { ...updatedDates[index], date: newValue };
            return { ...prev, dates: updatedDates };
        });
    }
    const handleAddDate = () => {
        setPackageInfo(prev => {
            const newDate = {
                date: "",
                packagePrice: "",
                submissionDate: "",
                days: "",
                nights: "",
                totalSeats: "",
                highlights: [],

                daysInfo: []
            }
            return { ...prev, dates: [...prev.dates, newDate]}
        })
    }
    const handleRemoveDate = (index) => {
        setPackageInfo(prev => {
            const updatedDates = prev.dates.filter((_, idx) => idx !== index);
            return { ...prev, dates: updatedDates };
        });
    }
    
    // Handling Starting Point
    const handleSetStartingPoint = (newValue, index) => {

        if(newValue !== ''){
            fetchStartingPointsData(newValue);
        } else {
            setStartingPointsResults([])
        }

        setPackageInfo(prev => {
            const updatedSP = [...prev.startingPoints];
            updatedSP[index] = { ...updatedSP[index], sp: newValue };
            return { ...prev, startingPoints: updatedSP };
        });
    }
    const handleSetPrice = (newValue, index) => {
        setPackageInfo(prev => {
            const updatedSP = [...prev.startingPoints];
            updatedSP[index] = { ...updatedSP[index], extraPrice: newValue };
            return { ...prev, startingPoints: updatedSP };
        });
    }
    const handleAddSp = () => {
        setPackageInfo(prev => {
            const newSp = {
                sp: "",
                extraPrice: ""
            }
            return { ...prev, startingPoints: [...prev.startingPoints, newSp]}
        })
    }
    const handleRemoveSp = (index) => {
        setPackageInfo(prev => {
            const updatedSP = prev.startingPoints.filter((_, idx) => idx !== index);
            return { ...prev, startingPoints: updatedSP };
        });
    }

    // Handling Program File
    const handleSetProgramFile = (newValue) => {
        setPackageInfo(prev => {
            return { ...prev, programFile: newValue };
        });
    }

    // Check Validations to continue
    const checkValidationFormInputs = () => {
        
        // Dates validation 
        const invalidDatesArray = dates.filter(date => date.date === "")
        let isDatesValid = invalidDatesArray.length === 0

        if(isDatesValid){
            handleSetCurrentStep("Dates")
            setCurrentDateIndex(0)
            setCurrentDayIndex(0)
        }
    }

    const renderDateInputs = dates.map((item, index) => {
        
        const {date} = item

        return <div 
                    key={index}
                    style={{display:"flex",alignItems:"center",marginTop: index !== 0 && "1rem"}}
                >
                    <div className='StepDatesDateNumber'>
                    {index + 1}.
                    </div>
                    <div className='StepDatesDate'>
                        <DatePicker 
                            id = {index}
                            value = {date}
                            onChange = {(value, isValid)=>handleSetDate(value, index, isValid)}
                        />
                    </div>
                    {
                    index === 0 ?
                        <div className='StepDatesDateDeleteBtn' style={{ backgroundColor:"gray", color:"white",borderRadius:"5px"}}>
                            <div style={{width:"3rem",height:"1.5rem", display:"flex",justifyContent:"center",alignItems:"center"}}>main</div>
                        </div>
                        :
                        <div className='StepDatesDateDeleteBtn'>
                            <Button 
                                icon = {TrashCan}
                                btnClass = "danger"
                                height = "3rem"
                                width = "3rem"
                                handleSubmit = {()=>handleRemoveDate(index)}
                            />
                        </div>
                    }
                </div>
    });

    const renderStartingPointInputs = startingPoints.map((item, index) => {

        const {sp,extraPrice} = item

        return <div 
                    key={index}
                    style={{display:"flex",alignItems:"center",marginTop: index !== 0 && "1rem"}}
                >
                    <div className='StepDatesDateNumber'>
                    {index + 1}.
                    </div>
                    <div className='StepDatesDate' style={{marginRight:".5rem"}}>
                        <Autocomplete 
                            id = {`Starting Point ${index}`}
                            placeholder = "Starting Point"
                            value = {sp}
                            onChange = {(value)=>handleSetStartingPoint(value,index)}
                            isOpen={openPopupId === `Starting Point ${index}`}
                            setOpen={handleSetOpen}
                            items = {startingPointsPlaceNames}
                            addNew = {false}
                        />
                    </div>
                    <div className='StepDatesDate' style={{marginLeft:".5rem"}}>
                        <InputNumber 
                            placeholder = "Extra Price"
                            value = {parseFloat(extraPrice) === 0 ? "" : parseFloat(extraPrice)}
                            onChange = {(value)=>handleSetPrice(value,index)}
                        />
                    </div>
                    {
                    index === 0 ?
                        <div className='StepDatesDateDeleteBtn' style={{ backgroundColor:"gray", color:"white",borderRadius:"5px"}}>
                            <div style={{width:"3rem",height:"1.5rem", display:"flex",justifyContent:"center",alignItems:"center"}}>main</div>
                        </div>
                        :
                        <div className='StepDatesDateDeleteBtn'>
                            <Button 
                                icon = {TrashCan}
                                btnClass = "danger"
                                height = "3rem"
                                width = "3rem"
                                handleSubmit = {()=>handleRemoveSp(index)}
                            />
                        </div>
                    }
                </div>
    });

    return (
        <div className='StepBasicInfo'>
            <div className='StepBasicInfoTitle'>
                Basic Information
            </div>

            <form>
            <InputText 
                label = "Title"
                placeholder = "Title"
                value = {title}
                onChange = {(value) => handleSetTitle(value)}
            />

            <div style={{marginTop:"1.5rem"}}>
            <InputTextArea
                height = "10rem"
                label = "Overview"
                placeholder = "Overview"
                value = {overview}
                onChange = {(value) => handleSetOverview(value)}
            />
            </div>

            <div style={{marginTop:"1.5rem"}}>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <span>Dates</span>
                <Button 
                    name = "ADD A DATE"
                    handleSubmit = {handleAddDate}
                />
                </div>
            </div>
            <div style={{marginTop:"1.5rem"}}>
                {renderDateInputs}
            </div>

            <div style={{marginTop:"1.5rem"}}>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <span>Starting Points</span>
                <Button 
                    name = "ADD A STARTING POINT"
                    handleSubmit = {handleAddSp}
                />
                </div>
            </div>
            <div style={{marginTop:"1.5rem"}}>
                {renderStartingPointInputs}
            </div>

            <div style={{marginTop:"1rem"}}>
                <InputFile
                    label = "Program File" 
                    id = {"pdf"}
                    value = {programFile}
                    onChange = {(value) => handleSetProgramFile(value)}
                />
            </div>

            <div style={{marginTop:"5rem",display:"flex",justifyContent:"flex-end"}}>
                <Button 
                    name = "NEXT"
                    btnClass = "dark"
                    handleSubmit = {checkValidationFormInputs}
                />
            </div>
            </form>

        </div>
    )
}
