import React from 'react'
import './MyPacksDetails.css'
import UserMenu from '../components/UserMenu'
import { Outlet } from 'react-router-dom'
import Bookings from '../images/icons/bookings.png'
import Inquiries from '../images/icons/inquiries.png'
import Wishlist from '../images/icons/wishlist.png'

export default function MyPacksDetails() {
    const pages = [
        {
            img: Wishlist,
            name: "Wishlist",
            url: "/wishlist"
        },
        {
            img: Inquiries,
            name: "Inquiries",
            url: "/inquiries"
        },
        {
            img: Bookings,
            name: "Bookings",
            url: "/bookings"
        }
    ]


    return (
        <div className='MyPacksDetails'>
            <UserMenu 
                title = "My Packs"
                pages = {pages}
            />

            <Outlet context={{

                            }} 
            />
        </div>
    )
}
