import React, { useEffect, useRef, useState } from 'react'
import './Search.css'
import Flag from '../images/icons/flag.png'
import Location from '../images/icons/location.png'
import Calendar from '../images/icons/calendar.png'
import Person from '../images/icons/person.png'
import SearchIcon from '../images/icons/search.png'
import BlackX from '../images/icons/blackX.png'
import { Link, useSearchParams } from 'react-router-dom'
import useOutsideClick from '../utilities/useOutsideClick'
import { formatDashDate, formatDashDateToDate, formatDate } from '../utilities/dateUtils'
import DateRangePickerPopUp from './DateRangePickerPopUp'
import TravellersPopUp from './TravellersPopUp'
import DestinationPopUp from './DestinationPopUp'
import axios from '../api/axios'
import TravellersBoxes from './TravellersBoxes'

const iconSize = {
    width: "24px",
    height: "24px"
}

const xIconSize = {
    width: "16px",
    height: "16px"
}

export default function SearchBar(props) {

    const {
        withSelect = false
    } = props

    const [isChecked, setChecked] = useState(false);

    // Search Bar Parameters
    const [searchParams, setSearchParams] = useSearchParams()

    //  Pop up Variables
    const [iAmInPopupIsOpen, setIAmInPopupIsOpen] = useState(false)
    const [destinationPopupIsOpen, setDestinationPopupIsOpen] = useState(false)
    const [dateRangePopupIsOpen, setDateRangePopupIsOpen] = useState(false)
    const [travellersPopupIsOpen, setTravellersPopupIsOpen] = useState(false)

    const refIAmIn = useOutsideClick(()=>{setIAmInPopupIsOpen(false);setChecked(false)});
    const refDestination = useOutsideClick(()=>setDestinationPopupIsOpen(false));
    const refDate = useOutsideClick(()=>setDateRangePopupIsOpen(false));
    const refTravellers = useOutsideClick(()=>setTravellersPopupIsOpen(false));
  
    // I Am In Component Variables
    const inputIAmInRef = useRef(null);
    const [iAmIn, setIAmIn] = useState('')

    const [iAmInXBtnPressed, setIAmInXBtnPressed] = useState(false)

    // Destination Component Variables
    const [popularDestinations, setPopularDestinations] = useState([{city:'Athens',country:'Greece'}, {city:'Rome',country:'Italy'}, {city:'Madrid',country:'Spain'}, {city:'Amsterdam',country:'Netherlands'}])
    const [destination, setDestination] = useState('')
    const [placeId, setPlaceId] = useState('')
    const [destinationResults, setDestinationResults] = useState([])
    
    const [destinationXBtnPressed, setDestinationXBtnPressed] = useState(false)

    // DateRangePicker Component Variables
    const monthRange = 3
    const currDate = new Date()
    const startRangeDate = formatDashDate(currDate)
    currDate.setMonth(currDate.getMonth()+monthRange)
    const endRangeDate = formatDashDate(currDate)

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [dateRangeXBtnPressed, setDateRangeXBtnPressed] = useState(false)

    // Travellers Component Variables
    const [adults,setAdults] = useState(1)
    const [children,setChildren] = useState(0)
    const [infants,setInfants] = useState(0)
    const [pets,setPets] = useState(0)

    const [travellersXBtnPressed, setTravellersXBtnPressed] = useState(false)

    // Functions
    const handleClickIAmInContent = () => {
        setChecked(true);
        setIAmInPopupIsOpen(true);
        inputIAmInRef.current.focus();
    }

    const fetchDestinationData = async () => {
        try {
            const response = await axios.get(`/search/autocompletePlace/${destination.replace(/ /g, "%20")}`);
            // console.log(response.data)
            setDestinationResults(response.data)
        } catch (error) {
            console.error("Destination API " + error)
        }
    }

    // I Am In Component useEffect
    useEffect(() => {
        const iAmIn = searchParams.get("i_am_in") || ""

        setIAmIn(iAmIn)
    },[])

    useEffect(() => {
        if(iAmInXBtnPressed){
            setIAmIn('')
            setIAmInXBtnPressed(false)
        }
    },[iAmInXBtnPressed])

    // Destination Component useEffect
    useEffect(() => {
        if(destination!=='')
            fetchDestinationData();
    }, [destination]);
    
    useEffect(() => {
        const destination = searchParams.get("destination") || ""

        setDestination(destination)
    },[])

    useEffect(() => {
        if(destinationXBtnPressed){
            setDestination('')
            setDestinationXBtnPressed(false)
        }
    },[destinationXBtnPressed])
    
    // DateRangePicker Component useEffect
    useEffect(()=> {
        const dateFrom = searchParams.get("date_from") || null
        const dateTo = searchParams.get("date_to") || null

        setStartDate(dateFrom ? formatDashDateToDate(dateFrom) : null)
        setEndDate(dateTo ? formatDashDateToDate(dateTo) : null)

    },[])

    useEffect(() => {
        if(dateRangeXBtnPressed){
            setStartDate(null)
            setEndDate(null)
            setDateRangeXBtnPressed(false)
        }
    },[dateRangeXBtnPressed])

    // Travellers Component useEffect
    useEffect(() => {
        const adults = parseInt(searchParams.get("adults"),10) || 1
        const children = parseInt(searchParams.get("children"),10) || 0
        const infants = parseInt(searchParams.get("infants"),10) || 0
        const pets = parseInt(searchParams.get("pets"),10) || 0

        setAdults(adults)
        setChildren(children)
        setInfants(infants)
        setPets(pets)
    },[])

    useEffect(() => {
        if(travellersXBtnPressed){
            setAdults(1)
            setChildren(0)
            setInfants(0)
            setPets(0)
            setTravellersXBtnPressed(false)
        }
    },[travellersXBtnPressed])

    // const handleChangeSearchParams = (e) => {
    //     setSearchParams( prev => {
    //         prev.set(e.target.name, e.target.value)
    //         return prev
    //     }, { replace: true })
    // }

    
    return (
        <div className='SearchBar'>
            
                <div className='FromTo'>
                    <div ref={refIAmIn} className={`StartPoint ${iAmInPopupIsOpen ? 'no-hover' : ''}`}>
                        <div className='StartPointContent' onClick={handleClickIAmInContent}>
                            <img alt='' src={Flag} style={{width: iconSize.width, height: iconSize.height}} />
                            <div className='SearchBarDivInput' style={{width: !isChecked && "0"}}>
                                <input 
                                    ref={inputIAmInRef}
                                    className='SearchBarInput' 
                                    style={{cursor:iAmInPopupIsOpen ? "" : "pointer"}}
                                    type='text' 
                                    name='i_am_in' 
                                    placeholder='I Am In' 
                                    value={iAmIn}
                                    onChange={(e)=>setIAmIn(e.target.value)}
                                    autoComplete='off'
                                />
                            </div>
                            {/* X Button */}
                            {
                                (iAmIn && iAmInPopupIsOpen) &&
                                <div className='SearchBarContentXbtn' onClick={(e)=>{ e.stopPropagation(); setIAmInXBtnPressed(true) }}>
                                    <img alt='' src={BlackX} style={{width: xIconSize.width, height: xIconSize.height}}/>
                                </div>
                            }
                        </div>

                        {/* PopUp */}
                        
                        {withSelect &&
                            <div 
                                className='StartPointCheckbox'
                            >
                                <input 
                                    type='checkbox' 
                                    id='customCheckbox' 
                                    checked={isChecked}
                                    onChange={()=>setChecked(!isChecked)}
                                />
                                <label className='customCheckboxLabel' htmlFor='customCheckbox'>
                                    <div className='customCheckboxIcon'></div>
                                    <span>Select a start point</span>
                                </label>
                            </div>
                        }
                    </div>

                    <div ref={refDestination} className={`Destination ${destinationPopupIsOpen ? 'no-hover' : ''}`}>
                        <div className='DestinationContent' onClick={()=>setDestinationPopupIsOpen(true)}>
                            <img alt='' src={Location} style={{width: iconSize.width, height: iconSize.height}} />
                            <input 
                                className='SearchBarInput' 
                                style={{cursor:destinationPopupIsOpen ? "" : "pointer"}}
                                type='text' 
                                name='destination' 
                                placeholder='Destination' 
                                value={destination} 
                                onChange={(e)=>setDestination(e.target.value)}
                                autoComplete='off'
                            />
                            {/* X Button */}
                            {
                                (destination && destinationPopupIsOpen) &&
                                <div className='SearchBarContentXbtn' onClick={(e)=>{ e.stopPropagation(); setDestinationXBtnPressed(true) }}>
                                    <img alt='' src={BlackX} style={{width: xIconSize.width, height: xIconSize.height}}/>
                                </div>
                            }
                        </div>

                        <DestinationPopUp 
                            popupIsOpen = {destinationPopupIsOpen}
                            setPopupIsOpen = {setDestinationPopupIsOpen}
                            popularDestinations = {popularDestinations}
                            destination = {destination}
                            destinations = {destinationResults}
                            setDestination = {setDestination}
                            setPlaceId = {setPlaceId}
                        />
                    </div>
                </div>
                
                <div ref = {refDate} className={`Dates ${dateRangePopupIsOpen ? 'no-hover' : ''}`}>
                    <div className='DatesContent' onClick={()=>setDateRangePopupIsOpen(v=>!v)}>
                        <img alt='' src={Calendar} style={{width: iconSize.width, height: iconSize.height}}/>
                        <span>
                        {
                            (!startDate && !endDate) ?
                            "Date Range"
                            :
                            formatDate(startDate) + " - " +  (endDate ? formatDate(endDate) : '')
                            
                        }
                        </span>
                        {/* X Button */}
                        {
                            ((startDate || endDate) && dateRangePopupIsOpen) &&
                            <div className='SearchBarContentXbtn' onClick={(e)=>{ e.stopPropagation(); setDateRangeXBtnPressed(true) }}>
                                <img alt='' src={BlackX} style={{width: xIconSize.width, height: xIconSize.height}}/>
                            </div>
                        }
                    </div>

                    <DateRangePickerPopUp 
                        popupIsOpen = {dateRangePopupIsOpen}
                        startDate = {startDate}
                        endDate = {endDate}
                        setStartDate = {setStartDate}
                        setEndDate = {setEndDate}
                    />
                </div>

                <div ref = {refTravellers} className={`Travellers ${travellersPopupIsOpen ? 'no-hover' : ''}`} >
                    <div className='TravellersContent' onClick={()=>setTravellersPopupIsOpen(v=>!v)} >
                        <img alt='' src={Person} style={{width: iconSize.width, height: iconSize.height}} />
                        <span>
                            {adults + children + " Travellers"} 
                            {infants!==0 && (", " + infants + "Babies")} 
                            {pets!==0 && (", " + pets + "Pets")}
                        </span>
                        {/* X Button */}
                        {
                            ((adults>1 || children!==0 || infants!==0 || pets!==0) && travellersPopupIsOpen) &&
                            <div className='SearchBarContentXbtn' style={{right:"0"}} onClick={(e)=>{ e.stopPropagation(); setTravellersXBtnPressed(true) }}>
                                <img alt='' src={BlackX} style={{width: xIconSize.width, height: xIconSize.height}}/>
                            </div>
                        }
                    </div>

                    <TravellersPopUp 
                        popupIsOpen = {travellersPopupIsOpen}
                    >
                        <TravellersBoxes
                            adults = {adults}
                            children = {children}
                            infants = {infants}
                            pets = {pets}
                            setAdults = {setAdults}
                            setChildren = {setChildren}
                            setInfants = {setInfants}
                            setPets = {setPets}
                        />
                    </TravellersPopUp>

                    <Link 
                        to={`/packages?i_am_in=${iAmIn}&`+
                                        `destination=${destination}&` +
                                        ((!startDate || !endDate) ?
                                        `monthly_start_date=${startRangeDate}&` +
                                        `monthly_length=${monthRange}&` +
                                        `monthly_end_date=${endRangeDate}&`
                                        :
                                        '') +
                                        ((startDate && endDate) ?
                                        `date_from=${startDate&&formatDashDate(startDate)}&` +
                                        `date_to=${endDate&&formatDashDate(endDate)}&`
                                        :
                                        '') +
                                        `adults=${adults}&` +
                                        `children=${children}&` +
                                        `infants=${infants}&` +
                                        `pets=${pets}&` + 
                                        `place_id=${placeId}`
                            } 
                        className='SearchBtn'
                    >
                        <img alt='' src={SearchIcon} style={{width: iconSize.width, height: iconSize.height}} />
                    </Link>
                </div>

        </div>
    )
}
