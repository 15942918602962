import React, { useEffect, useState } from 'react'
import './InputText.css'

export default function InputText(props) {

    const { 
        label = null, // name of label
        icon = "",
        placeholder = '', 
        id,
        name,
        value,
        onChange,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false,
        // Validation Variables
        isValid = true,
        setIsValid,
        errorMessage = "The field is required"
    } = props

    const [internalValue,setInternalValue] = useState(value || '')

    useEffect(() => {
        setInternalValue(value || '')
    },[value])

    const handleChange = (e) => {

        const newValue = e.target.value;

        setInternalValue(newValue);

        onChange && onChange(newValue)
        setIsValid && setIsValid(true);
    }

    return (
        <div className='InputTextAndLabel' style={{width: width}}>
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}
            <div className='InputText' style={{height: height, borderColor: isValid ? 'rgba(0, 0, 0, 0.2)' : 'red' }}>
                {icon && 
                    <div className='InputTextImg'>
                        <img alt='' src={icon} />
                    </div>
                }
                <input 
                    type='text' 
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    value={internalValue}
                    onChange={handleChange}
                    disabled={disabled}
                    style={{paddingLeft: icon && "0"}}
                />
                {!isValid && <div className='InputTextErrorMessage'>{errorMessage}</div>}
            </div>
        </div>
    )
}
