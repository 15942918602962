import React, { useState } from 'react'
import './SignUpPro.css'
import AgentRegistrationCover from '../images/agentRegistrationCover.jpg'
import LeftArrow from '../images/icons/blackLeftArrow.png'
import InputNumber from '../components/form-components/InputNumber'
import InputText from '../components/form-components/InputText'
import InputEmail from '../components/form-components/InputEmail'
import InputPassword from '../components/form-components/InputPassword'
import { validateEmail, validatePassword } from '../utilities/validations'
import { useOutletContext } from 'react-router-dom'
import axios from '../api/axios'

const mediumIconSize = {
    width: "20px",
    height: "20px"
}


export default function SignUpPro() {

    const {setOtpPopup, setLoginSignupPopup, setFormDataSUP} = useOutletContext()

    const [continueIsPressed, setContinueIsPressed] = useState(false)
    
    // Form Input Variables
    const [companyName, setCompanyName] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [gemi, setGemi] = useState('')
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [isCompanyNameValid, setIsCompanyNameValid] = useState(true)
    const [isFNameValid, setIsFNameValid] = useState(true)
    const [isLNameValid, setIsLNameValid] = useState(true)
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)

    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    // --

    const {setAgencyCase} = useOutletContext()

    
    const newAgency = {
        username: email,
        password: password,
        name: companyName,
        managerCountryCode: "+30",
        managerMobilePhone: phoneNumber,
        managerFirstName: fname,
        managerLastName: lname,
        gemiNumber: gemi
    }

    const fetchSignUpProData = async () => {
        setAgencyCase(true)
        try {
            const response = await axios.post(`/otp/sendAgency`, newAgency);
            // console.log("Done: " + response.data)
            if(response.data.success){
                setOtpPopup(true)
                setFormDataSUP(newAgency)
            }
        } catch (error) {
            // console.error(error)
            console.log(error.response.data.message)
            // if(error.response.data === "Agency already exists")
            //  warning: The email isnt available
        }

    }

    const handleContinue = () => {

        const cnameValidationResult = companyName.trim()
        const fnameValidationResult = fname.trim()
        const lnameValidationResult = lname.trim()
        const phoneNumberValidationResult = phoneNumber.trim()

        if(cnameValidationResult && fnameValidationResult && lnameValidationResult && phoneNumberValidationResult){
            setContinueIsPressed(true)
        }
        else {
            !cnameValidationResult && setIsCompanyNameValid(false)
            !fnameValidationResult && setIsFNameValid(false)
            !lnameValidationResult && setIsLNameValid(false)
            !phoneNumberValidationResult && setIsPhoneNumberValid(false)
        }
    }

    const handleSignUpPro = () => {

        const emailValidationResult = validateEmail(email)
        const passwordValidationResult = validatePassword(password);
        
        if(emailValidationResult && passwordValidationResult){
            fetchSignUpProData()
        }
        else {
            !emailValidationResult && setIsEmailValid(false)
            !passwordValidationResult && setIsPasswordValid(false)
        }
    }

    return (
        <div className='SignUpPro'>
            <div className='SignUpProTop'>
                <span style={{fontWeight:"600"}}>Do you already have an account?</span>
                <div 
                    className='SignUpProLogInBtn'
                    onClick={()=>setLoginSignupPopup(true)}
                >
                    Log In
                </div>
            </div>
            <div className='SignUpProForm'>
                <div className='SignUpProLeftCard'>
                    <img alt='' src={AgentRegistrationCover} />
                    <div className='SignUpProLeftCardTitle'>
                        <span className='Bold' style={{fontSize:"32px",marginBottom:"1rem"}}>Grow your Business with Holipack</span>
                        <span>Reach thousands of potential customers</span>
                    </div>
                </div>
                <div className='SignUpProRightCard'>
                    <div className='SignUpProRightCardHeader'>
                        { 
                        continueIsPressed && 
                            <div className='SignUpProRightCardHeaderImg' onClick={()=>setContinueIsPressed(false)}>
                                <img alt='' src={LeftArrow} style={{width: mediumIconSize.width, height: mediumIconSize.height}} />
                            </div>
                         }
                        <span className='TwentyPx' style={{fontWeight:"600"}}>Sign Up</span>
                    </div>

                    <div className='SignUpProRightCardContent'>
                    {
                    !continueIsPressed ?
                        <form className='SignUpProRightCardFirstPage'>
                            <div className='SignUpProRightCardContentRow'>
                                <InputText 
                                    placeholder = 'Company Name'
                                    id = 'cname'
                                    name = 'cname'
                                    value = {companyName}
                                    setValue = {setCompanyName}

                                    isValid = {isCompanyNameValid}
                                    setIsValid = {setIsCompanyNameValid}
                                />
                            </div>

                            <div className='SignUpProRightCardContentInputRow SignUpProRightCardContentRow'>
                                <InputText 
                                    placeholder = 'Manager’s First Name'
                                    id = 'mfname'
                                    name = 'fname'
                                    value = {fname}
                                    setValue = {setFname}
                                    width = "47.5%"

                                    isValid = {isFNameValid}
                                    setIsValid = {setIsFNameValid}
                                />
                                <InputText 
                                    placeholder = 'Manager’s Last Name'
                                    id = 'mlname'
                                    name = 'lname'
                                    value = {lname}
                                    setValue = {setLname}
                                    width = "47.5%"

                                    isValid = {isLNameValid}
                                    setIsValid = {setIsLNameValid}
                                />
                            </div>
                            
                            <div className='SignUpProRightCardContentRow'>
                            <InputNumber 
                                placeholder = 'Manager’s Phone Number'
                                id = 'phonenumber'
                                name = 'phonenumber'
                                value = {phoneNumber}
                                setValue = {setPhoneNumber}
                                isTelephoneNumber = {true}
                                maxNumbers = {11}

                                isValid = {isPhoneNumberValid}
                                setIsValid = {setIsPhoneNumberValid}
                            />
                            </div>

                            <div className='SignUpProRightCardContentRow'>
                                <InputText 
                                    placeholder = 'G.E.MI. Number'
                                    id = 'gemi'
                                    name = 'gemi'
                                    value = {gemi}
                                    setValue = {setGemi}
                                />
                            </div>
                        
                            <div className='SignUpProRightCardContinue'>
                                <div className='SignUpProRightCardBtn' onClick={handleContinue}>
                                    Continue
                                </div>
                            </div>
                        </form>
                    :
                        <form className='SignUpProRightCardSecPage'>
                            <div className='SignUpProRightCardSecPageContent'>
                                <div className='SignUpProRightCardContentRow'>
                                    <InputEmail
                                        placeholder = 'Email'
                                        id = 'email'
                                        name = 'email'
                                        value = {email}
                                        setValue = {setEmail}

                                        isValid = {isEmailValid}
                                        setIsValid = {setIsEmailValid}
                                    />
                                </div>
                                <div className='SignUpProRightCardContentRow'>
                                    <InputPassword
                                        placeholder = 'Password'
                                        id = 'password'
                                        name = 'password'
                                        value = {password}
                                        setValue = {setPassword}

                                        isValid = {isPasswordValid}
                                        setIsValid = {setIsPasswordValid}
                                    />
                                </div>

                                <div className='SignUpProRightCardBtn' style={{marginTop:"4rem"}} onClick={handleSignUpPro}>
                                    Sign Up as Professional
                                </div>
                            </div>

                            <div className='SignUpProRightCardSecPageFooter'>
                                <span className='FourteenPx'>By registering, you agree to the </span>
                                <span className='FourteenPx' style={{marginLeft:".5rem",color:"#0DB4FC",textDecoration:"underline"}}>Terms of Use</span>
                                <span className='FourteenPx'>.</span>
                            </div>
                        </form>
                    }
                    </div>
                    
                </div>
            </div>

        </div>
    )
}
