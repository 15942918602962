import React from 'react'
import './DayTourHotel.css'
import BlackPerson from '../images/icons/blackperson.png'
import SingleBed from '../images/icons/singlebed.png'
import ArrowDown from '../images/icons/arrowDown.png'
import RatingStars from './RatingStars'

const iconSize = {
    width: "16px",
    height: "16px"
}

const roomIconSize = {
    width: "20px",
    height: "20px"
}

export default function DayTourHotel(props) {
    
    const {hotelName,stars,facilityTypes,rooms} = props.hotel

    return (
        <div className='DayTourHotel'>
        
            <div className='DayTourHotelTitleStars'>
                <span className='Bold' style={{marginRight:"1rem"}}>{hotelName}</span>
                <RatingStars rate={stars} />
            </div>

            <div className='DayTourHotelContent'>

                <table>
                    <thead>
                    <tr>
                        <th>Room Type</th>
                        <th>Room Description</th>
                        <th>Travellers</th>
                    </tr>
                    </thead>
                    <tbody>

                    {rooms.map((room,index) => {

                        const {roomType,roomLayout,price,description,currency} = room

                        const roomTp = roomType.split(',')[0]
                        const capacity = roomType.split(',')[1]

                        return <tr 
                                    key={index}
                                >
                                    <td>
                                        <div style={{display:"flex",alignItems:"center"}}>
                                            <span style={{marginRight:"1rem"}}>{roomTp} Room</span>
                                            <img alt='' src={SingleBed} style={{width: roomIconSize.width, height: roomIconSize.height}} />
                                        </div>
                                    </td>
                                    <td>{roomLayout}</td>
                                    <td>
                                        <div style={{display:"flex",alignItems:"center"}}>
                                            {Array.from({ length: capacity }).map((_, index) => (
                                                <img key={index} alt='' src={BlackPerson} style={{width: roomIconSize.width, height: roomIconSize.height, marginRight:".5rem"}} />
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                    })
                    }
                    
                    </tbody>
                </table>
            </div>

            <div className='DayTourHotelServices'>
                {
                    facilityTypes.map((facilityType,index) => {

                        const {facility,facilityPhoto} = facilityType

                        return  <div 
                                    key={index}
                                    className='DayTourHotelService'
                                >
                                    <img alt='' src={facilityPhoto} style={{width: iconSize.width, height: iconSize.height, marginRight:".5rem"}} />
                                    <span style={{opacity:"70%"}}>{facility}</span>
                                </div>
                    })
                }
            </div>

        </div>
    )
}
