import React from 'react'
import './AdminBookings.css'
import TableContainer from '../components/TableContainer'
import SearchOrderFilter from '../../components/SearchOrderFilter'
import Table from '../components/Table'

export default function AdminBookings() {

    const body = <>
        <SearchOrderFilter />
        <Table />
    </>

    return (
        <div className='AdminBookings'>
            <TableContainer 
                headTitle = "Bookings"
                headLabel = "75"
                body = {body}
            />
        </div>
    )
}
