import React from 'react'
import './Agent.css'
import StarRate from '../images/icons/star.png'
import { Link } from 'react-router-dom'

const starIconSize = {
    width: "16px",
    height: "16px"
}

export default function Agent(props) {

    const {id, name, rate, agencyPhoto } = props.packa

    return (
        <Link 
            to={`/agencies/${id}/about`}
            style={{textDecoration: "none",color: "#222222",marginRight:"2rem"}}
        >
            <div className='Package'>
                <div className='AgencyPhotoContainer'>
                    <div className='AgencyPhotoShrinkedContainer'>
                        <img className='AgencyPhoto' alt='' src={agencyPhoto} />
                    </div>
                </div>
                <div className='PackageCardDetails'>
                    <div className='FirstRow'>
                        <span className='Bold'>{name}</span>
                        <div className='Rate'>
                            <img alt='' src={StarRate} style={{width: starIconSize.width, height: starIconSize.height}} />
                            <span>{rate.toFixed(1)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
