import React from 'react'
import './DayTourFlight.css'
import BlackFrontAirplane from '../images/icons/blackFrontAirplane.png'
import WhiteOutlineAirplane from '../images/icons/whiteOutlineAirplane.png'
import { formatTextDate,formatTime,calculateTimeDifference } from '../utilities/dateUtils';

import AegeanLogo from '../images/aegeanLogo.png'

const frontAirplaneIconSize = {
    width: "35px",
    height: "35px"
}

const outlineAirplaneIconSize = {
    width: "50px",
    height: "50px"
}

export default function DayTourFlight(props) {

    const {airline,arrivalAirportInfo,arrivalDate,departureAirportInfo,departureDate,flightCode} = props.flight

    return (
        <div className='DayTourFlight'>
            <div className='DayTourFlightTop'>
                <div className='DayTourFlightTopLeft'>
                    <img alt='' src={BlackFrontAirplane} style={{width: frontAirplaneIconSize.width, height: frontAirplaneIconSize.height}} />
                    <span style={{marginLeft:"1rem"}}>Flight details</span>
                </div>
                <span>{`Check-In: ${formatTextDate(departureDate)}`}</span>
            </div>
            <div className='DayTourFlightFromTo'>
                <span className='Bold'>{`${departureAirportInfo.name} - ${arrivalAirportInfo.name}`}</span>
            </div>
            <div className='DayTourFlightContent'>
                <div className='DayTourFlightContentLogoName'>
                    <div className='DayTourFlightContentLogo'>
                        <img alt='' src={AegeanLogo} />
                    </div>
                    <span>{airline}</span>
                </div>
                <div className='DayTourFlightContentDesign'>
                    <span>{calculateTimeDifference(departureDate,arrivalDate)}</span>
                    <div className='DayTourFlightContentDesignMain'>
                        <div className='DayTourFlightContentDesignMainFlexCol'>
                            <span className='Bold'>{departureAirportInfo.airportCode}</span>
                            <span className='Bold'>{formatTime(departureDate)}</span>
                        </div>
                        <div className='DayTourFlightContentDesignMainScheme'>
                            <div className='DayTourFlightContentDesignMainSchemeLine'></div>
                            <div className='DayTourFlightContentDesignMainSchemeCircle'>
                                <img alt='' src={WhiteOutlineAirplane} style={{width: outlineAirplaneIconSize.width, height: outlineAirplaneIconSize.height}} />
                            </div>
                        </div>
                        <div className='DayTourFlightContentDesignMainFlexCol'>
                            <span className='Bold'>{arrivalAirportInfo.airportCode}</span>
                            <span className='Bold'>{formatTime(arrivalDate)}</span>
                        </div>
                    </div>
                    <span>{flightCode}</span>
                </div>
            </div>
        </div>
    )
}
