import React from 'react'
import './SearchInput.css'
import Search from '../images/icons/black_search.png'

export default function SearchInput() {
    return (
        <div className='SearchInput'>
            <input 
                type='text' 
                placeholder='Search'
            />
            <img alt='' src={Search} />
        </div>
    )
}
