import React, { useEffect, useState } from 'react'
import './LogInSignUpPopUp.css'
import BlackX from '../images/icons/blackX.png'
import Google from '../images/icons/google.png'
import WhiteApple from '../images/icons/whiteApple.png'
import WhiteFacebook from '../images/icons/whiteFacebook.png'
import EmailImg from '../images/icons/email.png'
import InputEmail from './form-components/InputEmail'
import { validateEmail } from '../utilities/validations'
import axios from '../api/axios'

export default function LogInSignUpPopUp(props) {

    const {loginSignupPopup, setLoginSignupPopup, loginPopup, setLoginPopup, signupPopup, setSignupPopup, setFormDataSU, setUserEmail} = props

    // Form Input Variables
    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(true)

    // Clearing the Inputs
    useEffect(() => {
        if(!loginSignupPopup && !loginPopup && !signupPopup){
            setEmail('')
            setIsEmailValid(true)
        }
    },[loginSignupPopup,loginPopup,signupPopup])

    // Function to check if is new user or not
    const checkIfUserExists = async (email) => {
        try {
            const response = await axios.get(`/search/byEmail/${email}`);
            // console.log(response.data)
            if(response.data.success){
                return response.data.data
            }

        } catch (error) {
            console.error(error)
        }
    }

    // Function for Submit
    const handleNext = async () => {
        const validationResult = validateEmail(email)

        if(validationResult){
            try {
                const userExists = await checkIfUserExists(email);
                
                if (userExists) {
                    setLoginSignupPopup(false)
                    setLoginPopup(true)

                    setUserEmail(email)
                } else if(userExists === false) {
                    setLoginSignupPopup(false)
                    setSignupPopup(true)
                    
                    setFormDataSU(prev => {
                        return {
                            ...prev,
                            username: email
                        }
                    })
                } else { //undefined
                    // empty email
                }
            } catch (error) {
                console.error('Error occurred while checking user existence:', error);
                // Handle error...
            }
        }
        
        setIsEmailValid(validationResult)
    }

    return ( 
        loginSignupPopup && 
            <div className='DarkBackground' onClick={()=>setLoginSignupPopup(false)}>
                <div className='LogInSignUpPopUp' onClick={(e) => e.stopPropagation()}>
                    <div className='LogInSignUpPopUpHeader'>
                        <span className='TwentyPx'>Log in or Sign up</span>
                        <div className='LogInSignUpPopUpHeaderCloseBtn' onClick={()=>setLoginSignupPopup(false)} >
                            <img className='LogInSignUpSocialImg' alt='' src={BlackX} />
                        </div>
                    </div>
                    <div className='LogInSignUpPopUpContent'>
                        <span>Log in or register using one of the following methods. </span>
                        <div className='LogInSignUpPopUpContentLinks'>
                            <div className='LogInSignUpPopUpContentLink GoogleLink'>
                                <div className='GoogleLinkImg'>
                                    <img className='LogInSignUpSocialImg' alt='' src={Google} />
                                </div>
                                <span>Continue with Google</span>
                            </div>
                            <div className='LogInSignUpPopUpContentLink AppleLink'>
                                <div className='AppleLinkImg'>
                                    <img className='LogInSignUpSocialImg' alt='' src={WhiteApple} />
                                </div>
                                <span>Continue with Apple</span>
                            </div>
                            <div className='LogInSignUpPopUpContentLink FacebookLink'>
                                <div className='FacebookLinkImg'>
                                    <img className='LogInSignUpSocialImg' alt='' src={WhiteFacebook} />
                                </div>
                                <span>Continue with Facebook</span>
                            </div>
                        </div>
                        <div className='LogInSignUpPopUpContentContinueLinesSpan'>
                            <div className='LogInSignUpPopUpContentContinueLines'></div>
                            <span>or continue with your email</span>
                            <div className='LogInSignUpPopUpContentContinueLines'></div>
                        </div>

                        <form 
                            className='LogInSignUpPopUpContentContinueInputBtn'
                        >
                            <div style={{marginBottom:"1rem"}}>
                                <InputEmail 
                                    icon = {EmailImg}
                                    placeholder = 'Email'
                                    id = 'email'
                                    name = 'email'
                                    value = {email}
                                    setValue = {setEmail}
                                    isValid = {isEmailValid}
                                    setIsValid = {setIsEmailValid}
                                />
                            </div>

                            <div className='LogInSignUpPopUpContentContinueInputBtnDesign LogInSignUpPopUpContentContinueBtn' onClick={handleNext}>
                                <span>Continue</span>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
    )
}
