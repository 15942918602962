import React from 'react'
import './AccountPopUp.css'
import Switch from '../images/icons/switch.png'
import { Link } from 'react-router-dom'
// import PremiumStar from '../images/icons/premiumstar.png'

export default function AcountPopUp(props) {

    const { accountSettingsOpened, accountPopUpType, adminMode = false, handleClickSwitchView, setLoginSignupPopup, handleLogout, name } = props

    return (
        accountSettingsOpened && (
            accountPopUpType === "UserPopUp" ?
                <div className='AccountPopUp'>
                    <div className='UserPopUpHeader'>
                        <span className='FourteenPx' style={{color:"#3BC9D7",marginBottom:".5rem"}}>Welcome!</span>
                        <span>{name}</span>
                    </div>
                    <div className='UserPopUpMenu'>
                        <Link to='/profile' className='UserPopUpMenuItem'>
                            <span>Profile</span>
                        </Link>
                        <Link to='/wishlist' className='UserPopUpMenuItem'>
                            <span>Wishlist</span>
                        </Link>
                        <Link to='/bookings' className='UserPopUpMenuItem'>
                            <span>Bookings</span>
                        </Link>
                        <Link to='/inquiries' className='UserPopUpMenuItem'>
                            <span>Inquiries</span>
                        </Link>
                        <Link to='/account-settings/personal_details' className='UserPopUpMenuItem'>
                            <span>Manage Account</span>
                        </Link>
                        {/* <div className='UserPopUpMenuDIYO UserPopUpMenuItem'>
                            <span style={{opacity:"60%"}}>Do It Your Own </span>
                            <img alt='' src={PremiumStar} style={{ width: iconSize.width, height: iconSize.height,marginLeft: ".5rem" }} />
                        </div> */}
                    </div>
                    <div className='UserPopUpFooter'>
                        <Link to='/' className='UserPopUpMenuItem' onClick={handleLogout}>
                            <span>Log out</span>
                        </Link>
                    </div>
                </div>
            :
            accountPopUpType === "AdminPopUp" ?
                <div className='AccountPopUp'>
                    <div className='UserPopUpHeader'>
                        <span className='FourteenPx' style={{color:"#3BC9D7",marginBottom:".5rem"}}>Welcome!</span>
                        <span>Manessis</span>
                    </div>
                    <div className='AdminPopUpContent'>
                        {!adminMode &&
                            <div className='UserPopUpMenuItem'>
                                <span>Profile</span>
                            </div>
                        }
                        <div className='GuestPopUpContentLogInBtn' style={{margin: adminMode && "0"}} onClick={handleClickSwitchView}>
                            <img alt='' src={Switch} />
                            <span>Switch to {adminMode?`Client's`:`Admin's`} View</span>
                        </div>
                    </div>
                    <div className='UserPopUpFooter'>
                        <div className='UserPopUpMenuItem' onClick={handleLogout}>
                            <span>Log out</span>
                        </div>
                    </div>
                </div>
            :
                // GuestPopUp
                <div className='AccountPopUp'> 
                    <div className='GuestPopUpContent'>
                        <span>Welcome!</span>
                        <div 
                            className='GuestPopUpContentLogInBtn'
                            onClick={()=>setLoginSignupPopup(true)}
                        >
                            <span>Log in / Sign up</span>
                        </div>
                    </div>
                    <div className='GuestPopUpFooter'>
                        <Link 
                            to = "/agent_registration"
                            style = {{textDecoration: "none" }}
                        >
                            <span>Register as a professional</span>
                        </Link>
                    </div>
                </div>
            )
    )
}
