import React, { useEffect, useState } from 'react'
import './NecessaryNotes.css'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from '../api/axios';

export default function NecessaryNotes() {

    const navigate = useNavigate();
    const {packageId,dateParam} = useOutletContext()

    const [necessaryNotesDetails, setNecessaryNotesDetails] = useState({})
    const {necessary,notes} = necessaryNotesDetails

    const fetchNecessaryNotesData = async () => {
        try {

            const response = await axios.get(`/packages/${packageId}/necessaryNotes?date=${dateParam}`);
  
            setNecessaryNotesDetails(response.data);

        } catch (error) {
            navigate("/")
            console.error(error)
        }
    };
    
    useEffect(() => {
        fetchNecessaryNotesData()
    },[packageId,dateParam,navigate])

    return (
        <div className='NecessaryNotes'>
            <div className='Necessary'>
                <span className='TwentyPx Bold' style={{marginBottom:"2rem"}}>Necessary</span>
                { 
                necessary?.split('||').map((nes,index) => {
                    return  <div 
                                key={index}
                                className='NecessaryItem'
                            >
                                <div className='NecessaryItemCircle'>
                                    <span className='TwentyPx' style={{color:"rgba(0, 0, 0, 0.44)"}}>{index+1}</span>
                                </div>
                                <span>{nes}</span>
                            </div>
                })
                }
            </div>
            
            <div className='Notes'>
                <span className='TwentyPx Bold' style={{marginBottom:"2rem"}}>Notes</span>
                {
                notes?.split('||').map((note,index) => {
                    return  <div 
                                key={index}
                                className='NecessaryItem'
                            >
                                <div className='NoteItemCircle'></div>
                                <span>{note}</span>
                            </div>
                })
                }
                
            </div>

        </div>
    )
}
