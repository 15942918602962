import React from 'react'
import './AdministrativeMenu.css'
import { NavLink } from 'react-router-dom'
import Dashboard from '../../images/icons/dashboard.png'
import Packages from '../../images/icons/baggage.png'
import Bookings from '../../images/icons/bookings2.png'
import Clients from '../../images/icons/clients.png'
import Hotel from '../../images/icons/hotel.png'
import Reviews from '../../images/icons/reviews.png'
import Settings from '../../images/icons/settings.png'
import Aegean from '../../images/aegeanLogo.png'

export default function AdministrativeMenu(props) {

    const {agentName = "Versus Travel"} = props

    const menuItems = [
        {
            menuItemIcon: Dashboard,
            menuItemName: "Dashboard",
            menuItemUrl: `/dashboard`
        },
        {
            menuItemIcon: Packages,
            menuItemName: "Packages",
            menuItemUrl: `/packages`
        },
        {
            menuItemIcon: Hotel,
            menuItemName: "Hotels",
            menuItemUrl: `/hotels`
        },
        {
            menuItemIcon: Bookings,
            menuItemName: "Bookings",
            menuItemUrl: `/bookings`
        },
        {
            menuItemIcon: Clients,
            menuItemName: "Clients",
            menuItemUrl: `/clients`
        },
        {
            menuItemIcon: Reviews,
            menuItemName: "Reviews",
            menuItemUrl: `/reviews`
        },
        {
            menuItemIcon: Settings,
            menuItemName: "Settings",
            menuItemUrl: `/settings`
        }
    ]

    return (
        <div className='AdministrativeMenu'>
            
            <div className='AdministrativeMenuFirstRow'>
                <div className='AdministrativeMenuAgentPhoto'>
                    <img alt='' src={Aegean} />
                </div>
                <span>Hi</span>
                <span style={{marginLeft:".5rem",fontWeight:"600"}}>{agentName}</span>
                <span>,</span>
            </div>

            <div className='AdministrativeMenuSecondRow'>
                {
                menuItems.map((item, index) => {
                    const {menuItemIcon, menuItemName, menuItemUrl} = item
                    return  <NavLink 
                                key={index}
                                to={"/live" + menuItemUrl}
                                className='AdministrativeMenuSecondRowLink'
                                style={({isActive}) => {
                                    return isActive ? { fontWeight:"600" } : {}
                                }}
                            >
                                <img alt='' src={menuItemIcon} />
                                <span>{menuItemName}</span>
                            </NavLink>
                })
                }
            </div>
        </div>
    )
}
