import React, { useState } from 'react'
import './Flexible.css'
import LeftArrow from '../images/icons/leftArrow.png'
import RightArrow from '../images/icons/rightArrow.png'

const iconSize = {
    width: "20px",
    height: "20px"
}

export default function Flexible() {

    const [selectedDays, setSelectedDays] = useState()
    const [selectedMonths, setSelectedMonths] = useState(new Array(12).fill(false))

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const visibleSquares = 5

     // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed
    const currentYear = currentDate.getFullYear();

    const monthsData = [];
    for (let i = 0; i < 12; i++) {
        const monthIndex = (currentMonth + i) % 12;
        const year = currentYear + Math.floor((currentMonth + i) / 12);
        monthsData.push({ month: monthNames[monthIndex], year });
    }

    const handleSelectedMonths = (index) => {
        setSelectedMonths(prevState => {
            const updatedSelectedMonths = [...prevState]; 
            updatedSelectedMonths[index] = !updatedSelectedMonths[index]; 
            return updatedSelectedMonths;
          });
    }

    const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevMonth = () => {
    if(currentIndex !== 0)
        setCurrentIndex(prevIndex => (prevIndex === 0 ? (monthsData.length-1) : prevIndex - 1));
  };

  const goToNextMonth = () => {
    if(currentIndex < (monthsData.length-visibleSquares))
        setCurrentIndex(prevIndex => (prevIndex === (monthsData.length-1) ? 0 : prevIndex + 1));
  };
    return (
        <div className='Flexible'>
            <span className='FlexibleTitle'>How long do you want to travel?</span>
            <div className='FlexibleHowLongContainer'>
                <div 
                    className={'FlexibleHowLongContainerItem ' + (selectedDays==="OneDay" && 'FlexibleHowLongContainerItemActive') }
                    onClick={()=>setSelectedDays("OneDay")}
                >
                    One Day
                </div>
                <div 
                    className={'FlexibleHowLongContainerItem ' + (selectedDays==="TwoToThree" && 'FlexibleHowLongContainerItemActive') }
                    onClick={()=>setSelectedDays("TwoToThree")}
                >
                    2 to 3 Days
                </div>
                <div 
                    className={'FlexibleHowLongContainerItem ' + (selectedDays==="FourToSix" && 'FlexibleHowLongContainerItemActive') }
                    onClick={()=>setSelectedDays("FourToSix")}
                >
                    4 to 6 Days
                </div>
                <div 
                    className={'FlexibleHowLongContainerItem ' + (selectedDays==="SevenPlus" && 'FlexibleHowLongContainerItemActive') }
                    onClick={()=>setSelectedDays("SevenPlus")}
                >
                    7+ Days
                </div>
            </div>
            <span className='FlexibleTitle'>When do you want to travel?</span>
            <div className='FlexibleWhenContainer'>
                <div className='flexible-btn prev' onClick={goToPrevMonth}>
                    <img alt='' src={LeftArrow} style={{ width: iconSize.width, height: iconSize.height }}/>
                </div>
                <div className='FlexibleCarousel'>
                    <div 
                        className='FlexibleWhenContainerItems'
                        style={{
                            transform: `translateX(-${ currentIndex * 7.5}rem)`,
                            width: `calc(${visibleSquares * 7.5}rem - 1.5rem)`
                        }}
                    >
                        {monthsData.map((item, index) => (
                            <div 
                                key={index} 
                                className={'FlexibleWhenContainerItem ' + (selectedMonths[index] && 'FlexibleWhenContainerItemActive') } 
                                onClick={()=>handleSelectedMonths(index)}
                            >
                                <div className="month">{item.month}</div>
                                <div className="year">{item.year}</div>
                            </div>
                        ))}
                    </div> 
                </div>
                <div className='flexible-btn next' onClick={goToNextMonth}>
                    <img alt='' src={RightArrow} style={{ width: iconSize.width, height: iconSize.height }}/>
                </div>
            </div>
        </div>
    )
}
