import React, { useEffect, useState } from 'react'
import './AgentDetails.css'
import AgentDetailsPages from '../components/AgentDetailsPages'
import RatingStars from '../components/RatingStars'
import Email from '../images/icons/email.png'
import Bookmarks from '../images/icons/bookmark.png'
import Phone from '../images/icons/phone.png'
import Contact from '../images/icons/contact.png'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'

const bookmarkIconSize = {
    width: "24px",
    height: "24px"
}

const iconSize = {
    width: "18px",
    height: "18px"
}


export default function AgentDetails() {

    const { agencyId } = useParams()

    const navigate = useNavigate()

    const [aboutDetails, setAboutDetails] = useState({})
    const {logoPhoto, name, rate, offices} = aboutDetails
    const {workingHours,countryCode,phone,email} = offices?.[0] || {} // Main Office

    useEffect(() => {
        const fetchAgencyData = async () => {
            try {

                const response = await axios.get(`/agencies/${agencyId}/about`);
                console.log(response.data)
                setAboutDetails(response.data);

            } catch (error) {
                navigate("/")
                console.error(error)
            }
        };
      
        Object.keys(aboutDetails).length === 0 && fetchAgencyData();

    }, [agencyId,navigate]); 
    
    return (
        <div className='AgentDetails'>
            <div className='AgentDetailsCard'>
                <div className='AgentDetailsCardBookmark'>
                    <img alt='' src={Bookmarks} style={{width: bookmarkIconSize.width, height: bookmarkIconSize.height}} />
                </div>
                <div className='AgentDetailsCardPhoto'>
                    <img alt='' src={logoPhoto} />
                </div>
                <span className='TwentyPx Bold'>{name}</span>
                <div className='AgentDetailsCardRating'>
                    <span style={{marginRight:".5rem"}}>{rate}</span>
                    <RatingStars rate={rate} />
                </div>
                <span style={{marginBottom:"1rem"}}>Open Hours</span>
                <div className='AgentDetailsCardOpenHours'>
                    <span>{workingHours?.split(', ')[0]}</span>
                    <span>{workingHours?.split(', ')[1]}</span>
                </div>
                <div className='AgentDetailsCardPhone'>
                        <img alt='' src={Phone} style={{width: iconSize.width, height: iconSize.height, marginRight:".5rem"}} />
                        <span>{countryCode} {phone}</span>
                </div>
                <div className='AgentDetailsCardEmail'>
                    <img alt='' src={Email} style={{width: iconSize.width, height: iconSize.height, marginRight:".5rem"}} />
                    <span>{email}</span>
                </div>
                <div className='AgentDetailsCardContactBtn'>
                    <span className='TwentyPx Bold'>
                        <img alt='' src={Contact} style={{width: iconSize.width, height: iconSize.height, marginRight:"1rem"}} />
                        Contact
                    </span>
                </div>
            </div>
            <AgentDetailsPages 
                agencyId = {agencyId}
                aboutDetails = {aboutDetails}
            />
        </div>
    )
}
