import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import DayTour from '../components/DayTour'
import './Itinerary.css'
import axios from '../api/axios'

export default function Itinerary() {

    const navigate = useNavigate();
    const {packageId,dateParam} = useOutletContext()

    const [currDay,setCurrDay] = useState(1)
    const [itineraryDetails, setItineraryDetails] = useState({})

    const fetchItineraryData = async () => {
        try {

            const response = await axios.get(`/packages/${packageId}/itinerary?date=${dateParam}`);
  
            setItineraryDetails(response.data.packageDayResponseList);

        } catch (error) {
            navigate("/")
            console.error(error)
        }
    };

    useEffect(() => {
        fetchItineraryData()
    },[packageId,dateParam,navigate])

    return (
        <div className='Itinerary'>
            {
                itineraryDetails.length && itineraryDetails.map((day,index) => {
                    return  <DayTour 
                                key = {index}
                                dayDetails = {day}
                                daysLength = {itineraryDetails.length}
                                currDay = {currDay}
                                setCurrDay = {setCurrDay}
                            />
                })
            }
        </div>
    )
}
