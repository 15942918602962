import React from 'react'
import './PackagesTable.css'
import Package from './Package'

export default function PackagesTable(props) {

    const {setLoginSignupPopup, packages, loading} = props

    return (
        <div className='PackagesTable'>
            { 
            loading ?

                Array.from({ length: 18 }).map((_, index) => (
                    <div key={index} className='SkeletonLoader' style={{marginBottom:"2rem",marginRight:"1rem"}}>
                        <div className='SkeletonPhoto'></div>
                        <div className='SkeletonRow'></div>
                        <div className='SkeletonRow' style={{marginTop:".5",width:"75%"}}></div>
                    </div>
                ))
            :
                packages?.map((packa,index) => {
                    
                    return (
                        <Package 
                            packa = {packa}
                            setLoginSignupPopup = {setLoginSignupPopup}
                            key = {index}
                        />
                    )
                })
            
            }
        </div>
    )
}
