import React, { useEffect, useState } from 'react'
import './MultipleSelect.css'
import BlackArrowDown from '../../images/icons/arrowDown.png'
import BlackArrowUp from '../../images/icons/arrowUp.png'
import useOutsideClick from '../../utilities/useOutsideClick'

export default function MultipleSelect(props) {

    const [selectedValues, setSelectedValues] = useState([])
    const [defaultSelected, setDefaultSelected] = useState(true)

    const { 
        selectedIndexes = selectedValues, 
        setSelectedIndexes = setSelectedValues, 
        items = ['item 1','item 2','item 3'], 
        defaultSelection = 'Select an item', 
        width = "100%", // width of the component
        label = null
    } = props

    const [popupIsOpen, setPopupIsOpen] = useState(false)

    const ref = useOutsideClick(()=>setPopupIsOpen(false));

    // For displaying the defaultSelection if nothing is selected
    useEffect(() => {
        if(selectedIndexes.length === 0){
            setDefaultSelected(true)
        }
        else {
            setDefaultSelected(false)
        }
    },[selectedIndexes])

    const displaySelected = () => {
        const selectedItems = items.filter((item, index) => selectedIndexes.includes(index));
        let selectedItemsString = selectedItems.join(', ');

        // If the selected items exceed the container's width, truncate and add "..."
        if (selectedItemsString.length > 35) { // Adjust the value based on your container width
            selectedItemsString = selectedItemsString.slice(0, 35) + ' ...'; // Truncate and add "..."
        }

        return selectedItemsString;
    };

    const handleMultipleSelect = (e, selectedItem) => {
        e.stopPropagation();

        setSelectedIndexes(prev => {
            let tempArr = [...prev]
            if(tempArr.includes(selectedItem)){
                tempArr = tempArr.filter(item => item !== selectedItem)
            }
            else {
                tempArr.push(selectedItem)
            }
            return tempArr
        })
    }

    return (
        <div className='MultipleSelect' style={{width:width}}>

            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}

            <div ref={ref} className='MultipleSelectContainer' onClick={()=>setPopupIsOpen(v=>!v)}>

                <div className='MultipleSelectContainerInput'>
                    <span>
                    {
                        defaultSelected ? 
                            defaultSelection 
                        : 
                            displaySelected()
                    }                    
                    </span>
                    <img className='MultipleSelectImg' alt='' src={popupIsOpen ? BlackArrowUp : BlackArrowDown} />
                </div>

                {
                popupIsOpen &&
                    <div className='MultipleSelectPopup'>
                        <div className='MultipleSelectPopupContainer'>
                            {items.map((item,index) => {
                                return  <span key={index} className='MultipleSelectPopupRow' onClick={(e)=>handleMultipleSelect(e,index)} style={{backgroundColor:selectedIndexes.includes(index)?"aliceblue":""}}>
                                            {item}
                                        </span>
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
