import React, { useEffect, useState } from 'react'
import PackageFilters from '../components/PackageFilters'
import { useOutletContext } from 'react-router-dom';
import axios from '../api/axios';

export default function AgentPackages() {

    const {agencyId} = useOutletContext()

    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(true);
    const [currentOrder, setCurrentOrder] = useState('latestDepartures')

    const fetchData = async () => {
        try {
            const response = await axios.get(`/agencies/${agencyId}/packages?&orderBy=${currentOrder}&pageSize=10&pageNumber=1`);

            setPackages(response.data);

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [agencyId,currentOrder]);

    return (
        <div className='AgentPackages'>
            <PackageFilters 
                // setLoginSignupPopup={setLoginSignupPopup} 
                packages = {packages}
                loading = {loading}
                fetchData = {fetchData}
                setCurrentOrder = {setCurrentOrder}
            />
        </div>
    )
}
