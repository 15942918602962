import React from 'react'
import Star from '../images/icons/star.png'
import GrayStar from '../images/icons/graystar.png'
import './RatingStars.css'

const starIconSize = {
    width: "16px",
    height: "16px"
}

export default function RatingStars(props) {

    const {rate = 0} = props
    
    const filledStars = Math.floor(rate);
    
    const remainder = rate - filledStars;

    return (
        <div className='RatingStars'>
            {[...Array(5)].map((_, index) => (
                <img key={index} alt='' src={GrayStar} style={{ width: starIconSize.width, height: starIconSize.height }} />
            ))}
            <div className='RatingStarsFilled'>
                {[...Array(filledStars)].map((_, index) => (
                    <img key={index} alt='' src={Star} style={{ width: starIconSize.width, height: starIconSize.height }} />
                ))}
                {remainder>0 && <img alt='' src={Star} style={{ width: starIconSize.width, height: starIconSize.height, clipPath: `inset(0% ${100 - remainder * 100}% 0% 0%)`}} />}
            </div>
        </div>
    )
}
