import React, { useState } from 'react'
import './PaymentStartingPoints.css'

export default function PaymentStartingPoints(props) {

    const {
        startingPoints = ["Athens","Thessaloniki"],
        selectedStartingPoint,
        handleSelectedStartingPoint
    } = props

    const [internalSelectedStartingPoint, setInternalSelectedStartingPoint] = useState(selectedStartingPoint.sp)

    const handleClick = (startingPoint) => {
        setInternalSelectedStartingPoint(startingPoint)
        handleSelectedStartingPoint && handleSelectedStartingPoint(startingPoint)
    }
    
    return (
        <div className='PaymentStartingPoints'>
            <div className='PaymentStartingPointsGrid'>
            {
                startingPoints && startingPoints.map((item, index) => {
                    return  <div 
                                key={index}
                                className={'PaymentStartingPointsBox ' + (internalSelectedStartingPoint === item.id ? "PaymentStartingPointsBoxSelected" : "PaymentStartingPointsBoxDefault")}
                                onClick={()=>handleClick(item.id)}
                            >
                                {item.name}
                            </div>
                })
            }
            </div>
            {
            // Display Error message if the user hasnt picked hotels for all locations
            !selectedStartingPoint.isValid &&
                <div style={{marginTop:"1rem",fontSize:"12px",color:"red"}}>You need to choose a Start point</div>
            }
        </div>
    )
}
