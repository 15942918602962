import React, { useState } from 'react'
import './StepInclusionsAndNecessary.css'
import NewLineTable from './NewLineTable'
import Button from '../../components/form-components/Button'

export default function StepInclusionsAndNecessary(props) {

    const {
        setPackageInfo,
        inclusions,
        exclusions,
        optionals,
        necessary,
        notes
    } = props

    // Handling Inclusions
    const handleAddInclusion = (newValue) => {
        setPackageInfo(prev => {
            const updatedInclusions = [...prev.inclusions, newValue];
            return { ...prev, inclusions: updatedInclusions };
        });
    }

    const handleRemoveInclusion = (index) => {
        setPackageInfo(prev => {
            const updatedInclusions = prev.inclusions.filter((_, idx) => idx !== index);
            return { ...prev, inclusions: updatedInclusions };
        })
    }

    // Handling Exclusions
    const handleAddExclusion = (newValue) => {
        setPackageInfo(prev => {
            const updatedExclusions = [...prev.exclusions, newValue];
            return { ...prev, exclusions: updatedExclusions };
        });
    }

    const handleRemoveExclusion = (index) => {
        setPackageInfo(prev => {
            const updatedExclusions = prev.exclusions.filter((_, idx) => idx !== index);
            return { ...prev, exclusions: updatedExclusions };
        })
    }

    // Handling Optionals
    const handleAddOptional = (newValue) => {
        setPackageInfo(prev => {
            const updatedOptionals = [...prev.optionals, newValue];
            return { ...prev, optionals: updatedOptionals };
        });
    }

    const handleRemoveOptional = (index) => {
        setPackageInfo(prev => {
            const updatedOptionals = prev.optionals.filter((_, idx) => idx !== index);
            return { ...prev, optionals: updatedOptionals };
        })
    }

    // Handling Necessaries
    const handleAddNecessary = (newValue) => {
        setPackageInfo(prev => {
            const updatedNecessary = [...prev.necessary, newValue];
            return { ...prev, necessary: updatedNecessary };
        });
    }

    const handleRemoveNecessary = (index) => {
        setPackageInfo(prev => {
            const updatedNecessary = prev.necessary.filter((_, idx) => idx !== index);
            return { ...prev, necessary: updatedNecessary };
        })
    }

    // Handling Notes
    const handleAddNote = (newValue) => {
        setPackageInfo(prev => {
            const updatedNotes = [...prev.notes, newValue];
            return { ...prev, notes: updatedNotes };
        });
    }

    const handleRemoveNote = (index) => {
        setPackageInfo(prev => {
            const updatedNotes = prev.notes.filter((_, idx) => idx !== index);
            return { ...prev, notes: updatedNotes };
        })
    }

    return (
        <div className='StepInclusionsAndNecessary'>
            <div className='StepInclusionsAndNecessaryTitle'>
                Inclusions & Necessary
            </div>

            <form>
            <div style={{marginTop:"1.5rem"}}>
                <NewLineTable 
                    placeholder = "Inclusions"
                    label = "Inclusions"
                    items = {inclusions}
                    handleAdd = {handleAddInclusion}
                    handleRemove = {handleRemoveInclusion}
                />
            </div>
            <div style={{marginTop:"1.5rem"}}>
                <NewLineTable 
                    placeholder = "Exclusions"
                    label = "Exclusions"
                    items = {exclusions}
                    handleAdd = {handleAddExclusion}
                    handleRemove = {handleRemoveExclusion}
                />
            </div>
            <div style={{marginTop:"1.5rem"}}>
                <NewLineTable 
                    placeholder = "Optionals"
                    label = "Optionals"
                    items = {optionals}
                    handleAdd = {handleAddOptional}
                    handleRemove = {handleRemoveOptional}
                />
            </div>
            <div style={{marginTop:"1.5rem"}}>
                <NewLineTable 
                    placeholder = "Necessary"
                    label = "Necessary"
                    items = {necessary}
                    handleAdd = {handleAddNecessary}
                    handleRemove = {handleRemoveNecessary}
                />
            </div>
            <div style={{marginTop:"1.5rem"}}>
                <NewLineTable 
                    placeholder = "Notes"
                    label = "Notes"
                    items = {notes}
                    handleAdd = {handleAddNote}
                    handleRemove = {handleRemoveNote}
                />
            </div>

            <div style={{marginTop:"5rem",display:"flex",justifyContent:"flex-end"}}>
                <Button 
                    name = "SAVE"
                    btnClass = "dark"
                />
            </div>
            </form>
        </div>
    )
}
