import React, { useEffect, useState } from 'react'
import './Wishlist.css'
import PackageFilters from '../components/PackageFilters'
import useAxiosPrivate from '../auth/useAxiosPrivate';
import useAuth from '../auth/useAuth';

export default function Wishlist() {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth()

    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(true);
    const [currentOrder, setCurrentOrder] = useState('latestDepartures')

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get(`/packages/searchByFilters?&orderBy=${currentOrder}&page=1&pageSize=16`);

            setPackages(response.data);

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentOrder, auth?.accessToken]);

    return (
        <div className='Wishlist'>
            <div className='WishlistHeader'>
                <span className='TwentyPx Bold'>Wishlist</span>
            </div>
            <PackageFilters 
                // setLoginSignupPopup={setLoginSignupPopup} 
                packages = {packages}
                loading = {loading}
                fetchData = {fetchData}
                setCurrentOrder = {setCurrentOrder}
            />
        </div>
    )
}
