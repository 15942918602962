import React from 'react'
import './Footer.css'
import Send from '../images/icons/send.png'
import Copyright from '../images/icons/copyright.png'
import Logo from '../images/logo.png'
import Facebook from '../images/icons/facebook.png'
import Instagram from '../images/icons/instagram.png'
import LinkedIn from '../images/icons/linkedin.png'

const iconSize = {
  width: "20px",
  height: "20px"
}

export default function Footer() {

    return (
        <div className='Footer'>
            <div className='FooFirstRow'>
                  <div className='Flex'>
                      <div className='FlexCol'>
                          <div className='FooLogoText'>
                              <div className='FooLogo'
                                style={{
                                    backgroundImage: `url('${Logo}')`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }}
                              >
                              </div>
                              <span className='FourteenPx Bold'>Find your favorite place and go!</span>
                          </div>
                          <span style={{fontStyle: "italic"}}>Currency</span>
                          <div className='DropdownCurrency'>
                            <span className='Bold'>€ Euro</span>
                          </div>
                      </div>
                      <div className='Flex'>
                          <div className='FlexCol' style={{paddingLeft:"5rem"}}>
                              <span className='TwentyPx Bold'>Support</span>
                              <div className='FlexCol PaddingLeft'>
                                  <span style={{paddingTop:"1rem"}}>Help Center</span>
                                  <span style={{paddingTop:"1rem"}}>Contact us</span>
                              </div>
                          </div>
                          <div className='FlexCol' style={{paddingLeft:"5rem"}}>
                              <span className='TwentyPx Bold'>About Us</span>
                              <div className='FlexCol PaddingLeft'>
                                  <span style={{paddingTop:"1rem"}}>Who We Are</span>
                              </div>
                          </div>
                      </div>
                    </div>
                <div className='FlexCol'>
                    <span className='TwentyPx Bold'>Subscribe to our Newsletter</span>
                    <div className='NewsletterBtn'>
                        <input type='text' placeholder='Enter your email' />
                        <div className='Send'>
                            <img alt='' src={Send} style={{width: iconSize.width, height: iconSize.height}} />
                        </div>
                    </div>
                    <div className='Help'>
                        <span className='TwentyPx Bold'>Help us Improve</span>
                    </div>
                </div>
            </div>
            <div className='FooSecondRow'>
                <div className='Copyright'>
                    <div className='Copyright'>
                        <img alt='' src={Copyright} style={{width: iconSize.width, height: iconSize.height}} />
                        <span className='FourteenPx Bold' style={{marginLeft:".5rem"}}>2024 Holipack Co.</span>
                    </div>
                    <span className='FourteenPx Bold' style={{marginLeft:"3rem"}}>Privacy</span>
                    <span className='FourteenPx Bold' style={{marginLeft:"3rem"}}>Terms</span>
                </div>
                <div className='FooSocial'>
                    <img alt='' src={LinkedIn} style={{width: iconSize.width, height: iconSize.height}} />
                    <img alt='' src={Instagram} style={{width: iconSize.width, height: iconSize.height}} />
                    <img alt='' src={Facebook} style={{width: iconSize.width, height: iconSize.height}} />
                </div>
            </div>
        </div>
    )
}
