import React from 'react'
import './Profile.css'
import Verified from '../images/icons/verified.png'
import LocationImg from '../images/icons/filledlocation.png'
import ProfilePic2 from '../images/profilepic2.jpg'
import Instagram from '../images/icons/blackinstagram.png'
import Facebook from '../images/icons/blackfacebook.png'

const verifiedIconSize = {
    width: "24px",
    height: "24px"
}
const locationIconSize = {
    width: "20px",
    height: "20px"
}

export default function Profile() {
    return (
        <div className='Profile'>
            <div className='UserDetailsCard'>
                <div className='UserDetailsCardPhoto'>
                    <img alt='' src={ProfilePic2} />
                </div>
                <div className='UserDetailsCardAlignCenter UserDetailsCardName'>
                    <span className='TwentyPx Bold' style={{marginRight:".5rem"}}>Jinny Mark</span>
                    <img alt='' src={Verified} style={{width: verifiedIconSize.width, height: verifiedIconSize.height}} />
                </div>
                <span style={{marginTop:".5rem", marginBottom:"2rem"}}>F, 27</span>
                <div className='UserDetailsCardAlignCenter'>
                    <span>From</span>
                    <span className='Bold' style={{margin:"0 .5rem"}}>GREECE</span>
                    <img alt='' src={LocationImg} style={{width: locationIconSize.width, height: locationIconSize.height}} />
                </div>
                <div className='UserDetailsCardAlignCenter' style={{marginTop:".5rem", marginBottom:"2rem"}}>
                    <span style={{marginRight:".5rem"}}>Packages Travelled:</span>
                    <span className='Bold'>9</span>
                </div>
                <span className='FourteenPx'>Member since Jun 2018</span>

                {/* Social */}
                <div className='UserDetailsCardAlignCenter' style={{marginTop:"2rem"}}>
                    <img alt='' src={Instagram} style={{width: locationIconSize.width, height: locationIconSize.height, marginRight:"1rem"}} />
                    <span>jinny_mark</span>
                </div>
                <div className='UserDetailsCardAlignCenter' style={{marginTop:"1rem", marginBottom:"2rem"}}>
                    <img alt='' src={Facebook} style={{width: locationIconSize.width, height: locationIconSize.height, marginRight:"1rem"}} />
                    <span>jinny_mark</span>
                </div>
            </div>
            <div className='UserAboutMe'>
                <span className='TwentyPx Bold'>About Me</span>
                <div className='UserAboutMeDescription'>
                    As I reflect on my journeys across different lands, I realize how each adventure has woven a tapestry of memories within me. Traveling isn't just about visiting new places; it's about discovering facets of oneself in the midst of unfamiliar surroundings.
                    From the bustling streets of Tokyo to the serene beaches of Bali, every destination has left an indelible mark on my soul. The taste of authentic street food in Bangkok, the mesmerizing sunset atop Machu Picchu, the laughter shared with newfound friends in Paris cafes — these moments are etched in my heart forever.
                </div>
                <span className='UserAboutMeThemeColorTitle'>Interests</span>
                <span style={{marginBottom:"1rem"}}>Dancing, Gym, Cinema</span>
                <span className='UserAboutMeThemeColorTitle'>Countries I’ve Visited</span>
                <span style={{marginBottom:"1rem"}}>Japan, Brazil, Egypt, Australia, Canada, South Africa, Norway, India, Argentina</span>
                <span className='UserAboutMeThemeColorTitle'>Countries I’ve Lived</span>
                <span style={{marginBottom:"1rem"}}>Japan, Brazil</span>
                <span className='UserAboutMeThemeColorTitle'>Languages</span>
                <span>English, Greek</span>
            </div>
        </div>
    )
}
