import React, { useEffect, useState } from 'react'
import './InputDate.css'
import Calendar from '../../images/icons/calendar.png'
import useOutsideClick from '../../utilities/useOutsideClick'
import Select from './Select'

const iconSize = {
    width: "20px",
    height: "20px"
}

// const days = ['Ημέρα',1,2,3,4,5,6,7,8,9,10,11,12,13,14]
// const months = ['Μήνας',"Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
// const years = ['Χρόνος',1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003] // 100years before 100years after


export default function InputDate(props) {

    const { 
        label = null, // name of label
        id,
        name,
        value,
        setValue,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false
    } = props

    const [days, setDays] = useState([]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [isOlderThanFiveYears, setIsOlderThanFiveYears] = useState(false);

    const [daysSelectedIndex, setDaysSelectedIndex] = useState(0)
    const [monthsSelectedIndex, setMonthsSelectedIndex] = useState(0)
    const [yearsSelectedIndex, setYearsSelectedIndex] = useState(0)

    useEffect(() => {
        // Get current date
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // January is 0

        // Generate years array
        const yearsArray = ['Χρόνος'];
        for (let year = currentYear; year >= currentYear - 100; year--) {
            yearsArray.push(year);
        }
        setYears(yearsArray);

        // Generate months array
        const monthsArray = ['Μήνας', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        setMonths(monthsArray);

        updateDays(currentYear, currentMonth);

    }, []);

    useEffect(() => {
        if (yearsSelectedIndex !== 0 && monthsSelectedIndex !== 0) {
            updateDays(years[yearsSelectedIndex], monthsSelectedIndex);
        }
    }, [monthsSelectedIndex, yearsSelectedIndex]);

    useEffect(() => {
        const currentDate = new Date();

        if (yearsSelectedIndex !== 0 && monthsSelectedIndex !== 0 && daysSelectedIndex !== 0) {
             // Check if selected date is 5 years old or younger
            const selectedDate = new Date(years[yearsSelectedIndex], monthsSelectedIndex - 1, daysSelectedIndex);
            const fiveYearsAgo = new Date(currentDate.getFullYear() - 5, currentDate.getMonth(), currentDate.getDate());
            
            setIsOlderThanFiveYears(selectedDate <= fiveYearsAgo);
        }
    }, [daysSelectedIndex, monthsSelectedIndex, yearsSelectedIndex]);

    const updateDays = (year, month) => {
        const daysInMonth = new Date(year, month, 0).getDate();

        const daysArray = ['Ημέρα'];
        for (let day = 1; day <= daysInMonth; day++) {
            daysArray.push(day);
        }
        setDays(daysArray);
    };

    // console.log(isOlderThanFiveYears)
    return (
        <div className='InputDate' style={{width: width}}>
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}
            
            <div className='InputDateContainer'>
                <Select 
                    items = {days}
                    selectedIndex = {daysSelectedIndex}
                    setSelectedIndex = {setDaysSelectedIndex}
                    width = "30%"
                />

                <Select 
                    items = {months}
                    selectedIndex = {monthsSelectedIndex}
                    setSelectedIndex = {setMonthsSelectedIndex}
                    width = "30%"
                />

                <Select 
                    items = {years}
                    selectedIndex = {yearsSelectedIndex}
                    setSelectedIndex = {setYearsSelectedIndex}
                    width = "30%"
                />
            </div>
        </div>
    )
}