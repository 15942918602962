import React from 'react'
import './DayTourHotels.css'
import DayTourHotel from './DayTourHotel'
import Location from '../images/icons/location.png'
import Hotel from '../images/icons/hotel.png'
import { formatTextDate } from '../utilities/dateUtils';

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function DayTourHotels(props) {

    const {hotelList,hotelsCheckInDate,hotelsLocation,hotelsNights} = props

    return (
        <div className='DayTourHotels'>
            <div className='DayTourHotelsTop'>
                <div className='DayTourHotelsTopContent'>
                    <img alt='' src={Hotel} style={{width: iconSize.width, height: iconSize.height}} />
                    <span style={{marginLeft:"1rem"}}>Hotel Details</span>
                </div>
                <div className='DayTourHotelsTopContent'>
                    {/* <img alt='' src={Location} style={{width: iconSize.width, height: iconSize.height}} /> */}
                    <span style={{marginLeft:"1rem"}}>{`Check-In: ${formatTextDate(hotelsCheckInDate)} • ${hotelsNights} nights`}</span>
                </div>
            </div>
            <div className='DayTourHotelsLocation'>
                <img alt='' src={Location} style={{width: iconSize.width, height: iconSize.height}} />
                <span style={{marginLeft:"1rem"}}>{hotelsLocation}</span>
            </div>

            {hotelList
                .map((hotel,index) => {
                    
                    return  <DayTourHotel 
                                key = {index}
                                hotel = {hotel}
                            />
                })
            }
            
            <span className='FourteenPx'>* Included Taxes and Fees </span>
        </div>
    )
}
