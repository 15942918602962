import React, { useEffect, useState } from 'react';
import './Select.css';
import BlackArrowDown from '../../images/icons/arrowDown.png';
import useOutsideClick from '../../utilities/useOutsideClick';

export default function Select(props) {

    const {
        id,
        label = null,
        width = '100%',
        firstItem = "Select an item",
        firstItemExist = true,
        items = ['item 1', 'item 2'],
        defaultIndex = 0,
        onSelect,
        isOpen,
        setOpen
    } = props
    
    const [selectedIndex, setSelectedIndex] = useState(firstItemExist ? -1 : defaultIndex);
    const [popupIsOpen, setPopupIsOpen] = useState(isOpen || false);

    const ref = useOutsideClick(() => {
        setPopupIsOpen(false)
        setOpen && setOpen(id, false)
    });

    useEffect(()=> {
        setPopupIsOpen(isOpen || false)
    },[isOpen])

    const handleSelection = (index) => {
        setSelectedIndex(index);
        onSelect && onSelect(index);
    };

    return (
        <div 
            className='Select' 
            style={{ width }}
        >
            {label && <label style={{ marginBottom: '1rem' }}>{label}</label>}
            <div 
                ref={ref} 
                className='SelectContainer'
                onClick={() => {
                    setPopupIsOpen((v) => !v)
                    setOpen && setOpen(id, popupIsOpen) // TODO: Check this because normally should be !popupIsOpen & Also the popup doesnt close when I open another
                }} 
            >
                <span>{selectedIndex === -1 ? firstItem : items[selectedIndex]}</span>
                <img className='SelectImg' alt='' src={BlackArrowDown} />

                {popupIsOpen && (
                    <div className='SelectPopup'>
                        <div className='SelectPopupContainer'>
                            {firstItemExist &&
                                <span
                                    key={-1}
                                    className='SelectPopupRow'
                                    onClick={() => handleSelection(-1)}
                                    style={{ backgroundColor: selectedIndex === -1 ? 'aliceblue' : '' }}
                                >
                                    {firstItem}
                                </span>
                            }
                            {items.map((item, index) => (
                                <span
                                    key={index}
                                    className='SelectPopupRow'
                                    onClick={() => handleSelection(index)}
                                    style={{ backgroundColor: selectedIndex === index ? 'aliceblue' : '' }}
                                >
                                    {item}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
