import React from 'react'
import './ChangePopUp.css'
import Backdrop from './Backdrop'
import Button from './form-components/Button'

export default function ChangePopUp(props) {

    const {
        popupIsOpen, 
        setPopupIsOpen = null, 
        title,
        children,
        handleSubmit = null,
        handleClear = null
    } = props

    const handleSave = () => {
        
        if(handleSubmit){
            // Extra Funcionality depending the content
            handleSubmit()
        }
        else {
            // Close the popup
            setPopupIsOpen(false)
        }
    }

    const handleCancel = () => {
        // Close the popup
        setPopupIsOpen(false)
        // Extra Funcionality depending the content
        handleClear && handleClear()
    }

    return (
        popupIsOpen &&
        <>
            {popupIsOpen && <Backdrop onClick={handleCancel} />}
            <div className='ChangePopUp'>
                <div className='ChangePopUpHeader'>
                    <span className='TwentyPx'>{title}</span>
                </div>
                <div className='ChangePopUpContent'>
                    {children}
                </div>
                <div className='ChangePopUpFooter'>
                    <div onClick={handleCancel}>
                        <span style={{textDecoration: 'underline', textUnderlineOffset: '2px', cursor:"pointer"}}>Cancel</span>
                    </div>
                    <Button 
                        name = "Save"
                        handleSubmit = {handleSave}
                    />
                </div>
            </div>
        </>
    )
}
