import React, { useEffect, useState } from 'react'
import './DatePicker.css'

export default function DatePicker(props) {

    const {
        id,
        label = null,
        height = "3rem",
        value,
        onChange,
    } = props

    const [day, setDay] = useState('DD');
    const [month, setMonth] = useState('MM');
    const [year, setYear] = useState('YYYY');
    const [isValidDate, setIsValidDate] = useState(true);

    const [internalValue, setInternalValue] = useState(value || '')

    useEffect(() => {
        if(internalValue){
            const [dayValue, monthValue, yearValue] = value.split('/');
            setDay(dayValue || 'DD');
            setMonth(monthValue || 'MM');
            setYear(yearValue || 'YYYY');
        }else {
            setDay('DD');
            setMonth('MM');
            setYear('YYYY');
        }
    }, [internalValue]);

    useEffect(() => {
        // if(value !== '')
            setInternalValue(value)
    },[value])

    const handleDayChange = (e) => {

        let input = e.target.value.replace(/\D/g, '');
        let goNext = false;

        // Remove leading zeros except if the input is exactly '0'
        if (input !== '0' && day !== '00') {
            input = input.replace(/^0+/, '');
        }

        if (input.length > 2) {
            input = input.slice(-2);
        }

        if(input.length === 1) {
            if( parseInt(input, 10) >= 4 && parseInt(input, 10) <= 9 ) {
                goNext = true
            }

            input = '0' + input
        } else if(input.length === 2){
            if(parseInt(input.slice(0,1),10)===3 && parseInt(input.slice(1,2),10)>1){
                input = '0' + input.slice(1,2)
            }
            goNext = true
        }

        setDay(input);

        if (goNext) {
            // document.getElementById(`monthInput-${id}`).focus(); // Move focus to month input
            document.getElementById(`monthInput-${id}`).select();
        }

        validateDate(input, month, year);

    };
    const handleDayKeyDown = (e) => {
        if (
          (e.key === 'Delete' || e.key === 'Backspace') &&
          e.target.selectionStart === 0 &&
          e.target.selectionEnd === day.length
        ) {
          e.preventDefault();
          setDay('DD');
          setIsValidDate(false);
          setTimeout(() => {
            e.target.setSelectionRange(0, 4);
          }, 0);
        }
    };

    const handleMonthChange = (e) => {

        let input = e.target.value.replace(/\D/g, '');
        let goNext = false;

        // Remove leading zeros except if the input is exactly '0'
        if (input !== '0' && month !== '00') {
            input = input.replace(/^0+/, '');
        }

        if (input.length > 2) {
            input = input.slice(-2);
        }

        if(input.length === 1) {
            if( parseInt(input, 10) >= 2 && parseInt(input, 10) <= 9 ) {
                goNext = true
            }

            input = '0' + input
        } else if(input.length === 2){
            if(parseInt(input.slice(0,1),10)===1 && parseInt(input.slice(1,2),10)>2){
                input = '0' + input.slice(1,2)
            }

            if(isValidMonth(input)){
                goNext = true
            } else {
                document.getElementById(`monthInput-${id}`).select();
            }
            
        }

        setMonth(input);
        
        if (goNext) {
            // document.getElementById(`yearInput-${id}`).focus(); // Move focus to year input
            document.getElementById(`yearInput-${id}`).select();
        }

        validateDate(day, input, year);

    };
    const handleMonthKeyDown = (e) => {
        if (
          (e.key === 'Delete' || e.key === 'Backspace') &&
          e.target.selectionStart === 0 &&
          e.target.selectionEnd === month.length
        ) {
          e.preventDefault();
          setMonth('MM');
          setIsValidDate(false);
          setTimeout(() => {
            e.target.setSelectionRange(0, 4);
          }, 0);
        }
    };

    const handleYearChange = (e) => {

        let input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

        // Remove Zeros from the start
        input = input.replace(/^0+/, '');

        // When the user type 5 number, the last number is being kept
        if(input.length > 4){
            input = input.slice(-1);
        }

        // // Takes only 4 last numbers
        // if (input.length > 4) {
        //     input = input.slice(input.length-4, input.length);
        // }

        // Add to str Zeros until input.length is 4
        let str = ''
        if (input.length < 4) {
            for (let index = input.length; index < 4; index++) {
                str += '0'
            }
        }

        setYear(str+input);
        validateDate(day, month, str + input);

    };
    const handleYearKeyDown = (e) => {
        if (
          (e.key === 'Delete' || e.key === 'Backspace') &&
          e.target.selectionStart === 0 &&
          e.target.selectionEnd === year.length
        ) {
          e.preventDefault();
          setYear('YYYY');
          setIsValidDate(false);
          setTimeout(() => {
            e.target.setSelectionRange(0, 4);
          }, 0);
        }
    };

    const validateDate = (day, month, year) => {
        let isValid;
        if (day === '' && month === '' && year === '') {
            isValid = false;
        } else {
            let date = new Date(year,month-1,day)
            // isValid = isValidDay(day) && isValidMonth(month) && isValidYear(year);
            isValid =   date.getFullYear() === parseInt(year) && 
                        date.getMonth() === parseInt(month) - 1 && 
                        date.getDate() === parseInt(day);
        }
        setIsValidDate(isValid);
        if (onChange) {
            const newDate = `${day}/${month}/${year}`;
            onChange(newDate,isValid);
        }
    };

    const isValidDay = (day) => {
        if (!day) return false;
        const dayNum = parseInt(day, 10);
        return dayNum >= 1 && dayNum <= 31;
    };

    const isValidMonth = (month) => {
        if (!month) return false;
        const monthNum = parseInt(month, 10);
        return monthNum >= 1 && monthNum <= 12;
    };

    const isValidYear = (year) => {
        if (!year) return false;
        const yearNum = parseInt(year, 10);
        return yearNum >= 1900 && yearNum <= new Date().getFullYear() + 50;
    };

    return (
        <div className='DatePickerAndLabel'>
            {label && <label style={{ marginBottom: "1rem" }}>{label}</label>}
            <div 
                className='DatePicker' 
                style={{ height: height, border: isValidDate || (day === 'DD' && month === 'MM' && year === 'YYYY') ? '1px solid #ccc' : '1px solid red' }}
                onClick={()=>document.getElementById(`dayInput-${id}`).select()}
            >
                <input
                    type="text"
                    id={`dayInput-${id}`}
                    value={day}
                    onChange={handleDayChange}
                    onKeyDown={handleDayKeyDown}
                    // placeholder="DD"
                    // maxLength="2"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.target.select()
                    }}
                    style={{width:"2.5ch"}}
                />
                /
                <input
                    type="text"
                    id={`monthInput-${id}`}
                    value={month}
                    onChange={handleMonthChange}
                    onKeyDown={handleMonthKeyDown}
                    // placeholder="MM"
                    // maxLength="2"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.target.select()
                    }}
                    style={{width:"3ch"}}
                />
                /
                <input
                    type="text"
                    id={`yearInput-${id}`}
                    value={year}
                    onChange={handleYearChange}
                    onKeyDown={handleYearKeyDown}
                    // placeholder="YYYY"
                    // maxLength="4"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.target.select()
                    }}
                    style={{width:"5ch"}}
                />
            </div>
        </div>
  );
};