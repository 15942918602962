import React from 'react'
import './TravellersPopUp.css'

export default function TravellersPopUp(props) {

    const {
        popupIsOpen, 
        // setPopupIsOpen = null, 
        children
    } = props


    return (
        popupIsOpen &&
        <>
            <div className='TravellersPopUp'>
                {children}
            </div>
        </>
    )
}
