// dateUtils.js

const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const formattedDate = dateObject.toLocaleDateString('en-GB'); // 'en-GB' gives the "dd/mm/yyyy" format
    return formattedDate;
};

function formatDashDate(inputDate) {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(inputDate.getDate()).padStart(2, '0');

    // Construct the formatted date string in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

function formatDashDateToDate (inputDateDash) {
    const [year, month, day] = inputDateDash.split('-');
    const dateObject = new Date(year, month - 1, day);
    return dateObject
} 

const formatTextDate = (inputDate) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const dateObject = new Date(inputDate);
    const formattedDate = dateObject.toLocaleDateString('en-GB', options);
    return formattedDate;
};

const formatTime = (inputDate) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const dateObject = new Date(inputDate);
    const formattedTime = dateObject.toLocaleTimeString('en-US', options);
    return formattedTime;
};

const calculateTimeDifference = (startDateTime, endDateTime) => {
    const startDateObject = new Date(startDateTime);
    const endDateObject = new Date(endDateTime);

    const timeDifferenceInMillis = endDateObject - startDateObject;

    // Calculate hours and minutes
    const hours = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifferenceInMillis % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} hr ${minutes} min`;
};

export { formatDate,formatDashDate,formatDashDateToDate,formatTextDate,formatTime,calculateTimeDifference };