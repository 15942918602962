import React, { useState } from 'react'
import './StepsMenu.css'

export default function StepsMenu(props) {
    const {
        currentStep,
        handleSetCurrentStep,

        currentDateIndex,
        setCurrentDateIndex,
        currentDayIndex,
        setCurrentDayIndex,

        dates
    } = props

    const handleClickDates = () => {

        let step;

        const invalidDatesArray = dates.filter(date => date.date === "")
        let isDatesValid = invalidDatesArray.length === 0

        if(isDatesValid){
            step = 'Dates'
        } else {
            step = 'NoDates'
        }

        handleSetCurrentStep(step)
        setCurrentDateIndex(0)
        setCurrentDayIndex(0)
    }
    
    return (
        <div className='StepsMenu'>
            <div 
                className={`StepsMenuItem ${currentStep === "BasicInfo" ? 'StepsMenuItemActive' : ''}`} 
                onClick={()=>{
                    handleSetCurrentStep("BasicInfo")
                    setCurrentDateIndex(0)
                    setCurrentDayIndex(0)
                }}
            >
                Basic Info
            </div>
            <div 
                className={`StepsMenuItem ${currentStep === "Dates" || currentStep === "Day" || currentStep === "NoDates" ? 'StepsMenuItemActive' : ''}`} 
                onClick={handleClickDates}
            >
                Dates
            </div>
            {

                dates.map((item, dateIndex) => {
                    const {daysInfo,date} = item;
                    return  date !== '' &&  <div 
                                                key={`Date-${dateIndex+1}`}
                                            >
                                                <div 
                                                    className={`StepsMenuItemDay ${(currentStep === 'Dates' && currentDateIndex === dateIndex) ? 'StepsMenuItemDayActive' : ''}`} 
                                                    onClick={()=>{
                                                        handleSetCurrentStep('Dates')
                                                        setCurrentDateIndex(dateIndex)
                                                        setCurrentDayIndex(0)
                                                    }}
                                                >
                                                    {`Date ${dateIndex+1}`}
                                                </div>
                                                <div style={{padding:"0 1rem"}}>
                                                    {
                                                        daysInfo.map((_, dayIndex) => (
                                                            <div 
                                                                key={`Date-${dateIndex+1} Day-${dayIndex+1}`}
                                                                className={`StepsMenuItemDay ${(currentStep === 'Day' && currentDateIndex === dateIndex && currentDayIndex === dayIndex) ? 'StepsMenuItemDayActive' : ''}`} 
                                                                onClick={()=>{
                                                                    handleSetCurrentStep('Day')
                                                                    setCurrentDateIndex(dateIndex)
                                                                    setCurrentDayIndex(dayIndex)
                                                                }}
                                                            >
                                                                {`Day ${dayIndex+1}`}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                })
            }
            
            <div 
                className={`StepsMenuItem ${currentStep === "Inclusions&Necessary" ? 'StepsMenuItemActive' : ''}`} 
                onClick={()=>{
                    handleSetCurrentStep("Inclusions&Necessary")
                    setCurrentDateIndex(0)
                    setCurrentDayIndex(0)
                }}
            >
                Inclusions & Necessary
            </div>
        </div>
    )
}
