import React from 'react'
import './ReviewItem.css'
import BlackPerson from '../images/icons/blackperson.png'
import FlagReport from '../images/icons/flagreport.png'
import RatingStars from './RatingStars'
import { formatDate } from '../utilities/dateUtils'

const accountSize = {
    width: "24px",
    height: "24px"
}
const flagIconSize = {
    width: "16px",
    height: "16px"
}

export default function ReviewItem(props) {
    
    const {submissionDate, comment, rate, username} = props.review

    return (
        <div className='ReviewItem'>
            <div className='ReviewItemContent'>
                <div className='ReviewItemTop'>
                    <div className='ReviewItemImg'>
                        <img alt='' src={BlackPerson} style={{ width: accountSize.width, height: accountSize.height, opacity:"50%"}} />
                    </div>
                    <div className='ReviewItemGroup'>
                        <div className='ReviewItemGroupNameDate'>
                            <span>{username}</span>
                            <span style={{margin:"0 .5rem"}}>•</span>
                            <span style={{color:"#aaa"}}>{formatDate(submissionDate)}</span>
                        </div>

                        <RatingStars
                            rate = {rate}
                        />

                    </div>
                </div>
                <div className='ReviewItemComment'>
                    <span>{comment}</span>
                </div>
            </div>
            <div className='ReviewItemFooter'>
                <img alt='' src={FlagReport} style={{ width: flagIconSize.width, height: flagIconSize.height}} />
            </div>
        </div>
    )
}
