import './Packages.css'
import React, { useEffect, useState } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import Search from '../components/Search'
import PackageFilters from '../components/PackageFilters'
import axios from '../api/axios'

export default function Packages() {

    const { setLoginSignupPopup } = useOutletContext()

    // Search Bar Parameters
    const [searchParams, setSearchParams] = useSearchParams()
    
    const iAmIn = searchParams.get("i_am_in")
    const destination = searchParams.get("destination")
    const departureDateFrom = searchParams.get("departure_date_from")
    const departureDateTo = searchParams.get("departure_date_to")
    const travellers = searchParams.get("travellers")
    const placeId = searchParams.get("place_id")

    // ---------
    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(true);
    const [currentOrder, setCurrentOrder] = useState('latestDepartures')

    const fetchData = async (priceRange={priceMin:"",priceMax:""}, continentFilters=[], categoryFilters=[], tagFilters=[]) => {
        
        const {priceMin,priceMax} = priceRange
        const continents = continentFilters.join(",");
        const categories = categoryFilters.join(",");
        const tags = tagFilters.join(",");

        try {
            const response = await axios.get(`/packages/searchByFilters?` + (placeId && `destination=${placeId}&`) + `pricingFrom=${priceMin ? (parseInt(priceMin,10)+1) : 0}&pricingTo=${priceMax ? (parseInt(priceMax,10)+1) : 0}&continents=${continents}&categories=${categories}&tags=${tags}&orderBy=${currentOrder}&page=1&pageSize=16`);

            setPackages(response.data);

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentOrder]);

    return (
        <div className='AllPackages'>
            <Search />
            <PackageFilters 
                setLoginSignupPopup={setLoginSignupPopup} 
                packages = {packages}
                loading = {loading}
                fetchData = {fetchData}
                setCurrentOrder = {setCurrentOrder}
                currentOrder = {currentOrder}
            />
        </div>
    )
}
