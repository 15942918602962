
const validateEmail = (input) => {

    // Check if input is empty
    if (!input.trim()) {
        return false;
    }
    
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if input matches email format
    if (!emailRegex.test(input)) {
        return false;
    }
    
    return true;
};

function validatePassword(password) {
    // Check if password is empty
    if (!password) {
      return false;
    }
  
    // Check if password length is greater than 8 characters
    if (password.length < 8) {
      return false;
    }
  
    // Check if password contains at least one capital letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }
  
    // Check if password contains at least one number
    if (!/\d/.test(password)) {
      return false;
    }
  
    // All validation checks passed, return true
    return true;
}

export { validateEmail, validatePassword };