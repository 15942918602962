import React from 'react'
import './PackageDetailsPages.css'
import { NavLink, Outlet, useSearchParams } from 'react-router-dom'

export default function PackageDetailsPages(props) {

    const {packageId} = props
    
    // Get search params
    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const adultsParam = searchParams.get('adults') || 1;
    const childrenParam = searchParams.get('children') || 0;
    const infantsParam = searchParams.get('infants') || 0;
    const petsParam = searchParams.get('pets') || 0;

    const tabs = [
        {
          tabName: "Overview",
          tabUrl: `/overview?date=${dateParam}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}`
        },
        {
          tabName: "Itinerary",
          tabUrl: `/itinerary?date=${dateParam}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}`
        },
        {
          tabName: "Inclusions & Exclusions",
          tabUrl: `/inclusions&exclusions?date=${dateParam}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}`
        },
        {
          tabName: "Necessary & Notes",
          tabUrl: `/necessary&notes?date=${dateParam}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}`
        },
        {
          tabName: "Reviews",
          tabUrl: `/reviews?date=${dateParam}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}`
        }
    ]

    return (
        <div className='PackageDetailsPages'>
            <div className='PackageDetailsPagesLinks'>
                {
                    tabs.map((tab,index)=> {
                        return <NavLink 
                                    key={index}
                                    to={"/package/" + packageId + tab.tabUrl} 
                                    style={{ textDecoration: "none", color: "#222222" }}
                                    draggable="false"
                                >
                                    {
                                        ({isActive}) => {
                
                                            const borderBottom = isActive ? "1px solid #222222" : "none"
                
                                            return <div style={{height:"4rem",display:"flex",alignItems:"center",borderBottom: borderBottom}}>
                                                      <span style={{fontSize:"22px"}}>{tab.tabName}</span>
                                                  </div>
                                        }
                                    }
                                </NavLink>
                    })
                }
            </div>

            <Outlet context={{
                                packageId: packageId,
                                dateParam: dateParam,
                            }}
            />
        </div>
        
    )
}
