import React, { useState } from 'react'
import './ReviewStatistics.css'
import RatingStars from './RatingStars'

export default function ReviewStatistics(props) {

    const {rate = 0, ratingsCountResponseList, reviewTypeName} = props

    const totalCount = ratingsCountResponseList?.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.count;
    }, 0);

    const [hasTravelled,setHasTravelled] = useState(true)

    return (
        <div className='ReviewStatistics'>
            <div className='ReviewStatisticsLeft'>
                <div className='ReviewStatisticsNumberStars'>
                    <span className='Bold' style={{ fontSize:"28px", marginBottom: !hasTravelled && "1rem" }}>{rate.toFixed(1)}</span>
                    <RatingStars 
                        rate = {rate}
                    />
                </div>
                <div className='ReviewThePackage' style={{display: !hasTravelled ? "none" : ""}}>
                    <span>Review the {reviewTypeName}</span>
                </div>
            </div>
            <div className='ReviewStatisticsRight'>
                {ratingsCountResponseList?.map((item,index) => {
                    return  <div 
                                key = {index}
                                className='ReviewStatisticsRightRow'
                            >
                                <span>{item.rate} stars</span>
                                <div className='ReviewStatisticsBar'>
                                    <div className='ReviewStatisticsBarPercent' style={{display:"block",width: `${(item.count * 100 / totalCount)}%`}}></div>
                                </div>
                                <span>{item.count}</span>
                            </div>
                })}
            </div>
        </div>
    )
}
