import React, { useState } from 'react'
import './DateRangePickerPopUp.css'
import DateRangePicker from './DateRangePicker'
import Flexible from './Flexible'

export default function DateRangePickerPopUp(props) {

    const { popupIsOpen, startDate, endDate, setStartDate, setEndDate } = props
    
    const [selectedTypeOfCalender, setSelectedTypeOfCalender] = useState("Dates")

    return (
        popupIsOpen &&
            <div className='DateRangePickerPopUp'>
                <div className="DateRangePickerPopUpTop">
                    <div className='DateRangePickerPopUpContainer'>
                        <div className={`DateRangePickerPopUpBtns ${selectedTypeOfCalender === "Dates" ? "DateRangePickerPopUpActiveBtn" : ""}`} onClick={()=>setSelectedTypeOfCalender("Dates")}>
                            <span>Dates</span>
                        </div>
                        <div className={`DateRangePickerPopUpBtns ${selectedTypeOfCalender === "Flexible" ? "DateRangePickerPopUpActiveBtn" : ""}`} onClick={()=>setSelectedTypeOfCalender("Flexible")}>
                            <span>Flexible</span>
                        </div>
                    </div>
                </div>

                <div className='DateRangePickerPopUpContents'>
                    {
                        selectedTypeOfCalender === "Dates" ?
                            <DateRangePicker 
                                startDate = {startDate}
                                endDate = {endDate}
                                setStartDate = {setStartDate}
                                setEndDate = {setEndDate}
                            />
                        :
                            <Flexible />
                    }
                </div>
            </div>
    )
}
