import React from 'react'
import './AgentAbout.css'
import AgentOffice from '../components/AgentOffice'
import { useOutletContext } from 'react-router-dom'

export default function AgentAbout() {
    
    const { aboutDetails } = useOutletContext()

    const { caption, description, offices = [{}] } = aboutDetails
    
    return (
        <div className='AgentAbout'>
            <span className='Bold' style={{marginBottom:"1rem"}}>{caption}</span>
            <span>{description}</span>
            <div className='AgentAboutAddresses'>
                { 
                    offices.map((office,index) => {
                        return  <AgentOffice 
                                    key = {index}
                                    index = {index}
                                    office = {office}
                                />
                    })
                }
            </div>
        </div>
    )
}
