import React from 'react'
import './DayTourOverview.css'
import Description from '../images/icons/description.png'

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function DayTourOverview(props) {

    const { description } = props

    return (
        <div className='DayTourOverview'>
            <div className='DayTourOverviewTop'>
                <img alt='' src={Description} style={{width: iconSize.width, height: iconSize.height}} />
                <span style={{marginLeft:"1rem"}}>Description</span>
            </div>
            <span>{description}</span>
        </div>
    )
}
