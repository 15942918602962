import React from 'react'
import './AdminReviews.css'
import TableContainer from '../components/TableContainer'
import Reviews from '../../components/Reviews'

export default function AdminReviews() {
    return (
        <div className='AdminReviews'>
            <TableContainer 
                headTitle = "Reviews"
                headLabel = "75"
                body = {<Reviews />}
            />
        </div>
    )
}
