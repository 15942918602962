import React from 'react'
import './Dashboard.css'
import StatBox from '../components/StatBox'
import Packages from '../../images/icons/white_baggage.png'
import Bookings from '../../images/icons/white_calendar.png'
import Earnings from '../../images/icons/white_statistic.png'
import Inquiries from '../../images/icons/white_phone.png'
import Reviews from '../../images/icons/white_star.png'
import Favorites from '../../images/icons/white_heart.png'
import Bookmarks from '../../images/icons/white_bookmark.png'
import TableContainer from '../components/TableContainer'
import GraphicTable from '../components/statistics/GraphicTable'
import TopFive from '../components/statistics/TopFive'

export default function Dashboard() {
    
    return (
        <div className='Dashboard'>
            <div className='StatBoxes'>
                <StatBox 
                    icon = {Packages}
                    bgColor = "#A1CE9A"
                    statNumber = "56"
                    statTitle = "Total Packages"
                />

                <StatBox 
                    icon = {Bookings}
                    bgColor = "#BD9ACE"
                    statNumber = "56"
                    statTitle = "Total Bookings"
                />

                <StatBox 
                    icon = {Earnings}
                    bgColor = "#CFA1A1"
                    statNumber = "56"
                    statTitle = "Earnings"
                />

                <StatBox 
                    icon = {Inquiries}
                    bgColor = "#98B0C7"
                    statNumber = "56"
                    statTitle = "Total Inquires"
                />

                <StatBox 
                    icon = {Reviews}
                    bgColor = "#D1D29C"
                    statNumber = "56"
                    statTitle = "Total Reviews"
                />

                <StatBox 
                    icon = {Favorites}
                    bgColor = "#9ACECB"
                    statNumber = "56"
                    statTitle = "Total Favorites"
                />

                <StatBox 
                    icon = {Bookmarks}
                    bgColor = "#CEB69A"
                    statNumber = "56"
                    statTitle = "Total Bookmarks"
                />
            </div>

            <div className='StatTables'>
                <TableContainer 
                    width = "63%"
                    headTitle = "Booking Stats"
                    body = {<GraphicTable />}
                />

                <TableContainer 
                    width = "33%"
                    headTitle = "Top 5 Packages"
                    body = {<TopFive />}
                />
            </div>

        </div>
    )
}
