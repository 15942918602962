import React, { useState } from 'react'
import './InputPassword.css'
import Eye from '../../images/icons/eye.png'
import EyeOff from '../../images/icons/eyeOff.png'

export default function InputPassword(props) {
    const { 
        label = null, // name of label
        placeholder = '', 
        id,
        name,
        value,
        setValue,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false,
        // Validation Variables
        isValid = true,
        setIsValid,
        errorMessage = "Invalid Password"
    } = props

    const [visiblePassword, setVisiblePassword] = useState(false)

    return (
        <div className='InputPasswordAndLabel' style={{width: width}}>
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}
            <div className='InputPassword' style={{ height: height, borderColor: isValid ? 'rgba(0, 0, 0, 0.2)' : 'red' }}>
                <input 
                    type={visiblePassword ? 'text' : 'password'}
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={(e)=>{setValue(e.target.value);setIsValid && setIsValid(true);}}
                    disabled={disabled}
                />
                <div className='InputPasswordImg' onClick={()=>setVisiblePassword(v=>!v)}>
                    <img alt='' src={visiblePassword ? EyeOff : Eye} />
                </div>
                {!isValid && <div className='InputPasswordErrorMessage'>{errorMessage}</div>}
            </div>
        </div>
    )
}
