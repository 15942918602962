import React, { useState } from 'react'
import './AccountMenu.css'
import BlackPerson from '../images/icons/blackperson.png'
import AccountPopUp from './AccountPopUp'
import useOutsideClick from '../utilities/useOutsideClick'
import useAuth from '../auth/useAuth'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import useLogout from '../auth/useLogout'

const accountSize = {
    width: "24px",
    height: "24px"
}

const ROLES = {
    User: "ROLE_USER",
    Agency: "ROLE_AGENCY"
}

export default function AccountMenu(props) {

    const {setLoginSignupPopup} = props

    const { auth } = useAuth()
    const navigate = useNavigate();
    const logout = useLogout();
    const ref = useOutsideClick(handleClosePopUp);

    const [accountSettingsOpened, setAccountSettingsOpened] = useState(false)
    const [adminMode, setAdminMode] = useState(false)

    const decode = auth?.accessToken
        ? jwtDecode(auth.accessToken, process.env.ACCESS_TOKEN_SECRET_KEY)
        : undefined
    
    const roles = decode?.roles || []
    const name = auth?.accessToken ? (decode?.firstName + " " + decode?.lastName) : "User"

    const accountPopUpType = ( auth?.accessToken && roles?.includes(ROLES.User) ) ? "UserPopUp" : ( auth?.accessToken && roles?.includes(ROLES.Agency) ? "AdminPopUp" : "GuestPopUp" )

    function handleClosePopUp() {
        setAccountSettingsOpened(false)
    }

    const handleClickSwitchView = () => {
        setAdminMode(v => !v)
        //!adminMode ? Navigate to '/' : Navigate to '/dashboard'
    }

    const handleLogoutUser = async () => {
        await logout();
        navigate('/')
        window.location.reload(); //TODO: nopt sure if its needed
    };

    return (
        <>
            {/* Icon Menu */}
            <div ref={ref} className='Account' onClick={ ()=> setAccountSettingsOpened(v=>!v) } >
                <img alt='' src={BlackPerson} style={{ width: accountSize.width, height: accountSize.height, opacity:"60%"}} />
            </div>

            {/* Pop Up */} {/* TODO: Remove AccountPopUp, Make 3 Seperatly Popups */}
            <AccountPopUp 
                accountSettingsOpened = {accountSettingsOpened}
                setAccountSettingsOpened = {setAccountSettingsOpened}
                accountPopUpType = {accountPopUpType}
                adminMode = {adminMode}
                handleClickSwitchView = {handleClickSwitchView}
                setLoginSignupPopup = {setLoginSignupPopup}
                handleLogout = {handleLogoutUser}
                name = {name}
            />

        </>
    )
}
