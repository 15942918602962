import React, { useState } from 'react'
import Logo from '../images/logo.png'
import { Link } from 'react-router-dom'
import AccountMenu from './AccountMenu'
import './Topbar.css'
import SearchBar from './SearchBar'

export default function Topbar(props) {

    const {setLoginSignupPopup} = props

    const [isFixed, setIsFixed] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollY = window.scrollY;
    //         // You can adjust the scroll threshold based on your requirements
    //         const scrollThreshold = 64; //px

    //         if (scrollY > scrollThreshold) {
    //             setIsFixed(true);
    //         } else {
    //             setIsFixed(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         // Cleanup the event listener when the component is unmounted
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <div className={`TopBar ${isFixed ? 'Fixed' : ''}`}>
            <Link 
                to="/"
                style={{textDecoration: "none",color: "#222222"}}
            >
                <img alt='' src={Logo} className='Logo'/>
            </Link>

            {/* {
            isFixed &&
                <SearchBar 
                    isChecked={false}
                />
            } */}

            <div className='TopBarRight'>
                <span style={{cursor:"pointer"}}>EN</span>

                <AccountMenu 
                    setLoginSignupPopup ={setLoginSignupPopup}
                />
            </div>
        </div>
    )
}
