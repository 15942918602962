import React, { useState, useEffect, useRef } from 'react';
import './MultipleAutocomplete.css';
import useOutsideClick from '../../utilities/useOutsideClick';
import BlackArrowDown from '../../images/icons/arrowDown.png';
import BlackArrowUp from '../../images/icons/arrowUp.png';
import BlackX from '../../images/icons/blackX.png';

export default function MultipleAutocomplete(props) {

    const {
        id,
        label = null,
        placeholder = 'Search items',
        width = "100%",
        value,
        onChangeInput,
        items = ['item 1', 'item 2', 'item 3', 'item 3', 'item 52', 'item 43', 'item 662', 'item 343', 'item 231', 'item 3253', 'item 22', 'item 5253', 'item 672', 'item 31`', 'item 255', 'item2 3', 'item 542', 'item 112', 'item 324', 'item 442', 'item 223', 'item 1112', 'item 3452', 'item 22535', 'item 16683', 'item 436362', 'item 63423', 'item  df2', 'item fs3', 'item s2', 'item jk3', 'item s2', 'item 223gv3'],
        values,
        onChange,
        onRemove,
        dropdownImg = true,
        isOpen,
        setOpen
    } = props;

    // Internal Variables
    const ref = useOutsideClick(() => {
        setPopupIsOpen(false)
        setOpen && setOpen(id, false)
    });

    const [popupIsOpen, setPopupIsOpen] = useState(isOpen || false);
    
    const [inputValue, setInputValue] = useState(value || "");
    const [selectedValues, setSelectedValues] = useState(values || []);

    useEffect(()=> {
        setPopupIsOpen(isOpen || false)
    },[isOpen])

    useEffect(()=> {
        setInputValue(value || "")
    },[value])

    useEffect(()=> {
        setSelectedValues(values || [])
    },[values])

    const handleMultipleSelect = (selectedItem) => {
        setInputValue("")
        onChangeInput && onChangeInput("")
        
        setSelectedValues(prev => {
            let tempArr = [...prev];
            if (tempArr.includes(selectedItem)) {
                tempArr = tempArr.filter(item => item !== selectedItem);
            } else {
                tempArr.push(selectedItem);
            }
            return tempArr;
        });

        onChange && onChange(selectedItem)
    };

    const handleRemoveItem = (e, item) => {
        e.stopPropagation();
        setSelectedValues(prev => prev.filter(i => i !== item));

        onRemove && onRemove(item)
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;

        setInputValue(newValue);
        onChangeInput && onChangeInput(newValue)
    };

    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(inputValue.toLowerCase()) && !selectedValues.includes(item)
    );

    return (
        <div className='MultipleAutocomplete' style={{ width: width }}>

            { label && <label style={{ marginBottom: "1rem" }}> {label} </label> }

            <div 
                ref={ref} 
                className='MultipleAutocompleteContainer' 
                onClick={() => {
                    setPopupIsOpen(v => !v)
                    setOpen && setOpen(id, !popupIsOpen)
                }}
            >
                <div className='MultipleAutocompleteContainerInput'>
                    {selectedValues.map((item, index) => (
                        <div
                            className='MultipleAutocompleteContainerInputSelectedValue'
                            onClick={(e) => e.stopPropagation()}
                            key={index}
                        >
                            {item}
                            <div
                                className='MultipleAutocompleteContainerInputSelectedValueImg'
                                onClick={(e) => handleRemoveItem(e, item)}
                            >
                                <img alt='' src={BlackX} />
                            </div>
                        </div>
                    ))}
                    <input
                        id = {id}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={(e) => {
                            e.stopPropagation();
                            setPopupIsOpen(true);
                            setOpen && setOpen(id, true)
                        }}
                    />
                    {dropdownImg &&
                        <img className='MultipleAutocompleteImg' alt='' src={(popupIsOpen && filteredItems.length > 0) ? BlackArrowUp : BlackArrowDown} />
                    }
                </div>

                { (popupIsOpen && filteredItems.length > 0) &&
                    <div className='MultipleAutocompletePopup'>
                        <div className='MultipleAutocompletePopupContainer'>
                            {filteredItems.map((item, index) => (
                                <span
                                    key={index}
                                    className='MultipleAutocompletePopupRow'
                                    onClick={() => handleMultipleSelect(item)}
                                >
                                    {item}
                                </span>
                            ))}
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}
