import React from 'react'
import './SearchOrderFilter.css'
import OrderBy from './OrderBy'
import SearchInput from './SearchInput'
import Filters from './Filters'

export default function SearchOrderFilter() {
    return (
        <div className='SearchOrderFilter'>
            <SearchInput />
            <div className='SearchOrderFilterMargins'>
                <OrderBy />
                <Filters />
            </div>
        </div>
    )
}
