import React, { useEffect, useState } from 'react'
import './AdminPackages.css'
import { Link, useNavigate } from 'react-router-dom'
import StarRate from '../../images/icons/star.png'
import SearchOrderFilter from '../../components/SearchOrderFilter'
import TableContainer from '../components/TableContainer'
import Edit from '../../images/icons/edit.png'
import useAxiosPrivate from '../../auth/useAxiosPrivate'
import useAuth from '../../auth/useAuth'

export default function AdminPackages() {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()

    // State Variables
    const [myPackages, setMyPackages] = useState([])

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get(`/packages/searchByFilters?page=1&pageSize=3`); // Change This to agent's packages

            setMyPackages(response.data);

        } catch (error) {
            console.error(error)
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [auth?.accessToken]);

    const handleEditClick = (e) => {
        e.preventDefault();
        
    }

    const handleAddPackageClick = () => {
        navigate('/live/newpackage')
    }

    const bodyMain = <div className='AdminPackagesContent'>
                            {myPackages.map((mypack,index) => {
                                
                                const {packageId, date, packagePhoto, agencyPhoto, rate, days, nights, name, priceFrom, currency, agencyId} = mypack

                                return  <div 
                                            key={index}
                                            className='AdminPackagesContentContainer'
                                        >
                                            {/* image */}
                                            <Link 
                                                to={`/package/${packageId}/overview?date=${date}`} 
                                                className='AdminPackagesContentPhotoContainer' 
                                                // onClick={handleClickPackage}
                                            >
                                                <img className='AdminPackagesContentPhoto' alt='' src={packagePhoto} />
                                                <div className='AdminPackagesContentPhotoContent'>
                                                    <div className='AdminPackagesContentPhotoContentEdit' onClick={handleEditClick}>
                                                        <img alt='' src={Edit} />
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* -- */}
                                            <div className='AdminPackagesContentContainerInfo'>
                                                <div className='AdminPackagesContentContainerInfoColumn'>
                                                    <span className='Bold' style={{marginBottom:".5rem",width:"fit-content"}}>{name}</span>
                                                    <div className='Rate'>
                                                        <img alt='' src={StarRate} style={{width:"16px", height:"16px"}} />
                                                        <span>{rate.toFixed(1)}</span>
                                                    </div>
                                                </div>
                                                <div className='AdminPackagesContentContainerInfoBottom'>
                                                    <div className='AdminPackagesContentContainerInfoColumn'>
                                                        <div style={{marginBottom:".5rem"}}>
                                                            <span style={{marginRight:".3rem"}}>From</span>
                                                            <span className='Bold'>{priceFrom?.toString()}{" " + currency && currency}</span>
                                                        </div>
                                                        <span className='FourteenPx'>{days} days • {nights} nights</span>
                                                    </div>
                                                    <div className='AdminPackagesContentContainerInfoColumn' style={{alignItems:"center"}}>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            })
                            }
                        </div>

    const body = <>
        <SearchOrderFilter />
        {bodyMain}
    </>

    return (
        <div className='AdminPackages'>
            <TableContainer 
                headTitle = "My Packages"
                headLabel = "54"
                headBtnName = "ADD PACKAGE"
                headBtnHandleClick = {handleAddPackageClick}
                body = {body}
            />
        </div>
    )
}
