import React, { useState, useEffect, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import './Reserve.css'
import CheckoutForm from "../components/CheckoutForm";
import LeftArrow from '../images/icons/blackLeftArrow.png'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReservationSumUp from "../components/ReservationSumUp";
import PaymentDates from "../components/PaymentDates";
import PaymentHotels from "../components/PaymentHotels";
import { formatDate } from "../utilities/dateUtils";
import useAxiosPrivate from "../auth/useAxiosPrivate";
import axios from "../api/axios";
import ChangePopUp from "../components/ChangePopUp";
import TravellersBoxes from "../components/TravellersBoxes";
import PaymentStartingPoints from "../components/PaymentStartingPoints";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

// const stripePromise = loadStripe("pk_test_51MidDyJ6bEGSG0FEpPCnBnbHjcIrqByFW7Amu1FppJk8Ph7rGVzUk5UWkk5Q6NVN9s9tdRw8Ktwy55z3qfte4waI00vqf4OWJh");
const stripePromise = loadStripe("pk_test_51OtwzGHMcSyBMu1keUkwrE2ukn04MwNnxb3bhKN20txCtqreZL1YhZRbq0hFPdJtQLXW7oDyX3KKpy1NGO2rR5y200lioEfvOz");

export default function Reserve() {

    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()

    // Url Params (before question mark)
    const { id } = useParams();
    // Search Params (after question mark)
    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const startingPointParam = searchParams.get('startingPoint');
    const adultsParam = parseInt(searchParams.get('adults'));
    const childrenParam = parseInt(searchParams.get('children'));
    const infantsParam = parseInt(searchParams.get('infants'));
    const petsParam = parseInt(searchParams.get('pets'));
    const hotelsParam = searchParams.get('hotels');

    // Dates Component Variables
    const [datesPopupIsOpen, setDatesPopupIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(dateParam)
    
    // Starting Points Component Variables
    const [startingPointsPopupIsOpen, setStartingPointsPopupIsOpen] = useState(false)
    const [selectedStartingPoint, setSelectedStartingPoint] = useState({sp:parseInt(startingPointParam,10),isValid:true})

    // Travellers Component Variables
    const [travellersPopupIsOpen, setTravellersPopupIsOpen] = useState(false)
    const [adults,setAdults] = useState(parseInt(adultsParam,10))
    const [children,setChildren] = useState(parseInt(childrenParam,10))
    const [infants,setInfants] = useState(parseInt(infantsParam,10))
    const [pets,setPets] = useState(parseInt(petsParam,10))

    // Hotels Component Variables
    const [hotelsPopupIsOpen, setHotelsPopupIsOpen] = useState(false)
    const [selectedHotels, setSelectedHotels] = useState([])
    
    // Reserve Component Variables
    const [packageInfo, setPackageInfo] = useState({})
    const [packagePrice, setPackagePrice] = useState(-1)
    const [clientSecret, setClientSecret] = useState("");
    const [invalidMessage, setInvalidMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [startingPointText, setStartingPointText] = useState("")
    const [hotelsText, setHotelsText] = useState("");

        // Values after save
        const [departureDate, setDepartureDate] = useState(selectedDate)
        const [startingPoint, setStartingPoint] = useState(selectedStartingPoint)
        const [travellers, setTravellers] = useState({adults,children,infants,pets})
        const [hotels, setHotels] = useState([])

    const [hotelsValuesValid, setHotelsValuesValid] = useState(false)

    // ------- Stripe 
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    const paymentIntents = async () => {

        let basicHotelInfoRequestList = []
        hotels.forEach(hotel => {
            hotel.selectedRooms.forEach(room => {
                basicHotelInfoRequestList.push({
                    hotelId: hotel.selectedHotel.hotelId,
                    roomId: room.roomId,
                    quantity: room.quantity
                })
            })
        })

        const body = {
            packageId: id,
            packageName: packageInfo.name,
            date: departureDate,
            travellers: travellers.adults, // + travellers.children + travellers.infants
            startingPointId: startingPoint.sp,
            basicHotelInfoRequestList: basicHotelInfoRequestList,
            agencyUsername: packageInfo.agency,
            currency: "eur"
        }
        
        console.log("PaymentIntent BODY: ", body)
        try {
            const response = await axiosPrivate.post('/payments/createPaymentIntent', body )
            
            setInvalidMessage("")
            setClientSecret(response.data.data.clientSecret);
        } catch (error) {
            console.error(error)
            setInvalidMessage("There was a problem with your booking values. Check if the information is completed.")
            setClientSecret("")
        }
    }
    // -------

    function goBack() {
        navigate(`/package/${id}/overview?date=${dateParam}`)
    }


    const initializeHotels = (locations) => {
        
        const initialHotels = Array.from({ length: locations.length }, (_, index) => ({
            selectedHotel: { hotelId: -1, isHotelValid: true },
            selectedRooms: [],
        }));

        // url -> hotels=63-201-1,64-330-2,64-331-1
        // 1. CheckUrlKey
        // 2. CheckUrlValues
        // 3. CheckLocalStorageVariables

        if(hotelsParam){
            const hotelMap = [];
            hotelsParam.split(',').forEach(item => {
                const [hotelId, roomId, quantity] = item.split('-').map(Number);
                
                let hotel = hotelMap.find(h => h.hotelId === hotelId);

                if (!hotel) {
                    hotel = {
                        hotelId,
                        rooms: [],
                    };
                    hotelMap.push(hotel);
                }
                
                hotel.rooms.push({
                    roomId,
                    quantity,
                });
            });

            if(hotelMap.length === locations.length){

                hotelMap.forEach((item,index) => {
                    const {hotelId, rooms} = item

                    const hotelIdExists = locations[index].hotelList.some(hotel => hotel.hotelId === hotelId)
                    const hotelIdIndex = locations[index].hotelList.findIndex(hotel => hotel.hotelId === hotelId)
                    console.log("hotelIdExists",hotelIdExists,hotelIdIndex)
                    if(hotelIdExists){
                        const hotelListRoomIdsSet = new Set(locations[index].hotelList[hotelIdIndex].rooms.map(room => room.roomId));
                        const allRoomIdExist = rooms.every(room => hotelListRoomIdsSet.has(room.roomId))
                        // check quantity too

                        console.log("allRoomIdExist",allRoomIdExist)
                        if(allRoomIdExist){ 
                            initialHotels[index].selectedHotel.hotelId = hotelId

                            rooms.forEach(room => {

                                initialHotels[index].selectedRooms.push({
                                    roomId: room.roomId,
                                    quantity: room.quantity,
                                    isQtyValid: true
                                })
                            })

                            setHotelsValuesValid(true)
                                
                        } else {
                            // console.log("Couldnt find room id or quantity isnt valid")
                            // setInvalidMessage("Couldnt find room id or quantity isnt valid")
                        }
                    } else {
                        // console.log("Couldnt find hotel id")
                        // setInvalidMessage("Couldnt find hotel id")
                    }
                    
                });

            } else {
                // console.log("Not same length of hotel ids with locations")
                // setInvalidMessage("Not same length of hotel ids with locations")
            }

        } else {
            setInvalidMessage("There was a problem with your booking values. Check if the information is completed.")
        }

        // console.log("initialHotels",initialHotels)

        setSelectedHotels(initialHotels)
        setHotels(initialHotels)
    }

    const calcPrice = async () => {

        let basicHotelInfoRequestList = []
        hotels.forEach(hotel => {
            hotel.selectedRooms.forEach(room => {
                basicHotelInfoRequestList.push({
                    hotelId: hotel.selectedHotel.hotelId,
                    roomId: room.roomId,
                    quantity: room.quantity
                })
            })
        })

        const body = {
            packageId: id,
            packageName: packageInfo.name,
            date: departureDate,
            travellers: travellers.adults, // + travellers.children + travellers.infants
            startingPointId: startingPoint.sp,
            basicHotelInfoRequestList: basicHotelInfoRequestList,
            agencyUsername: packageInfo.agency,
            currency: "eur"
        }

        try {
            const response = await axiosPrivate.post(`/booking/calculatePrice`, body)
            
            console.log("PackagePrice:",response.data)
            setPackagePrice(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const getInfo = async () => {
        try {
            const response = await axios.get(`/booking/${id}/bookInfo?date=${dateParam}`)
            
            console.log("PackageInfo:",response.data)
            setPackageInfo(response.data)
            initializeHotels(response.data.availableHotels)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getInfo()
    }, [departureDate]);
    
    // On first render and on every change (save)
    useEffect(() => {
        // if (!hotels.length) return;
        if (!hotelsValuesValid) return;
        paymentIntents()
        
        // TODO: paymentIntent to be called only if calcPrice is 200 OK
        calcPrice()
        
    }, [departureDate,travellers,hotels,startingPoint]);

    useEffect(() => {
        generateHotelsText();
    }, [packageInfo, hotels]);

    const updateSearchParam = (key, value) => {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.set(key, value);
        setSearchParams(newSearchParams);
    };
    const updateSearchParams = (...args) => {
        if (args.length % 2 !== 0) {
            throw new Error("Arguments must be in pairs");
        }
    
        const newSearchParams = new URLSearchParams(searchParams.toString());
    
        for (let i = 0; i < args.length; i += 2) {
            const key = args[i];
            const value = args[i + 1];
            newSearchParams.set(key, value);
        }
    
        setSearchParams(newSearchParams);
    };

    const handleSelectedDate = (departureDate) => {
        setSelectedDate(departureDate)
    }

    const handleSaveDate = () => {
        setInvalidMessage("")

        setDatesPopupIsOpen(false)
        setDepartureDate(selectedDate)
        // setting the SearchParams
        updateSearchParam("date",selectedDate);

        //TODO: Update the price
    }
    const handleCancelDate = () => {
        setSelectedDate(departureDate)
    }

    const handleSelectedStartingPoint = (startingPoint) => {
        setSelectedStartingPoint({sp:startingPoint, isValid:true})
    }

    const handleSaveStartingPoint = () => {

        // Check Starting Point Validation
        let saveValidation = packageInfo.startingPointInfoResponseList.some(spnt => spnt.id === selectedStartingPoint.sp)

        if(saveValidation){
            setInvalidMessage("")

            setStartingPointsPopupIsOpen(false)
            setStartingPoint(selectedStartingPoint)
            // setting the SearchParams
            updateSearchParam("startingPoint",selectedStartingPoint.sp);

        } else {
            setSelectedStartingPoint(prev => {
                return {
                    ...prev,
                    isValid: false
                }
            })
        }
    }

    const handleCancelStartingPoint = () => {
        setSelectedStartingPoint(startingPoint)
    }

    const handleSaveTravellers = () => {
        setInvalidMessage("")

        setTravellersPopupIsOpen(false)
        setTravellers({adults,children,infants,pets})
        // setting the SearchParams
        updateSearchParams("adults",adults,"children",children,"infants",infants,"pets",pets)
    }
    const handleCancelTravellers = () => {
        // Initialize when closing popup
        setAdults(travellers.adults)
        setChildren(travellers.children)
        setInfants(travellers.infants)
        setPets(travellers.pets)
    }

    const sumUpSimilarRooms = (hotels) => {
        return hotels.map(hotel => {
            const roomMap = new Map();
    
            hotel.selectedRooms.forEach(room => {
                if (roomMap.has(room.roomId)) {
                    const existingRoom = roomMap.get(room.roomId);
                    existingRoom.quantity += room.quantity;
                } else {
                    roomMap.set(room.roomId, { ...room });
                }
            });
    
            return {
                ...hotel,
                selectedRooms: Array.from(roomMap.values())
            };
        });
    };

    const handleSaveHotels = () => {
        console.log(selectedHotels)

        // Check Hotels & QTY Validation
        let saveValidation = !selectedHotels?.some(hotel =>
            hotel.hotelId === -1 || hotel.selectedRooms.length <= 0 || hotel.selectedRooms?.some(room => room.quantity < 1 || isNaN(room.quantity))
        );
        console.log("saveValidation",saveValidation)

        if(saveValidation){
            setInvalidMessage("")
            setHotelsValuesValid(true)
            setHotelsPopupIsOpen(false)

            // Sum up the potential similar rooms and quantities
            const updatedHotels = sumUpSimilarRooms(selectedHotels);

            setSelectedHotels(updatedHotels)
            setHotels(updatedHotels)
            // setting the SearchParams

            let hotelsSearchParam = [];

            updatedHotels.forEach((hotel, hotelIndex) => {
                let rooms = hotel.selectedRooms.map(room => `${hotel.selectedHotel.hotelId}-${room.roomId}-${room.quantity}`);
                hotelsSearchParam.push(rooms.join(","));
            });

            updateSearchParam("hotels",hotelsSearchParam.join(","))

            // TODO: Check this below
            // Replace %2C with ,
            let url = window.location.href;
            url = url.replace(/%2C/g, ",");
            window.history.replaceState({}, document.title, url);

        } else {
            // setting isHotelValid and/or isQtyValid to false
            setSelectedHotels(prevHotels => prevHotels.map(hotel => ({
                ...hotel,
                selectedHotel: {
                    ...hotel.selectedHotel,
                    isHotelValid: hotel.hotelId !== -1 && hotel?.selectedRooms?.length > 0
                },
                selectedRooms: hotel?.selectedRooms?.map(room => ({
                    ...room,
                    isQtyValid: room.quantity >= 1 && !isNaN(room.quantity)
                }))
            })));
        }

    }
    const handleCancelHotels = () => {
        // Initialize when closing popup
        setSelectedHotels(hotels)
    }

    // For Room Selects
    const handleSelectChange = (locationIndex, hotelIndex, roomIndex, selectedIndex) => {
        // console.log("Select: ", locationIndex, hotelIndex, roomIndex, selectedIndex)

        setSelectedHotels(prev => {
            const updatedHotels = prev.map((location, locIndex) => {
                if (locIndex === locationIndex) {
                    return {
                        ...location,
                        selectedRooms: location.selectedRooms.map((room, rIndex) => {
                            if (rIndex === roomIndex) {
                                return {
                                    ...room,
                                    roomId: packageInfo.availableHotels[locationIndex].hotelList[hotelIndex].rooms[selectedIndex].roomId
                                };
                            }
                            return room;
                        })
                    };
                }
                return location;
            });
    
            // console.log("Before:", prev);
            // console.log("After:", updatedHotels);
            return updatedHotels;
        })
    };

    // Hotels Text Format
    const generateHotelsText = () => {
        const textArray = [];
        packageInfo?.availableHotels?.forEach((location, locIndex) => {
            const currentHotel = location.hotelList.find(hotel => hotels[locIndex]?.selectedHotel?.hotelId === hotel.hotelId);

            if (currentHotel) {
                const currentRooms = hotels[locIndex].selectedRooms;
                currentRooms.forEach((croom) => {
                    const currentRoom = currentHotel.rooms.find(room => croom.roomId === room.roomId);

                    if (currentRoom) {
                        const text = `${location.hotelsLocation}: ${currentRoom.roomType.split(',')[0]} Room ${currentRoom.roomLayout}, ${croom.quantity}`;
                        textArray.push(text);
                    }
                })
            }
        });

        const formattedText = textArray.map((txt, index) => (
            <span
                key={index}
                style={{ opacity: ".7", marginBottom: (textArray.length - 1) !== index && ".5rem" }}
            >
                {txt}
            </span>
        ));

        setHotelsText(formattedText);
    };

    useEffect(() => {
        getStartingPointName(packageInfo,startingPoint.sp)
    },[packageInfo,startingPoint])

    const getStartingPointName = (packageInfo, startingPoint) => {
        const startingPointObj = packageInfo.startingPointInfoResponseList?.find(item => item.id === startingPoint)
        setStartingPointText(startingPointObj ? startingPointObj.name : "")
    };

    return (
        <div className="Reserve">

            <div className="ReserveLeftPanel">
                <div className="ReserveTitle">
                    <div className='ReserveBackBtn'>
                        <img alt='' src={LeftArrow} onClick={goBack} />
                    </div>

                    <span style={{fontSize:"26px",fontWeight:"600"}}>Your booking details</span>
                </div>

                <div className="ReserveSection">
                    <div className="ReserveSectionTitle">
                        Your Trip
                    </div>
                    <div className="ReserveSectionContent">
                        {/* Dates */}
                        <div className="ReserveSectionContentRow" style={{marginTop:"0"}}>
                            <div style={{fontWeight:"600"}}>Date</div>
                            <div>
                                <div 
                                    style={{textDecoration:"underline",fontWeight:"600",cursor:"pointer"}}
                                    onClick={()=>setDatesPopupIsOpen(v=>!v)}
                                >
                                    Change
                                </div>
                                {/* Concept of composition, where one component renders another component as part of its structure. */}
                                <ChangePopUp
                                    popupIsOpen = {datesPopupIsOpen}
                                    setPopupIsOpen = {setDatesPopupIsOpen}
                                    title = "Dates"
                                    handleSubmit = {handleSaveDate}
                                    handleClear = {handleCancelDate}
                                >
                                    <div style={{padding: "2rem 0",boxSizing: "border-box"}}>
                                        <PaymentDates 
                                            dates = {packageInfo.dates}
                                            selectedDate = {selectedDate}
                                            handleSelectedDate = {handleSelectedDate}
                                        />
                                    </div>
                                </ChangePopUp>
                            </div>
                        </div>
                        <span style={{opacity:".7"}}>{formatDate(departureDate)}</span>

                        {/* Starting Points */}
                        <div className="ReserveSectionContentRow">
                            <div style={{fontWeight:"600"}}>Starting Point</div>
                            <div>
                                <div 
                                    style={{textDecoration:"underline",fontWeight:"600",cursor:"pointer"}}
                                    onClick={()=>setStartingPointsPopupIsOpen(v=>!v)}
                                >
                                    Change
                                </div>
                                {/* Concept of composition, where one component renders another component as part of its structure. */}
                                <ChangePopUp
                                    popupIsOpen = {startingPointsPopupIsOpen}
                                    setPopupIsOpen = {setStartingPointsPopupIsOpen}
                                    title = "Starting Points"
                                    handleSubmit = {handleSaveStartingPoint}
                                    handleClear = {handleCancelStartingPoint}
                                >
                                    <div style={{padding: "2rem 0",boxSizing: "border-box"}}>
                                        <PaymentStartingPoints
                                            startingPoints = {packageInfo.startingPointInfoResponseList}
                                            selectedStartingPoint = {selectedStartingPoint}
                                            handleSelectedStartingPoint = {handleSelectedStartingPoint}
                                        />
                                    </div>
                                </ChangePopUp>
                            </div>
                        </div>
                        <span style={{opacity:".7"}}>{startingPointText}</span>

                        {/* Travellers */}
                        <div className="ReserveSectionContentRow">
                            <div style={{fontWeight:"600"}}>Travellers</div>
                            <div>
                                <div 
                                    style={{textDecoration:"underline",fontWeight:"600",cursor:"pointer"}}
                                    onClick={()=>setTravellersPopupIsOpen(v=>!v)}
                                >
                                    Change
                                </div>
                                <ChangePopUp
                                    popupIsOpen = {travellersPopupIsOpen}
                                    setPopupIsOpen = {setTravellersPopupIsOpen}
                                    title = "Travellers"
                                    handleSubmit = {handleSaveTravellers}
                                    handleClear = {handleCancelTravellers}
                                >
                                    <TravellersBoxes
                                        adults = {adults}
                                        children = {children}
                                        infants = {infants}
                                        pets = {pets}
                                        setAdults = {setAdults}
                                        setChildren = {setChildren}
                                        setInfants = {setInfants}
                                        setPets = {setPets}
                                    />
                                </ChangePopUp>
                            </div>
                        </div>
                        <span style={{opacity:".7"}}>
                            {travellers.adults + travellers.children + " Travellers"} 
                            {travellers.infants!==0 && (", " + travellers.infants + "Babies")} 
                            {travellers.pets!==0 && (", " + travellers.pets + "Pets")}
                        </span>

                        {/* Hotels */}
                        <div className="ReserveSectionContentRow">
                            <div style={{fontWeight:"600"}}>Hotels</div>
                            <div>
                                <div 
                                    style={{textDecoration:"underline",fontWeight:"600",cursor:"pointer"}}
                                    onClick={()=>setHotelsPopupIsOpen(v=>!v)}
                                >
                                    Change
                                </div>
                                <ChangePopUp
                                    popupIsOpen = {hotelsPopupIsOpen}
                                    setPopupIsOpen = {setHotelsPopupIsOpen}
                                    title = "Hotels"
                                    handleSubmit = {handleSaveHotels}
                                    handleClear = {handleCancelHotels}
                                >
                                    <PaymentHotels 
                                        selectedHotels = {selectedHotels}
                                        setSelectedHotels = {setSelectedHotels}
                                        handleSelectChange = {handleSelectChange}
                                        
                                        locationsChoices = {packageInfo?.availableHotels}
                                    />
                                </ChangePopUp>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>{hotelsText}</div>

                    </div>
                </div>
                { 
                !isLoading && (
                !invalidMessage ?
                    <>
                        <div className="ReserveSection">
                            <div className="ReserveSectionTitle">
                                Payment
                            </div>

                            {options.clientSecret && (
                                <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm />
                                </Elements>
                            )}
                        </div>

                        <div className="ReserveSection" style={{border:"none"}}>
                            <div className="ReserveSectionTitle">
                                Cancelation Policy
                            </div>
                            <div className="ReserveSectionContent">
                                Free cancellation before 11 Dec. Cancel before 3 Jan for a partial refund. Learn more
                            </div>
                        </div>
                    </>
                    :
                    <div style={{marginTop:"1rem"}}>
                        <span>{invalidMessage}</span>
                    </div>
                )}
            </div>

            <div className="ReserveRightPanel">
                <ReservationSumUp 
                    packageInfo = {packageInfo}
                    packagePrice = {packagePrice}
                />
            </div>

        </div>
    );
}