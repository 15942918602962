import React, { useEffect, useState } from 'react'
import './PackagesCarousel.css'
import Package from './Package'
import LeftArrow from '../images/icons/leftArrow.png'
import RightArrow from '../images/icons/rightArrow.png'
import Agent from './Agent'
import { Link } from 'react-router-dom'

const iconSize = {
    width: "32px",
    height: "32px"
}

export default function PackagesCarousel(props) {

    const {title, packages, loading, setLoginSignupPopup} = props
    const [currentIndex, setCurrentIndex] = useState(0);

    const [visibleSquares, setVisibleSquares] = useState(6);

    const handlePrevClick = () => {
        !loading && setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    };

    const handleNextClick = () => {
        !loading && setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, packages.length - visibleSquares))
    };

    //  ---------- WIDTH RESIZE ------------
    const calculateColumns = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth >= 1660) return 6;
        if (windowWidth >= 1390) return 5;
        if (windowWidth >= 1110) return 4;
        if (windowWidth >= 840) return 3;
        return 2; // Default to 2 images per row for smaller screens
    };

    const [columns, setColumns] = useState(calculateColumns());

    // Add event listener for window resize
    useEffect(() => {
        const handleResize = () => {
            setColumns(calculateColumns());
        };

        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='PackagesCarousel'>
            
            <div className='Carousel'>
        
                <div className='LeftArrow' onClick={handlePrevClick} style={{opacity:!loading && 1}}>
                    <img alt='' src={LeftArrow} style={{width: iconSize.width, height: iconSize.height}} />
                </div>

                <div className='TopAndPacks' style={{width:`calc(18px * ${columns}`}}>
                    <div className='TobBar'>
                        <span className='TwentyPx Bold'>{title}</span>
                        <span className='ShowAll FourteenPx'>Show All</span>
                    </div>
                    <div style={{display:"flex",overflow:"hidden"}}>
                        <div 
                            className='Packages'
                            style={{
                                transform: `translateX(-${ currentIndex * 18}rem)`,
                                width: `${visibleSquares * 18 - 2 }rem`,
                            }}
                        > 
                            { 
                            loading ?
            
                                Array.from({ length: 10 }).map((_, index) => (
                                    <div key={index} className='SkeletonLoader'>
                                        <div className='SkeletonPhoto'></div>
                                        <div className='SkeletonRow'></div>
                                        <div className='SkeletonRow' style={{marginTop:".5",width:"75%"}}></div>
                                    </div>
                                ))
                            :
                                packages?.map((packa,index) => {
                                    // console.log(packa)
                                    return (
                                        title !== "Agents" ?
                                            <div style={{minWidth:"18rem"}} key = {index}>
                                                <Package 
                                                    packa = {packa}
                                                    setLoginSignupPopup = {setLoginSignupPopup}
                                                    // key = {index}
                                                />
                                            </div>
                                        :
                                        
                                        <Agent
                                            packa = {packa}
                                            key = {index}
                                        />
                                    )
                                })
                            
                            }
                        </div>
                    </div>
                    
                </div>                

                <div className='RightArrow' onClick={handleNextClick} style={{opacity:!loading && 1}}>
                    <img alt='' src={RightArrow} style={{width: iconSize.width, height: iconSize.height}} />
                </div>

            </div>
        </div>
    )
}
