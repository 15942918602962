import React from 'react'
import './AgentDetailsPages.css'
import AgentAbout from '../images/icons/agentabout.png'
import AgentPackages from '../images/icons/agentpackages.png'
import AgentReviews from '../images/icons/agentreviews.png'
import AgentPolicy from '../images/icons/agentpolicy.png'
import { NavLink, Outlet } from 'react-router-dom'

const iconSize = {
    width: "18px",
    height: "18px"
}

export default function AgentDetailsPages(props) {

    const { agencyId, aboutDetails } = props

    const tabs = [
        {
            tabImg: AgentAbout,
            tabName: "About",
            tabUrl: "/about"
        },
        {
            tabImg: AgentPackages,
            tabName: "Packages",
            tabUrl: "/packages"
        },
        {
            tabImg: AgentReviews,
            tabName: "Reviews",
            tabUrl: "/reviews"
        },
        {
            tabImg: AgentPolicy,
            tabName: "Refund Policy",
            tabUrl: "/refund_policy"
        }
    ]

    return (
        <div className='AgentDetailsPages'>
            <div className='AgentDetailsPagesLinks'>
                {
                    tabs.map((tab,index)=> {
                        return <NavLink 
                                    key={index}
                                    to={"/agencies/" + agencyId + tab.tabUrl} 
                                    style={{ textDecoration: "none", color: "#222222", width:"25%", height:"100%" }}
                                    draggable="false"
                                >
                                    {
                                        ({isActive}) => {
                
                                            const borderBottom = isActive ? "2px solid #3BC9D7" : "none"
                
                                            return  <div className='AgentDetailsPagesLink' style={{borderBottom: borderBottom}}>
                                                        <img alt='' src={tab.tabImg} style={{width: iconSize.width, height: iconSize.height, marginRight:".5rem"}} />
                                                        <span>{tab.tabName}</span>
                                                    </div>
                                        }
                                    }
                                </NavLink>
                    })
                }
            </div>

            <Outlet context={{
                                agencyId,
                                aboutDetails
                            }}
            />
            
        </div>
    )
}
