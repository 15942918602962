import React, { useState } from 'react'
import './InputFile.css'
import XIcon from '../../images/icons/blackX.png'

export default function InputFile(props) {

    const { 
        label = null, // name of label
        icon = "",
        placeholder = '', 
        id,
        name,
        value,
        onChange,
        height = "3rem", // height of input
        width = "100%", // width of the component
        disabled = false,
        // Validation Variables
        isValid = true,
        setIsValid,
        errorMessage = "The field is required"
    } = props

    const [fileData, setFileData] = useState('');
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.type !== 'application/pdf') {
            setError('Please upload a PDF file.');
            setFileData('');
            setFileName('');
            return;
          }
    
          setError('');
          setFileName(file.name);
    
          const reader = new FileReader();
          reader.onload = (e) => {
            setFileData(e.target.result);
          };
          reader.readAsDataURL(file);
        }
    };

    const handleRemovefile = (e) => {
        e.preventDefault();

        setFileData('');
        setFileName('');
    }

    return (
        <div className='InputFileAndLabel' style={{width: width}}>
            
            {label && <label style={{marginBottom:"1rem"}}>{label}</label>}

            <div className='InputFile' style={{height: height, borderColor: isValid ? 'rgba(0, 0, 0, 0.2)' : 'red' }}>
                
                <input 
                    type='file' 
                    accept="application/pdf"
                    id={id}
                    name={name}
                    onChange={handleFileChange}
                    disabled={disabled}
                    style={{paddingLeft: icon && "0"}}
                />
                <label htmlFor={id} className='InputFileUpload'>
                    {fileName ? fileName : "Upload PDF Program File"}
                    {
                    fileName && 
                        <div className='InputFileUploadRemoveBtn' onClick={handleRemovefile}>
                            <img alt='' src={XIcon} />
                        </div>
                    }
                </label>

                {!isValid && <div className='InputFileErrorMessage'>{errorMessage}</div>}
            </div>

        </div>
    )
}
