import React from 'react'
import './TravellersBoxes.css'
import BlackPlus from '../images/icons/blackplus.png'
import Minus from '../images/icons/minus.png'

export default function TravellersBoxes(props) {

    const {
        adults, 
        children, 
        infants, 
        pets, 
        setAdults, 
        setChildren, 
        setInfants, 
        setPets
    } = props
    
    return (
        <div className='TravellersBoxes'>
            <div className='TravellersBox'>
                <div className='TravellersBoxLeft'>
                    <span className='TravellersBoxLeftMainLine'>Ενήλικες</span>
                    <span className='TravellersBoxLeftSubline'>Ηλικίας 13 ετών και άνω</span>
                </div>
                <div className='TravellersBoxRight'>
                    <div className='TravellersMinusPlusBtn' onClick={()=>adults>1 && setAdults(v=>v-1)}>
                        <img alt='' src={Minus} />
                    </div>
                    <span className='TravellersHowMany'>{adults}</span>
                    <div className='TravellersMinusPlusBtn' onClick={()=>adults<16 && setAdults(v=>v+1)}>
                        <img alt='' src={BlackPlus} />
                    </div>
                </div>
            </div>

            <div className='TravellersBox'>
                <div className='TravellersBoxLeft'>
                    <span className='TravellersBoxLeftMainLine'>Παιδιά</span>
                    <span className='TravellersBoxLeftSubline'>Ηλικίας 2–12 ετών</span>
                </div>
                <div className='TravellersBoxRight'>
                    <div className='TravellersMinusPlusBtn' onClick={()=>children>0 && setChildren(v=>v-1)}>
                        <img alt='' src={Minus} />
                    </div>
                    <span className='TravellersHowMany'>{children}</span>
                    <div className='TravellersMinusPlusBtn' onClick={()=>children<15 && setChildren(v=>v+1)}>
                        <img alt='' src={BlackPlus} />
                    </div>
                </div>
            </div>

            <div className='TravellersBox'>
                <div className='TravellersBoxLeft'>
                    <span className='TravellersBoxLeftMainLine'>Βρέφη</span>
                    <span className='TravellersBoxLeftSubline'>Κάτω των 2 ετών</span>
                </div>
                <div className='TravellersBoxRight'>
                    <div className='TravellersMinusPlusBtn' onClick={()=>infants>0 && setInfants(v=>v-1)}>
                        <img alt='' src={Minus} />
                    </div>
                    <span className='TravellersHowMany'>{infants}</span>
                    <div className='TravellersMinusPlusBtn' onClick={()=>infants<5 && setInfants(v=>v+1)}>
                        <img alt='' src={BlackPlus} />
                    </div>
                </div>
            </div>

            <div className='TravellersBox' style={{border:"none"}}>
                <div className='TravellersBoxLeft'>
                    <span className='TravellersBoxLeftMainLine'>Κατοικίδια</span>
                    {/* <span className='TravellersBoxLeftSubline'>Ταξιδεύετε με ζώο υπηρεσίας;</span> */}
                </div>
                <div className='TravellersBoxRight'>
                    <div className='TravellersMinusPlusBtn' onClick={()=>pets>0 && setPets(v=>v-1)}>
                        <img alt='' src={Minus} />
                    </div>
                    <span className='TravellersHowMany'>{pets}</span>
                    <div className='TravellersMinusPlusBtn' onClick={()=>pets<5 && setPets(v=>v+1)}>
                        <img alt='' src={BlackPlus} />
                    </div>
                </div>
            </div>
        </div>
    )
}
