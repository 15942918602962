import React, { useEffect, useState } from 'react'
import './Package.css'
import StarRate from '../images/icons/star.png'
import Heart from '../images/icons/heart.png'
import FilledHeart from '../images/icons/filledheart.png'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import useAuth from '../auth/useAuth'
import axios from '../api/axios'

const starIconSize = {
    width: "16px",
    height: "16px"
}

const heartIconSize = {
    width: "24px",
    height: "24px"
}

export default function Package(props) {

    const {setLoginSignupPopup} =  props
    const {packageId, date, packagePhoto, agencyPhoto, rate, days, nights, name, priceFrom, currency, agencyId, favorite, ratioDiscount} = props.packa

    // Set search params on package overview
    const [searchParams, setSearchParams] = useSearchParams();
    const adultsParam = searchParams.get('adults') || 1;
    const childrenParam = searchParams.get('children') || 0;
    const infantsParam = searchParams.get('infants') || 0;
    const petsParam = searchParams.get('pets') || 0;


    const navigate = useNavigate()
    const { auth } = useAuth()

    const fetchFavorite = () => {
        // update favorite
        try {
            // const response = axios.post(`url/${packageId}`, !favorite)
        } catch (error) {
            console.error(error)
        }
    }

    const handleClickLike = () => {
        if(!auth?.accessToken)
            setLoginSignupPopup(true);
        else
            fetchFavorite();
    }

    const handleClickAgent = () => {
        navigate(`/agencies/${agencyId}/about`)
    }

    const handleClickPackage = (event) => {
        event.preventDefault();

        if (event.target.closest('.Like') || event.target.closest('.AgentLogo')) {
            event.preventDefault();
        } else {
            const link = event.currentTarget;
            window.open(link.href, '_blank');
        }
    };

    return ( 
        <Link 
            to={`/package/${packageId}/overview?date=${date}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}`}
            style={{textDecoration: "none",color: "#222222"}}
            onClick={handleClickPackage}
        >
            <div className='Package'>
                {/* image */}
                <div className='PackagePhotoContainer'>
                    <img className='PackagePhoto' alt='' src={packagePhoto} />
                    <div className='PackagePhotoContent'>
                        <div 
                            className='AgentLogoContainer'
                            onClick={handleClickAgent}
                        >
                            <img className='AgentLogo' alt='' src={agencyPhoto} />
                        </div>
                        <div onClick={handleClickLike}>
                            <img className='Like' alt='' src={ !favorite ? Heart : FilledHeart } style={{width: heartIconSize.width, height: heartIconSize.height}} />
                        </div>
                    </div>
                    {ratioDiscount !== 0 && <div className='Discount'>- {ratioDiscount}%</div>}
                </div>
                <div className='PackageCardDetails'>
                    <div className='FirstRow'>
                        <span className='Bold'>{name}</span>
                        <div className='Rate'>
                            <img alt='' src={StarRate} style={{width: starIconSize.width, height: starIconSize.height}} />
                            <span>{rate.toFixed(1)}</span>
                        </div>
                    </div>
                    <div className='SecondRow'>
                        <span style={{marginRight:".3rem"}}>From</span>
                        <span className='Bold'>{priceFrom?.toString()}{" " + currency && currency}</span>
                        {ratioDiscount !== 0 && <span className='DiscountText'>{parseInt(priceFrom * (ratioDiscount/100 + 1),10)}</span>}
                        <span className='FourteenPx' style={{marginLeft:".5rem"}}>{days} days • {nights} nights</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}
