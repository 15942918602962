import React, { useState } from 'react'
import './TimeSelect.css'
import useOutsideClick from '../../utilities/useOutsideClick'

export default function TimeSelect(props) {

    const [selectedValue, setSelectedValue] = useState(0)
    const [defaultSelected, setDefaultSelected] = useState(true)
    
    const { 
        selectedIndex = selectedValue, 
        setSelectedIndex = setSelectedValue, 
        items = ['Select an item','item 1','item 2'],
        itemsAreNotDisplayed,
        defaultSelection = items[0], 
        width = "100%", // width of the component
    } = props
    
    const [popupIsOpen, setPopupIsOpen] = useState(false)

    const ref = useOutsideClick(()=>setPopupIsOpen(false));

    return (
        <div ref={ref} className='TimeSelect' onClick={()=>setPopupIsOpen(v=>!v)} style={{width:width}}>
            
            <div className='TimeSelectContainer' style={{border: popupIsOpen && "1px solid rgba(0,0,0,0.4)"}}>
                <span>{defaultSelected ? defaultSelection : items[selectedIndex]}</span>
                {
                popupIsOpen &&
                    <div className='TimeSelectPopup'>
                        <div className='TimeSelectPopupContainer'>
                            {items.map((item,index) => {
                                return  itemsAreNotDisplayed!==index && <span key={index} className='TimeSelectPopupRow' onClick={()=>{setSelectedIndex(index);setDefaultSelected(false)}} style={{backgroundColor:selectedIndex===index?"aliceblue":""}}>
                                            {item}
                                        </span>
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
