import React from 'react';
import './Table.css';

export default function Table(props) {
    const {
        headValues = ['#', 'Name', 'Dates', 'Status', 'Booked Clients', 'Available Seats'],
        bodyValues = [
            {
                place: "Singapore - Bali",
                dates: [{
                    date: "27/04/2024",
                    status: "Active",
                    bookedClients: 8,
                    availableSeats: 2
                }, {
                    date: "01/05/2024",
                    status: "Not Active",
                    bookedClients: 4,
                    availableSeats: 10
                }]
            },
            {
                place: "Singapore - Bali",
                dates: [{
                    date: "01/05/2024",
                    status: "Full",
                    bookedClients: 7,
                    availableSeats: 7
                }]
            },
            {
                place: "Singapore - Bali",
                dates: [{
                    date: "01/05/2024",
                    status: "Draft",
                    bookedClients: 7,
                    availableSeats: 7
                }]
            },
            {},
            {}
        ]
    } = props;

    function addLeadingZeros(number) {
        if (number < 10) {
            return `00${number}`;
        } else if (number < 100) {
            return `0${number}`;
        } else {
            return `${number}`;
        }
    }

    return (
        <table className='Table'>
            <thead>
                <tr>
                    {headValues.map((val, index) => (
                        <th key={index}>{val}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {bodyValues.map((val, index) => (
                    val.dates && val.dates.map((dat, i) => (
                        <tr key={`${index}-${i}`}>
                            {i === 0 ? 
                                <>
                                    <td>{addLeadingZeros(index + 1)}</td>
                                    <td style={{fontWeight:"600",textDecoration:"underline"}}>{val.place}</td>
                                </>
                                :
                                <>
                                    <td></td>
                                    <td></td>
                                </>
                            }
                            <td>{dat.date}</td>
                            <td>{dat.status}</td>
                            <td>{dat.bookedClients}</td>
                            <td>{dat.availableSeats}</td>
                        </tr>
                    ))
                ))}
            </tbody>
        </table>
    );
}
