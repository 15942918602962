import React, { useEffect, useState } from 'react'
import './PackageFilters.css'
import FiltersImg from '../images/icons/filters.png'
import OrderBy from './OrderBy'
import PackagesTable from './PackagesTable'
import axios from '../api/axios'

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function PackageFilters(props) {

    const {setLoginSignupPopup, packages, loading, fetchData, setCurrentOrder} = props
    // Filter Variables (probably will be search params)
    const [filterTableOpen, setFilterTableOpen] = useState(false)

    const [priceRange, setPriceRange] = useState({
        priceMin: "",
        priceMax: ""
    })
    const [continentFilters, setContinentFilters] = useState([])
    const [categoryFilters, setCategoryFilters] = useState([])
    const [tagFilters, setTagFilters] = useState([])

    // API Variables
    const [continents, setContinents] = useState([])
    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])

    const fetchFilterData = async () => {
        try {
            const response = await axios.get('/packages/filterOptions');

            // console.log(response.data.categories)
            setContinents(response.data.continents)
            setCategories(response.data.categories)
            setTags(response.data.tags)

        } catch (error) {
            console.error(error)
        } finally {
            // setLoading(false);
        }
    };

    // console.log(categoryFilters, tagFilters)

    useEffect(() => {
        fetchFilterData();
    }, []);

    useEffect(()=> {
        fetchData(priceRange, continentFilters, categoryFilters, tagFilters)
    },[continentFilters,categoryFilters,tagFilters])

    const handleClickApply = () => {
        fetchData(priceRange, continentFilters, categoryFilters, tagFilters)
    }

    const handleChangePriceRange = (e) => {
        setPriceRange({...priceRange,[e.target.name]:e.target.value})
    }
    
    const handleChangeCheckboxes= (e,filterName) => {
        const { name, checked } = e.target;

        // Determine which array to update based on the checkbox name
        if (filterName === 'continent') {
            // Update continentFilters state
            const updatedFilters = checked
                ? [...continentFilters, name]
                : continentFilters.filter(filter => filter !== name);
            setContinentFilters(updatedFilters);

        } else if (filterName === 'category') {
            // Update categoryFilters state
            const updatedFilters = checked
                ? [...categoryFilters, name]
                : categoryFilters.filter(filter => filter !== name);
            setCategoryFilters(updatedFilters);

        } else if (filterName === 'tag') {
            // Update tagFilters state
            const updatedFilters = checked
                ? [...tagFilters, name]
                : tagFilters.filter(filter => filter !== name);
            setTagFilters(updatedFilters);
        }
    }

    

    return (
        <div className='PackageFilters'>
            <div className='PackageFiltersPanel'>
                <div className='PackageFiltersBtn' onClick={()=>setFilterTableOpen((prevValue) => !prevValue)}>
                    <img alt='' src={FiltersImg} style={{width: iconSize.width, height: iconSize.height}} />
                    <span>Filters</span>
                    {false && <div className='PackageFiltersBtnNotification'> 1 </div>}
                </div>
                
                <OrderBy setCurrentOrder = {setCurrentOrder} />
            </div>

            <div className='PackageFiltersPackagesTable'>

                {
                filterTableOpen &&
                    <div className='PackageFiltersTable'>
                        <div className='PriceRangePackageFilter'>
                            <span>Price Range</span>
                            <div className='PriceRangePackageFilterInputs'>
                                <div className='PriceRangePackageFilterInput'>
                                    €
                                    <input placeholder='0' name='priceMin' value={priceRange.priceMin} onChange={handleChangePriceRange}/>
                                </div>
                                <div className='PriceRangePackageFilterInputsLine'></div>
                                <div className='PriceRangePackageFilterInput'>
                                    €
                                    <input placeholder='1000' name='priceMax' value={priceRange.priceMax} onChange={handleChangePriceRange}/>
                                </div>
                            </div>
                            <div className='ApplyPackageFilters'>
                                <div className='ApplyPackageFiltersBtn' onClick={handleClickApply}>
                                    <span>Apply</span>
                                </div>
                            </div>
                        </div>
                        <div className='CheckboxesPackageFilter'>
                            <span>Continents</span>
                            { continents.map((continent,index) => {
                                return  <div 
                                            key={index}
                                            className='CheckboxesPackageFilterContent'
                                        >
                                            <input 
                                                id={'continentCheckbox' + index}
                                                type='checkbox'
                                                name={continent}
                                                checked={continentFilters.includes(continent)}
                                                onChange={(e) => handleChangeCheckboxes(e,"continent") }
                                            />
                                            <label className='customCheckboxLabelPackageFilter' htmlFor={'continentCheckbox'+ index}>
                                                <div className='customCheckboxIconPackageFilter'></div>
                                                <span>{continent}</span>
                                            </label>
                                        </div>
                            })
                            }
                        </div>
                        <div className='CheckboxesPackageFilter'>
                            <span>Categories</span>
                            { categories.map((category,index) => {
                                
                                return <div 
                                            key={index}
                                            className='CheckboxesPackageFilterContent'
                                        >
                                            <input 
                                                id={'categoryCheckbox' + index}
                                                type='checkbox'
                                                name={category}
                                                checked={categoryFilters.includes(category)}
                                                onChange={(e) => handleChangeCheckboxes(e,"category")}
                                            />
                                            <label className='customCheckboxLabelPackageFilter' htmlFor={'categoryCheckbox'+ index}>
                                                <div className='customCheckboxIconPackageFilter'></div>
                                                <span>{category}</span>
                                            </label>
                                        </div>
                            })
                            }
                        </div>
                        <div className='CheckboxesPackageFilter' style={{border:"none"}}>
                            <span>Tags</span>
                            { tags.map((tag,index) => {
                                return  <div 
                                            key={index}
                                            className='CheckboxesPackageFilterContent'
                                        >
                                            <input 
                                                id={'tagCheckbox' + index}
                                                type='checkbox'
                                                name={tag}
                                                checked={tagFilters.includes(tag)}
                                                onChange={(e) => handleChangeCheckboxes(e,"tag")}
                                            />
                                            <label className='customCheckboxLabelPackageFilter' htmlFor={'tagCheckbox'+ index }>
                                                <div className='customCheckboxIconPackageFilter'></div>
                                                <span>{tag}</span>
                                            </label>
                                        </div>
                            })
                            }
                        </div>
                        
                    </div>
                }
                
                {/* Filter's Content */}
                <PackagesTable 
                    setLoginSignupPopup = {setLoginSignupPopup}
                    packages = {packages}
                    loading = {loading}
                />

            </div>
        </div>
    )
}
