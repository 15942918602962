import React, { useEffect, useState } from 'react'
import './InclusionsExclusions.css'
import GreenTick from '../images/icons/greentick.png'
import XIcon from '../images/icons/x.png'
import Plus from '../images/icons/plus.png'
import InclusionExclusion from '../components/InclusionExclusion'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from '../api/axios'

export default function InclusionsExclusions() {

    const navigate = useNavigate();
    const {packageId,dateParam} = useOutletContext()

    const [inclusionsExclusionsDetails, setInclusionsExclusionsDetails] = useState({})

    const {inclusions,optionals,exclusions} = inclusionsExclusionsDetails

    const fetchInclusionsExclusionsData = async () => {
            try {

                const response = await axios.get(`/packages/${packageId}/inclusionsExclusions?date=${dateParam}`);
      
                setInclusionsExclusionsDetails(response.data);

            } catch (error) {
                navigate("/")
                console.error(error)
            }
    };

    useEffect(() => {
        fetchInclusionsExclusionsData()
    },[packageId,dateParam,navigate])

    return (
        <div className='InclusionsExclusions'>

            <InclusionExclusion 
                name = "Inclusions"
                icon = {GreenTick}
                items = {inclusions?.split('||')}
            />
            <InclusionExclusion 
                name = "Optionals"
                icon = {Plus}
                items = {optionals?.split('||')}
            />
            <InclusionExclusion 
                name = "Exclusions"
                icon = {XIcon}
                items = {exclusions?.split('||')}
            />

            <span style={{fontStyle:"italic"}}>* Optionals add an extra cost in the package price</span>

        </div>
    )
}
