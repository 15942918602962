import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function AgentRefundPolicy() {

    const {aboutDetails} = useOutletContext()

    return (
        <div className='AgentRefundPolicy'>
            {aboutDetails.refundPolicy}
        </div>
    )
}
