import axios from '../api/axios'
import useAuth from './useAuth'

const useRefreshToken = () => {

    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refreshToken', {
            // This setting allow us to send cookies with our request
            // This request is going to send along our cookie that has the response token
            // Its a secured cookie that we never see in our js code but axios can send it to the backend endpoint that we need it to
            withCredentials: true
        })

        setAuth(prev => {
            // console.log(JSON.stringify(prev))
            // console.log('AcTo: ',response.data.data.accessToken)
            return { 
                ...prev, 
                accessToken: response.data.data.accessToken 
            }
        })

        return response.data.data.accessToken;
    }

    return refresh;
}

export default useRefreshToken;