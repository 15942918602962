import React, { useEffect, useState } from 'react'
import './StepDay.css'
import Button from '../../components/form-components/Button'
import InputText from '../../components/form-components/InputText'
import InputTextArea from '../../components/form-components/InputTextArea'
import MultipleAutocomplete from '../../components/form-components/MultipleAutocomplete'
import TrashCan from '../../images/icons/white_trash_can.png'
import DatePicker from '../../components/form-components/DatePicker'
import Autocomplete from '../../components/form-components/Autocomplete'
import InputNumber from '../../components/form-components/InputNumber'
import Select from '../../components/form-components/Select'
import axios from '../../api/axios'

export default function StepDay(props) {

    const {
        handleSetCurrentStep,
        setPackageInfo,
        currentDate,
        
        currentDateIndex,
        setCurrentDateIndex,
        currentDayIndex,
        setCurrentDayIndex,

        dates,
        dayInfo
    } = props

    const {
        shortDesc,
        description,
        visitedAreas,
        destinations,
        flights,
        hotelCheckInDatetime,
        hotelCheckOutDatetime,
        hotels
    } = dayInfo

    // Places API variables
    const [visitedAreasInput, setVisitedAreasInput] = useState("")
    const [visitedAreasResults, setVisitedAreasResults] = useState([]) // For google place api -> Result: {placeName: "", placeId: 1} 
    const [visitedAreasPlaceNames, setVisitedAreasPlaceNames] = useState([]) // For giving the right values to autocomplete component -> ["Athens","Madrid",...]

    const [destinationsInput, setDestinationsInput] = useState("")
    const [destinationsResults, setDestinationsResults] = useState([]) // For google place api -> Result: {placeName: "", placeId: 1} 
    const [destinationsPlaceNames, setDestinationsPlaceNames] = useState([]) // For giving the right values to autocomplete component -> ["Athens","Madrid",...]

    // Flights API variables
    const [departureAirportResults, setDepartureAirportResults] = useState([]) // For google place api -> Result: {placeName: "", placeId: 1} 
    const [arrivalAirportResults, setArrivalAirportResults] = useState([]) // For google place api -> Result: {placeName: "", placeId: 1} 
    const [airlineResults, setAirlineResults] = useState([]) // For google place api -> Result: {placeName: "", placeId: 1} 

    // AvailableHotelList
    const [availableHotelList, setAvailableHotelList] = useState([])

    // New Hotel
    const [newHotel, setNewHotel] = useState({
        hotelName: "",
        stars: "",
        roomTypes: [
            {
                roomType:"", 
                price:"",
                description:""
            }
        ],
        facilities: []
    })
    const [roomTypesResults, setRoomTypesResults] = useState([])
    const [facilitiesInput, setFacilitiesInput] = useState("")
    const [facilitiesResults, setFacilitiesResults] = useState([])

    const [openPopupId, setOpenPopupId] = useState(null); // Ensuring only one popup will be opened

    const handleSetOpen = (id, isOpen) => {
        setOpenPopupId(isOpen ? id : null);
    };

    // Visited Areas
        useEffect(() => {
            if(visitedAreasInput !== ''){
                fetchPlacesData(visitedAreasInput,setVisitedAreasResults)
            } else {
                setVisitedAreasResults([])
            }
        },[visitedAreasInput])
        // For giving the right values to autocomplete component
        useEffect(() => {
            let temp = []
            visitedAreasResults.map(item => {
                temp.push(item.placeName)
            })

            setVisitedAreasPlaceNames(temp)
        },[visitedAreasResults])

    // Destinastions
        useEffect(() => {
            if(destinationsInput !== ''){
                fetchPlacesData(destinationsInput,setDestinationsResults)
            } else {
                setDestinationsResults([])
            }
        },[destinationsInput])
        // For giving the right values to autocomplete component
        useEffect(() => {
            let temp = []
            destinationsResults.map(item => {
                temp.push(item.placeName)
            })

            setDestinationsPlaceNames(temp)
        },[destinationsResults])
    
    
    // Places API
    const fetchPlacesData = async (newValue, setPlaces) => {
        try {
            const response = await axios.get(`/search/autocompletePlace/${newValue.replace(/ /g, "%20")}`);
            // console.log(response.data)
            setPlaces(response.data)
        } catch (error) {
            console.error("Places API " + error)
        }
    }

    // Available Hotel List
    const fetchAvailableHotelData = async () => {
        try {
            // const response = await axios.get('');
            // console.log(response.data)
            setAvailableHotelList(['Hotel 1','Hotel 2','Hotel 3','Hotel 4','Hotel 5','Hotel 6'])
        } catch (error) {
            console.error("Available Hotel List" + error)
        }
    }
    // Room Types
    const fetchRoomTypesData = async () => {
        try {
            // const response = await axios.get('');
            // console.log(response.data)
            setRoomTypesResults(['Single','Double','Twin'])
        } catch (error) {
            console.error("Room Types" + error)
        }
    }
    // Facilities
    const fetchFacilitiesData = async () => {
        try {
            // const response = await axios.get('');
            // console.log(response.data)
            setFacilitiesResults(['Spa','Gym','Breakfast'])
        } catch (error) {
            console.error("Facilities" + error)
        }
    }
    useEffect(() => {
        fetchAvailableHotelData()
        fetchRoomTypesData()
        fetchFacilitiesData()
    },[])
    
    // Handling Short Description
    const handleSetShortDesc = (value) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
    
            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                shortDesc: value
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };
    
            return { ...prev, dates: updatedDates };
        });
    }

    // Handling Description
    const handleSetDescription = (value) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
    
            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                description: value
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };
    
            return { ...prev, dates: updatedDates };
        });
    }

    // Handling Visited Areas
    const handleSetVisitedAreas = (value) => {

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedVisitedAreas = [...updatedDaysInfo[currentDayIndex].visitedAreas];
    
            updatedVisitedAreas.push(value);
    
            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                visitedAreas: updatedVisitedAreas
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };
    
            return { ...prev, dates: updatedDates };
        });
    }

    const handleRemoveVisitedAreas = (value) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            let updatedVisitedAreas = [...updatedDaysInfo[currentDayIndex].visitedAreas];
    
            updatedVisitedAreas = updatedVisitedAreas.filter(va => va !== value);
    
            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                visitedAreas: updatedVisitedAreas
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };
    
            return { ...prev, dates: updatedDates };
        });
    }

    // Handling Destinations
    const handleSetDestinations = (value) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedDestinations = [...updatedDaysInfo[currentDayIndex].destinations];
    
            updatedDestinations.push(value);
    
            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                destinations: updatedDestinations
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };
    
            return { ...prev, dates: updatedDates };
        });
    }

    const handleRemoveDestinations = (value) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            let updatedDestinations = [...updatedDaysInfo[currentDayIndex].destinations];
    
            updatedDestinations = updatedDestinations.filter(dest => dest !== value);
    
            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                destinations: updatedDestinations
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };
    
            return { ...prev, dates: updatedDates };
        });
    }

    // Flights
    const handleAddFlight = () => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights.push({
                departureDatetime: "",
                arrivalDatetime: "",
                departureAirport: "",
                arrivalAirport: "",
                airline: "",
                flightCode: ""
            })

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    };
    const handleRemoveFlight = (index) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            let updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights = updatedFlights.filter((_,idx) => idx !== index )

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    };
    
    const handleSetDepartureDatetime = (newValue, index, isValid) => {
        if(!isValid){
            newValue = ''
        }

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights[index] = {
                ...updatedFlights[index],
                departureDatetime: newValue
            };

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        });
    }

    const handleSetArrivalDatetime = (newValue, index, isValid) => {
        if(!isValid){
            newValue = ''
        }

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights[index] = {
                ...updatedFlights[index],
                arrivalDatetime: newValue
            };

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        });
    }

    const handleSetDepartureAirport = (newValue, index) => {

        if(newValue !== ''){
            // fetchPlacesData(newValue,setDepartureAirportResults)
            setDepartureAirportResults(['qqq','www','wee','rrr','aaa'])
        } else {
            setDepartureAirportResults([])
        }

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights[index] = {
                ...updatedFlights[index],
                departureAirport: newValue
            };

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        });
    }

    const handleSetArrivalAirport = (newValue, index) => {

        if(newValue !== ''){
            // fetchPlacesData(newValue,setArrivalAirportResults)
            setArrivalAirportResults(['qqq','www','wee','rrr','aaa'])
        } else {
            setArrivalAirportResults([])
        }

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights[index] = {
                ...updatedFlights[index],
                arrivalAirport: newValue
            };

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        });
    }

    const handleSetAirline = (newValue, index) => {

        if(newValue !== ''){
            // fetchPlacesData(newValue,setAirlineResults)
            setAirlineResults(['qqq','www','wee','rrr','aaa'])
        } else {
            setAirlineResults([])
        }

        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights[index] = {
                ...updatedFlights[index],
                airline: newValue
            };

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        });
    }

    const handleSetFlightCode = (newValue,index) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates];
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedFlights = [...updatedDaysInfo[currentDayIndex].flights];

            updatedFlights[index] = {
                ...updatedFlights[index],
                flightCode: newValue
            };

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                flights: updatedFlights
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        });
    }

    // Hotels
    const handleSetHotelCheckInDatetime = (newValue, isValid) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                hotelCheckInDatetime: newValue
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    }

    const handleSetHotelCheckOutDatetime = (newValue, isValid) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                hotelCheckOutDatetime: newValue
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    }

    const handleAddHotel = () => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedHotels = [...updatedDaysInfo[currentDayIndex].hotels];

            updatedHotels.push({
                hotelName: "",
                checkInDatetime: "",
                checkOutDatetime: ""
            })

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                hotels: updatedHotels
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    };
    const handleRemoveHotel = (index) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            let updatedHotels = [...updatedDaysInfo[currentDayIndex].hotels];

            updatedHotels = updatedHotels.filter((_,idx) => idx !== index)

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                hotels: updatedHotels
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    };

    const handleSetHotelName = (newValue, index) => {
        setPackageInfo(prev => {
            const updatedDates = [...prev.dates]
            const updatedDaysInfo = [...prev.dates[currentDateIndex].daysInfo];
            const updatedHotels = [...updatedDaysInfo[currentDayIndex].hotels];

            updatedHotels[index] = {
                ...updatedHotels[index],
                hotelName: newValue
            }

            updatedDaysInfo[currentDayIndex] = {
                ...updatedDaysInfo[currentDayIndex],
                hotels: updatedHotels
            };
    
            updatedDates[currentDateIndex] = {
                ...updatedDates[currentDateIndex],
                daysInfo: updatedDaysInfo
            };

            return {...prev, dates: updatedDates}
        })
    }

    // Add New Hotel
    const handleCancelNewHotel = () => {
        setNewHotel({
            hotelName: "",
            stars: "",
            roomTypes: [
                {
                    roomType:"", 
                    price:"",
                    description:""
                }
            ],
            facilities: []
        })
    }

    const handleSetNewHotelName = (newValue) => {
        setNewHotel(prev => {
            return {...prev, hotelName: newValue}
        });
    };

    const handleSetNewHotelStars = (newValue) => {
        setNewHotel(prev => {
            return {...prev, stars: newValue}
        });
    };
    
    const handleAddRoomType = () => {
        setNewHotel(prev => {

            const updatedRoomTypes = [...prev.roomTypes] 

            updatedRoomTypes.push({
                roomType:"", 
                price:"",
                description:""
            })
            
            return {...prev, roomTypes: updatedRoomTypes}
        });
    };

    const handleRemoveRoomType = (index) => {
        setNewHotel(prev => {

            let updatedRoomTypes = [...prev.roomTypes]

            updatedRoomTypes = updatedRoomTypes.filter((_, idx) => idx !== index)

            return {...prev, roomTypes: updatedRoomTypes}
        });
    };

    const handleSetRoomType = (selectedIndex,index) => {
        setNewHotel(prev => {

            const updatedRoomTypes = [...prev.roomTypes]

            updatedRoomTypes[index] = {
                ...updatedRoomTypes[index],
                roomType: roomTypesResults[selectedIndex]
            }

            return {...prev, roomTypes: updatedRoomTypes}
        });
    }

    const handleSetRoomTypePrice = (newValue,index) => {
        setNewHotel(prev => {

            const updatedRoomTypes = [...prev.roomTypes]

            updatedRoomTypes[index] = {
                ...updatedRoomTypes[index],
                price: newValue
            }

            return {...prev, roomTypes: updatedRoomTypes}
        });
    }
    
    const handleSetRoomTypeDescription = (newValue,index) => {
        setNewHotel(prev => {

            const updatedRoomTypes = [...prev.roomTypes]

            updatedRoomTypes[index] = {
                ...updatedRoomTypes[index],
                description: newValue
            }

            return {...prev, roomTypes: updatedRoomTypes}
        });
    }

    const handleSetFacilities = (newValue) => {
        setNewHotel(prev => {
            const updatedFacilities = [...prev.facilities]
            updatedFacilities.push(newValue)
            return {...prev, facilities: updatedFacilities}
        })
    }

    const handleRemoveFacilities = (value) => {
        setNewHotel(prev => {
            let updatedFacilities = [...prev.facilities]
            updatedFacilities = updatedFacilities.filter(fa => fa !== value)
            return {...prev, facilities: updatedFacilities}
        })
    }

    // Check Validations to continue
    const checkValidationFormInputs = () => {

        const dateNumber = currentDateIndex+1
        const dayNumber = currentDayIndex+1

        // Days validation 
        let isShortDescValid = true 

        if(isShortDescValid){

            let nextStep;
            if(dayNumber < dates[currentDateIndex].daysInfo.length){
                nextStep = 'Day'
                setCurrentDayIndex(prev => prev+1)
            } else if (dateNumber < dates.length && dates[currentDateIndex+1].date !== ''){
                nextStep = 'Dates'
                setCurrentDateIndex(prev => prev+1)
            } else {
                nextStep = 'Inclusions&Necessary'
                setCurrentDateIndex(0)
                setCurrentDayIndex(0)
            }

            handleSetCurrentStep(nextStep)
        }
    }

    const renderFlightInputs = flights.map((item, index) => {

        const {
            departureDatetime,
            arrivalDatetime,
            departureAirport,
            arrivalAirport,
            airline,
            flightCode
        } = item

        return <div 
                    key={index}
                    className='StepDayFlight'
                >
                    <div className='StepDayFlightLineNumber'>
                        {index + 1}.
                    </div>

                    
                    <div className='StepDayFlightMiddleCol'>
                        <div className='StepDayFlightRow' style={{marginTop:"0"}}>
                            <div style={{width:"100%", marginRight:".5rem"}}>
                                <DatePicker 
                                    id = {index}
                                    value = {departureDatetime}
                                    onChange = {(value, isValid)=>handleSetDepartureDatetime(value, index, isValid)}
                                />
                            </div>
                            <div style={{width:"100%", marginLeft:".5rem"}}>
                                <DatePicker 
                                    id = {index}
                                    value = {arrivalDatetime}
                                    onChange = {(value, isValid)=>handleSetArrivalDatetime(value, index, isValid)}
                                />
                            </div>
                        </div>
                        
                        <div className='StepDayFlightRow'>
                            <div style={{width:"100%", marginRight:".5rem"}}>
                                <Autocomplete 
                                    id = {`Departure Airport ${index}`}
                                    placeholder = "Departure Airport"
                                    items = {departureAirportResults}
                                    value = {departureAirport}
                                    onChange = {(value) => handleSetDepartureAirport(value, index)}
                                    addNew = {false}
                                    isOpen={openPopupId === `Departure Airport ${index}`}
                                    setOpen={handleSetOpen}
                                />
                            </div>
                            <div style={{width:"100%", marginLeft:".5rem"}}>
                                <Autocomplete 
                                    id = {`Arrival Airport ${index}`}
                                    placeholder = "Arrival Airport"
                                    items = {arrivalAirportResults}
                                    value = {arrivalAirport}
                                    onChange = {(value) => handleSetArrivalAirport(value, index)}
                                    addNew = {false}
                                    isOpen={openPopupId === `Arrival Airport ${index}`}
                                    setOpen={handleSetOpen}
                                />
                            </div>
                        </div>

                        <div className='StepDayFlightRow'>
                            <Autocomplete 
                                id = {`Airline ${index}`}
                                placeholder = "Airline"
                                items = {airlineResults}
                                value = {airline}
                                onChange = {(value) => handleSetAirline(value, index)}
                                addNew = {false}
                                isOpen={openPopupId === `Airline ${index}`}
                                setOpen={handleSetOpen}
                            />
                        </div>
                        <div className='StepDayFlightRow'>
                            <InputText 
                                placeholder = "Flight Code"
                                value = {flightCode}
                                onChange = {(value) => handleSetFlightCode(value,index)}
                            />
                        </div>

                    { ( flights.length > 0 && index < (flights.length-1) ) && 
                        <div style={{margin:"2rem 0"}}>
                            <div style={{borderBottom: "1px solid rgba(0,0,0,0.2)"}}></div>
                        </div>}

                    </div>

                    {
                    // index !== 0 &&
                        <div className='StepDayFlightDeleteBtn'>
                            <Button 
                                icon = {TrashCan}
                                btnClass = "danger"
                                height = "3rem"
                                width = "3rem"
                                handleSubmit = {()=>handleRemoveFlight(index)}
                            />
                        </div>
                    }
                </div>
    });
    
    const renderRoomTypesInputs = newHotel.roomTypes.map((item, index) => {

        const {
            roomType,
            price,
            description
        } = item

        return <div 
                    key={index}
                    className='StepDayFlight'
                >
                    <div className='StepDayFlightLineNumber'>
                        {index + 1}.
                    </div>
                    
                    <div className='StepDayFlightMiddleCol'>
                        <div className='StepDayFlightRow' style={{marginTop:"0"}}>
                            <div style={{width:"100%", marginRight:".5rem"}}>
                                <Select 
                                    id = 'Room Types'
                                    firstItem = {"Select a Room Type"}
                                    items = {roomTypesResults}
                                    defaultIndex = {roomTypesResults.indexOf(roomType)}
                                    onSelect = {(selectedIndex) => handleSetRoomType(selectedIndex,index)}
                                    isOpen={openPopupId === 'Room Types'}
                                    setOpen={handleSetOpen}
                                />
                            </div>
                            <div style={{width:"100%", marginLeft:".5rem"}}>
                                <InputNumber 
                                    placeholder = "Price"
                                    value = {price}
                                    onChange ={(value) => handleSetRoomTypePrice(value,index)}
                                />
                            </div>
                        </div>
                        
                        <div className='StepDayFlightRow'>
                            <InputText
                                placeholder = "Description"
                                value = {description}
                                onChange ={(value) => handleSetRoomTypeDescription(value,index)}
                            />
                        </div>

                    { ( newHotel.roomTypes.length > 0 && index < (newHotel.roomTypes.length-1) ) && 
                        <div style={{margin:"1.5rem 0 .5rem 0"}}>
                            <div style={{borderBottom: "1px solid rgba(0,0,0,0.2)"}}></div>
                        </div>}

                    </div>

                    {
                    // index !== 0 &&
                        <div className='StepDayFlightDeleteBtn'>
                            <Button 
                                icon = {TrashCan}
                                btnClass = "danger"
                                height = "3rem"
                                width = "3rem"
                                handleSubmit = {()=>handleRemoveRoomType(index)}
                            />
                        </div>
                    }
                </div>
    });

    const addNewHotel = <div className='StepDayAddNewHotel'>
                    <div style={{marginTop:"1.5rem"}}>
                        <InputText 
                            placeholder = "Hotel Name"
                            label = "Hotel Name"
                            value = {newHotel.hotelName}
                            onChange = {(value) => handleSetNewHotelName(value)}
                        />
                    </div>
                    <div style={{marginTop:"1.5rem"}}>
                        <InputNumber
                            placeholder = "Stars"
                            label = "Stars"
                            value = {newHotel.stars}
                            onChange = {(value) => handleSetNewHotelStars(value)}
                        />
                    </div>
                    <div style={{marginTop:"1.5rem"}}>
                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        <span>Room Types</span>
                        <Button 
                            name = "ADD A ROOM TYPE"
                            handleSubmit = {handleAddRoomType}
                        />
                        </div>
                    </div>
                    <div style={{marginTop:"1.5rem"}}>
                        {renderRoomTypesInputs}
                    </div>
                    <div style={{margin:"1.5rem 0"}}>
                        <MultipleAutocomplete
                            id = 'Facilities'
                            placeholder = "Facilities"
                            label = "Facilities"

                            value = {facilitiesInput}
                            onChangeInput = {(value) => setFacilitiesInput(value)}
                            items = {facilitiesResults}
                            values = {newHotel.facilities}
                            onChange = {(value) => {handleSetFacilities(value)}}
                            onRemove = {(value) => {handleRemoveFacilities(value)}}
                            isOpen={openPopupId === 'Facilities'}
                            setOpen={handleSetOpen}
                        />
                    </div>
                </div>

    const renderHotelInputs = hotels.map((item, index) => {

        const {
            hotelId,
            hotelName
        } = item

        return <div 
                    key={index}
                    className='StepDayFlight'
                >
                    <div className='StepDayFlightLineNumber'>
                        {index + 1}.
                    </div>

                    
                    <Autocomplete 
                        id = {`Hotel Option ${index}`}
                        placeholder = "Hotel Name"
                        items = {availableHotelList}
                        value = {hotelName}
                        onChange = {(value) => handleSetHotelName(value, index)}
                        isOpen = {openPopupId === `Hotel Option ${index}`}
                        setOpen = {handleSetOpen}

                        addNewTitle = "Add New Hotel"
                        addNewContent = {addNewHotel}
                        handleInitialize = {(value) => handleSetNewHotelName(value)}
                        handleCancel = {handleCancelNewHotel}
                        handleSubmit = {() => handleSetHotelName(newHotel.hotelName, index)}
                    />

                    {
                    // index !== 0 &&
                        <div className='StepDayFlightDeleteBtn'>
                            <Button 
                                icon = {TrashCan}
                                btnClass = "danger"
                                height = "3rem"
                                width = "3rem"
                                handleSubmit = {()=>handleRemoveHotel(index)}
                            />
                        </div>
                    }
                </div>
    });

    
    
    return (
        <div className='StepDay'>
            <div className='StepDayTitle'>
                {`Day ${currentDayIndex+1} - ${currentDate}`}
            </div>

            <form>

                <div style={{marginTop:"1.5rem"}}>
                    <InputText 
                        label = "Short Description"
                        placeholder = "Short Description"
                        value = {shortDesc}
                        onChange = {(value) => handleSetShortDesc(value) }
                    />
                </div>
                <div style={{marginTop:"1.5rem"}}>
                    <InputTextArea
                        label = "Description"
                        placeholder = "Description"
                        height = "10rem"
                        value = {description}
                        onChange = {(value) => handleSetDescription(value)}
                    />
                </div>
                <div style={{marginTop:"1.5rem"}}>
                    <MultipleAutocomplete
                        id = "Visited Areas"
                        label = "Visited Areas"
                        placeholder = "Visited Areas"
                        value = {visitedAreasInput}
                        onChangeInput = {(value) => setVisitedAreasInput(value)}
                        items = {visitedAreasPlaceNames}
                        values = {visitedAreas}
                        onChange = {(value) => {handleSetVisitedAreas(value)}}
                        onRemove = {(value) => {handleRemoveVisitedAreas(value)}}
                        isOpen={openPopupId === 'Visited Areas'}
                        setOpen={handleSetOpen}
                    />
                </div>
                <div style={{marginTop:"1.5rem"}}>
                    <MultipleAutocomplete
                        id = "Destinations"
                        label = "Destinations"
                        placeholder = "Destinations"
                        value = {destinationsInput}
                        onChangeInput = {(value) => {setDestinationsInput(value)}}
                        items = {destinationsPlaceNames}
                        values = {destinations}
                        onChange = {(value) => {handleSetDestinations(value)}}
                        onRemove = {(value) => {handleRemoveDestinations(value)}}
                        isOpen={openPopupId === 'Destinations'}
                        setOpen={handleSetOpen}
                    />
                </div>

                <div style={{marginTop:"1.5rem"}}>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <span>Flights</span>
                    <Button 
                        name = "ADD A FLIGHT"
                        handleSubmit = {handleAddFlight}
                    />
                    </div>
                </div>
                <div style={{marginTop:"1.5rem"}}>
                    {renderFlightInputs}
                </div>

                <div style={{marginTop:"1.5rem"}}>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <span>Hotel Options</span>
                    <Button 
                        name = "ADD A HOTEL"
                        handleSubmit = {handleAddHotel}
                    />
                    </div>
                </div>
                <div style={{marginTop:"1.5rem"}}>
                    <div className='StepDayFlightCheckInOut'>
                        <div style={{width:"100%", marginRight:".5rem"}}>
                            <DatePicker 
                                label = "Check-In"
                                value = {hotelCheckInDatetime}
                                onChange = {(value, isValid)=>handleSetHotelCheckInDatetime(value, isValid)}
                            />
                        </div>
                        <div style={{width:"100%", marginLeft:".5rem"}}>
                            <DatePicker 
                                label = "Check-Out"
                                value = {hotelCheckOutDatetime}
                                onChange = {(value, isValid)=>handleSetHotelCheckOutDatetime(value, isValid)}
                            />
                        </div>
                    </div>
                    {renderHotelInputs}
                </div>

                <div style={{marginTop:"5rem",display:"flex",justifyContent:"flex-end"}}>
                    <Button 
                        name = "NEXT"
                        btnClass = "dark"
                        handleSubmit = {checkValidationFormInputs}
                    />
                </div>
            </form>

        </div>
    )
}
