import React, { useState } from 'react'
import './OrderBy.css'
import ArrowDown from '../images/icons/arrowDown.png'
import useOutsideClick from '../utilities/useOutsideClick'

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function OrderBy(props) {

    const {setCurrentOrder} = props
    
    const [popupIsOpen, setPopupIsOpen] = useState(false)
    const [orderBy ,setOrderBy] = useState([
        {name: 'Latest Departures', order: 0, api: 'latestDepartures'},
        {name: 'Price Ascending', order: 1, api: 'priceAscending'},
        {name: 'Price Descending', order: 2, api: 'priceDescending'},
        {name: 'Top Rated', order: 3, api: 'topRated'},
        {name: 'Top Offers', order: 4, api: 'topOffers'}
    ])

    const ref = useOutsideClick(handleClosePopUp);

    function handleClosePopUp() {
        setPopupIsOpen(false)
    }

    const handleOrderByClick = (clickedItem) => {
        const newOrderBy = [
            clickedItem,
            ...orderBy.filter(item => item !== clickedItem).sort((a, b) => a.order - b.order)
        ];
        setOrderBy(newOrderBy);

        // call api
        setCurrentOrder(clickedItem.api)
        // console.log(clickedItem.api)
    };

    return (
        <div ref={ref} className='OrderByBtn' onClick={()=>setPopupIsOpen(v=>!v)}>
            <span>{orderBy[0].name}</span>
            <img alt='' src={ArrowDown} style={{width: iconSize.width, height: iconSize.height}} />


            {popupIsOpen && 
                <div className='OrderByPopUp'>
                    {
                        orderBy.slice(1).map((ord,index) => {
                            return  <div key={index} className='OrderByPopUpBtn' onClick={() => handleOrderByClick(ord)}>
                                        {ord.name}
                                    </div>
                        })
                    }
                    
                </div>
            }
        </div>
    )
}
