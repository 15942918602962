import React from 'react'
import './TableContainer.css'

export default function TableContainer(props) {

    const {
            width = "100%",
            headTitle = "Head Title", 
            headLabel = null, 
            headBtnName = null, 
            headBtnHandleClick = null,
            body
        } = props

    return (
        <div className='TableContainer' style={{width:width}}>
            <div className='TableContainerHeader'>
                <div className='TableContainerHeaderLeft'>
                    <span className='TwentyPx Bold'>{headTitle}</span>
                    {headLabel &&
                        <div className='TableContainerHeadLabel'>{headLabel}</div>
                    }
                </div>
                
                <div className='TableContainerHeaderRight'>
                    {headBtnName &&
                        <div 
                            className='TableContainerHeadBtn'
                            onClick={headBtnHandleClick}
                        >
                            {headBtnName}
                        </div>
                    }
                </div>
                
            </div>
            <div className='TableContainerBody'>
                {body}
            </div>
        </div>
    )
}
