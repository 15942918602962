import React, { useState } from 'react'
import './CountryCode.css'
import BlackArrowDown from '../images/icons/blackarrowdown.png'
import useOutsideClick from '../utilities/useOutsideClick'

const iconSize = {
    width: "12px",
    height: "12px"
}

const countryCodes = [
    {
        country: "Ελλάδα",
        code: "30"
    },
    {
        country: "German",
        code: "299"
    },
    {
        country: "France",
        code: "401"
    },
    {
        country: "US",
        code: "999"
    },
    {
        country: "UK",
        code: "502"
    },
    {
        country: "France",
        code: "401"
    },
    {
        country: "US",
        code: "999"
    },
    {
        country: "UK",
        code: "502"
    },
    {
        country: "France",
        code: "401"
    },
    {
        country: "US",
        code: "999"
    },
    {
        country: "UK",
        code: "502"
    }
]

export default function CountryCode() {

    const [popupIsOpen, setPopupIsOpen] = useState(false)

    const ref = useOutsideClick(()=>setPopupIsOpen(false));

    return (
        <div ref={ref} className='CountryCode' onClick={()=>setPopupIsOpen(v=>!v)}>
            
            <span>Ελλάδα (+30)</span>
            <img alt='' src={BlackArrowDown} style={{ width: iconSize.width, height: iconSize.height, marginLeft:".5rem" }}/>

            {
            popupIsOpen &&
                <div className='CountryCodePopup'>
                    <div className='CountryCodePopupContainer'>
                        {countryCodes.map((countrycode,index) => {
                            return  <span key={index} className='CountryCodePopupRow'>
                                        {countrycode.country + " (+" + countrycode.code + ")"}
                                    </span>
                        })}
                    </div>
                </div>
            }
        </div>
    )
}
