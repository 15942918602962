import React, { useEffect, useState } from 'react'
import './AvailableHours.css'
import Trash from '../images/icons/trash.png'
import TimeSelect from './form-components/TimeSelect'


export default function AvailableHours(props) {

    const {
        width = "100%"
    } = props

    const [hoursOfDay, setHoursOfDay] = useState([]);

    const [weekValues, setWeekValues] = useState([
        {
            day: 'Monday',
            // startTime: '05:00 PM',
            // endTime: '10:00 PM',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
        {
            day: 'Tuesday',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
        {
            day: 'Wednesday',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
        {
            day: 'Thursday',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
        {
            day: 'Friday',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
        {
            day: 'Saturday',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
        {
            day: 'Sunday',
            addHourBtnClicked: false,
            startSelectedIndex: 9,
            endSelectedIndex: 17,
        },
    ])

    const hourFormat = (hour) => {
        return hour < 12 ? `${hour === 0 ? 12 : hour}:00 AM` : hour===24 ? '11:59 PM' : `${hour === 12 ? 12 : hour - 12}:00 PM`
    }
    // console.log(weekValues[0].day,hourFormat(weekValues[0].startSelectedIndex),hourFormat(weekValues[0].endSelectedIndex))

    useEffect(() => {
        const generateHoursArray = () => {
        const hours = [];
        for (let hour = 0; hour < 24; hour++) {
            const formattedHour = hourFormat(hour);
            hours.push(formattedHour);
        }
        hours.push('11:59 PM')
        return hours;
        };

        setHoursOfDay(generateHoursArray());
    }, []);


    const handleClick = (i) => {

        setWeekValues(prev => {
            const arr = prev.map((item, index) => {
                if (index === i) {
                    return { ...item, startSelectedIndex: 9, endSelectedIndex: 17, addHourBtnClicked: !item.addHourBtnClicked };
                }
                return item;
            });
            return arr;
        });
    };

    const handleTimeChange = (dayIndex, isStartTime, newIndex) => {
        setWeekValues(prev => {
            const updatedValues = [...prev];
            if (isStartTime) {
                if(newIndex >= updatedValues[dayIndex].endSelectedIndex){
                    updatedValues[dayIndex].endSelectedIndex = newIndex + 1;
                }
                updatedValues[dayIndex].startSelectedIndex = newIndex;
            } else {
                if(newIndex <= updatedValues[dayIndex].startSelectedIndex){
                    updatedValues[dayIndex].startSelectedIndex = newIndex - 1;
                }
                updatedValues[dayIndex].endSelectedIndex = newIndex;
            }
            return updatedValues;
        });
    };

    const handleApplyToAll = (dayIndex) => {
        setWeekValues(prev => {
            const updatedValues = [...prev];
            return updatedValues.map(day => {
                return {
                    ...day,
                    startSelectedIndex: updatedValues[dayIndex].startSelectedIndex,
                    endSelectedIndex: updatedValues[dayIndex].endSelectedIndex
                };
            });
        });
    }
    
    return (
        <div className='AvailableHours' style={{width:width}}>

            <div style={{cursor:"default",marginBottom:"1rem"}}>Availability Hours</div>

            {
            weekValues.map((item,index) => {

                const { day, addHourBtnClicked, startSelectedIndex, endSelectedIndex } = item
                
                return  <div 
                            key={index}
                            className='AvailableHoursDayRow'
                        >
                            <span style={{cursor:"default",width:"6rem"}}>{day}</span>
                            {
                            !addHourBtnClicked ?
                                <div className='AvailableHoursAddHoursBtn' onClick={()=>handleClick(index)}>
                                    Add hours
                                </div>
                            :
                            <div className='AvailableHoursContent'>
                                <div className='AvailableHoursContentTimeRange'>
                                    
                                    <TimeSelect 
                                        defaultSelection={hoursOfDay[startSelectedIndex]}
                                        items={hoursOfDay}
                                        itemsAreNotDisplayed = { (hoursOfDay.length-1) }
                                        selectedIndex={startSelectedIndex}
                                        setSelectedIndex={(hourIndex) => handleTimeChange(index, true, hourIndex)}
                                    />

                                    <span style={{margin:"0 .5rem"}}>-</span>
                                    
                                    <TimeSelect 
                                        defaultSelection={hoursOfDay[endSelectedIndex]}
                                        items={hoursOfDay}
                                        itemsAreNotDisplayed = { 0 }
                                        selectedIndex={endSelectedIndex}
                                        setSelectedIndex={(hourIndex) => handleTimeChange(index, false, hourIndex)}
                                    />

                                </div>
                                <div className='AvailableHoursContentImg' onClick={()=>handleClick(index)}>
                                    <img alt='' src={Trash} />
                                </div>
                            </div>
                            }
                            {
                            index === weekValues.findIndex(day => day.addHourBtnClicked) &&
                                <div className='AvailableHoursApplyToAll' onClick={()=>handleApplyToAll(index)}>
                                    Apply to all
                                </div>
                            }
                        </div>
            })
            }

            {/* <div className='AvailableHoursDayRow'>
                <div className='AvailableHoursFooterBtn AvailableHoursSaveBtn'>
                    Save
                </div>
                <div className='AvailableHoursFooterBtn AvailableHoursCancelBtn'>
                    Cancel
                </div>
            </div> */}
        </div>
    )
}
