import React, { useEffect, useState } from 'react'
import './SignUpPopUp.css'
import BlackX from '../images/icons/blackX.png'
import LeftArrow from '../images/icons/blackLeftArrow.png'
import Eye from '../images/icons/eye.png'
import InputText from './form-components/InputText'
import InputPassword from './form-components/InputPassword'
import { validatePassword } from '../utilities/validations'
import axios from '../api/axios'

const iconSize = {
    width: "20px",
    height: "20px"
}

export default function SignUpPopUp(props) {

    const {signupPopup, setSignupPopup, loginSignupPopup, setLoginSignupPopup, setOtpPopup, formDataSU, setAgencyCase} = props

    // Form Input Variables
    const [password, setPassword] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [isFNameValid, setIsFNameValid] = useState(true)
    const [isLNameValid, setIsLNameValid] = useState(true)


    const newUser = {
        username: formDataSU.username,
        password: password,
        firstName: fname,
        lastName: lname
    }

    // Clearing the Inputs
    useEffect(() => {
        if(!signupPopup && !loginSignupPopup){
            setFname('')
            setLname('')
            setPassword('')
            setIsPasswordValid(true)
            setIsFNameValid(true)
            setIsLNameValid(true)
        }
    },[signupPopup,loginSignupPopup])

    const fetchSignUpData = async () => {
        setAgencyCase(false)
        try {
            const response = await axios.post(`/otp/sendUser`, newUser);
            
            if(response.data.success){
                setSignupPopup(false)
                setOtpPopup(true)
            }

        } catch (error) {
            // console.error(error)
            console.log(error.response.data.message)
            // if(error.response.data === "User already exists")
            //  warning: The email isnt available
        }

    }

    const handleSignUpUser = () => {
        const passwordValidationResult = validatePassword(password);
        const fnameValidationResult = fname.trim()
        const lnameValidationResult = lname.trim()
        
        if(passwordValidationResult && fnameValidationResult && lnameValidationResult){
            fetchSignUpData()
        }
        else {
            !passwordValidationResult && setIsPasswordValid(false)
            !fnameValidationResult && setIsFNameValid(false)
            !lnameValidationResult && setIsLNameValid(false)
        }
    }

    return (
        signupPopup &&
            <div className='SignUpPopUpDarkBackground' onClick={()=>{setSignupPopup(false);setLoginSignupPopup(true)}}>
                <div className='SignUpPopUp' onClick={(e) => e.stopPropagation()}>
                    <div className='SignUpPopUpHeader'>
                        <div className='SignUpPopUpHeaderImg' onClick={()=>{setSignupPopup(false);setLoginSignupPopup(true)}}>
                            <img alt='' src={LeftArrow} style={{ width: iconSize.width, height: iconSize.height }} />
                        </div>
                        <span className='TwentyPx'>Sign Up</span>
                        <div className='SignUpPopUpHeaderImg' onClick={()=>setSignupPopup(false)}>
                            <img alt='' src={BlackX} style={{ width: iconSize.width, height: iconSize.height }} />
                        </div>
                    </div>
                    <form className='SignUpPopUpContent'>
                        <div className='SignUpPopUpContentFLName'>
                            <InputText 
                                placeholder = 'First Name'
                                id = 'fname'
                                name = 'fname'
                                value = {fname}
                                setValue = {setFname}
                                width = "45%"

                                isValid = {isFNameValid}
                                setIsValid = {setIsFNameValid}
                            />
                            <InputText 
                                placeholder = 'Last Name'
                                id = 'lname'
                                name = 'lname'
                                value = {lname}
                                setValue = {setLname}
                                width = "45%"

                                isValid = {isLNameValid}
                                setIsValid = {setIsLNameValid}
                            />
                        </div>
                        <div className='SignUpPopUpContentPassword'>
                            <InputPassword 
                                placeholder = 'Password'
                                id = 'password'
                                name = 'password'
                                value = {password}
                                setValue = {setPassword}

                                isValid = {isPasswordValid}
                                setIsValid = {setIsPasswordValid}
                                errorMessage = {"Password doesn't meet the requirments"}
                            />
                        </div>
                        <div className='SignUpPopUpContentBtn' onClick={handleSignUpUser}>
                            <span>Sign Up</span>
                        </div>
                    </form>
                    <div className='SignUpPopUpFooter'>
                        <span className='FourteenPx'>By registering, you agree to the </span>
                        <span className='FourteenPx' style={{marginLeft:".5rem",color:"#0DB4FC",textDecoration:"underline"}}>Terms of Use</span>
                        <span className='FourteenPx'>.</span>
                    </div>
                </div>
            </div>
    )
}
