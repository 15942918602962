import React from 'react'
import './ImageSlider.css'
import useOutsideClick from '../utilities/useOutsideClick';
import WhiteLeftArrow from '../images/icons/whiteLeftArrow.png' 
import WhiteRightArrow from '../images/icons/whiteRightArrow.png' 
import WhiteXIcon from '../images/icons/whitex.png' 

const iconSize = {
    width: "16px",
    height: "16px"
}

export default function ImageSlider({ images, showPopup, selectedImage, closePopup, nextImage, prevImage }) {

    return (
        showPopup && (
        <div className="ImageSliderPopUp">
            <div className='CloseBtn' onClick={closePopup}>
                <img alt='' src={WhiteXIcon} style={{width: iconSize.width,height: iconSize.height, marginRight:".5rem"}} />
                <span style={{fontWeight:"600",color:"white"}}>Close</span>
            </div>

            <div className='NavBtn PrevBtn' onClick={prevImage}>
                <img alt='' src={WhiteLeftArrow} style={{width: iconSize.width,height: iconSize.height}} />
            </div>

            <img alt='' src={images[selectedImage]} />
            
            <span style={{marginTop:"2rem",fontWeight:"600",color:"white"}}>{`${selectedImage+1} / ${images.length}`}</span>

            <div className='NavBtn NextBtn' onClick={nextImage}>
                <img alt='' src={WhiteRightArrow} style={{width: iconSize.width,height: iconSize.height}} />
            </div>

        </div>
        )
    )
}
