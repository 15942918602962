import { Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";
import { jwtDecode } from "jwt-decode";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();

    const decode = auth?.accessToken
        ? jwtDecode(auth.accessToken, process.env.ACCESS_TOKEN_SECRET_KEY)
        : undefined

    const roles = decode?.roles || []

    return (
        roles.find(role => allowedRoles?.includes(role)) ?
            <Outlet />
            :
            <Navigate to="/unauthorized" replace />
            // !auth.accessToken ? //!isLoading &&
            //     <Navigate to="/unauthorized" replace />
            //     :
            //     <Navigate to="/" replace />
    )
}

export default RequireAuth;