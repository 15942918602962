import React, { useState } from 'react'
import './NewLineTable.css'
import Button from '../../components/form-components/Button'
import TrashCan from '../../images/icons/white_trash_can.png'

export default function NewLineTable(props) {

    const {
        placeholder = '',
        label = '',
        items = [],
        handleAdd = null,
        handleRemove = null
    } = props

    const [internalValue, setInternalValue] = useState("")

    const handleChange = (e) => {
        setInternalValue(e.target.value)
    }

    const renderLines = items.map((item, index) => (
        <div 
            key={index}
            className='NewLineTableLine'
        >
            <div className='NewLineTableNumberText'>
                <div className='NewLineTableNumber'>
                    {index + 1}.
                </div>
                <div className='NewLineTableText'>
                    {item}
                </div>
            </div>

            <div className='NewLineTableDeleteBtn'>
                <Button 
                    icon = {TrashCan}
                    btnClass = "danger"
                    height = "100%"
                    width = "3rem"
                    handleSubmit = {()=>handleRemove(index)}
                />
            </div>
        </div>
    ));

    return (
        <div className='NewLineTable'>
            {label && 
                <span className='NewLineTableLabel'>{label}</span>
            }
            <div className='NewLineTableContainer'>
                <input 
                    type='text'
                    placeholder={placeholder}
                    value={internalValue}
                    onChange={handleChange}
                />

                <div style={{height:"100%",padding:".2rem", boxSizing:"border-box"}}>
                <Button 
                    name = "ADD"
                    height = "100%"
                    handleSubmit = {()=>{handleAdd(internalValue);setInternalValue("")}}
                />
                </div>
            </div>
            {renderLines}
        </div>
    )
}
