import React from 'react'
import './Inquiries.css'

export default function Inquiries() {
    return (
        <div className='Inquiries'>
            <div className='InquiriesHeader'>
                <span className='TwentyPx Bold'>Inquiries</span>
            </div>

            
        </div>
    )
}
