import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import LogInSignUpPopUp from '../components/LogInSignUpPopUp'
import Topbar from '../components/Topbar'
import Footer from '../components/Footer'
import OtpPopUp from '../components/OtpPopUp'
import SignUpPopUp from '../components/SignUpPopUp'
import LogInPopUp from '../components/LogInPopUp'

const ROLES = {
    User: "ROLE_USER",
    Agency: "ROLE_AGENCY"
}

export default function AppLayout() {
    
    // Pop Up Variables
    const [loginSignupPopup, setLoginSignupPopup] = useState(false)
    const [otpPopup, setOtpPopup] = useState(false)
    const [signupPopup, setSignupPopup] = useState(false)
    const [loginPopup, setLoginPopup] = useState(false)

    // SignUpPro Variables (SUP)
    const [formDataSUP, setFormDataSUP] = useState({
        username: '',
        password: '',
        name: '',
        managerCountryCode: '',
        managerMobilePhone: '',
        managerFirstName: '',
        managerLastName: '',
        gemiNumber: ''
    })
    const [formDataSU, setFormDataSU] = useState({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
    })
    //  LogIn Variables
    const [userEmail, setUserEmail] = useState('')

    const [agencyCase, setAgencyCase] = useState(null)

    return (
        <div className='App'>

            <Topbar 
                setLoginSignupPopup = {setLoginSignupPopup}
            />

            <Outlet 
                context={{
                    setLoginSignupPopup,
                    setOtpPopup,
                    setFormDataSUP,
                    
                    setAgencyCase
                }}
            />

            <Footer />

            <LogInSignUpPopUp
                loginSignupPopup = {loginSignupPopup}
                setLoginSignupPopup = {setLoginSignupPopup}

                loginPopup = {loginPopup}
                setLoginPopup = {setLoginPopup}
                signupPopup = {signupPopup}
                setSignupPopup = {setSignupPopup}
                setFormDataSU = {setFormDataSU}
                setUserEmail = {setUserEmail}
            />

            <OtpPopUp
                otpPopup = {otpPopup}
                setOtpPopup = {setOtpPopup}

                // path = {roles?.includes(ROLES.Agency) ? "/live/dashboard" : "/"}
                formData = {agencyCase ? formDataSUP : formDataSU}

                agencyCase = {agencyCase}
            />

            <SignUpPopUp
                signupPopup = {signupPopup}
                setSignupPopup = {setSignupPopup}

                loginSignupPopup = {loginSignupPopup}
                setLoginSignupPopup = {setLoginSignupPopup}
                setOtpPopup = {setOtpPopup}
                formDataSU = {formDataSU}

                setAgencyCase = {setAgencyCase}
            />

            <LogInPopUp
                loginPopup = {loginPopup}
                setLoginPopup = {setLoginPopup}

                loginSignupPopup = {loginSignupPopup}
                setLoginSignupPopup = {setLoginSignupPopup}
                userEmail = {userEmail}
                setUserEmail = {setUserEmail}
            />


        </div>
    )
}
