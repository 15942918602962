import React, { useState } from 'react'
import './PersonalDetails.css'
import ProfilePic from '../images/profilepic.jpg'
import InputText from '../components/form-components/InputText'
import InputEmail from '../components/form-components/InputEmail'
import InputNumber from '../components/form-components/InputNumber'
import InputDate from '../components/form-components/InputDate'
import Select from '../components/form-components/Select'
import AvailableHours from '../components/AvailableHours'
import InputTextArea from '../components/form-components/InputTextArea'
import MultipleSelect from '../components/form-components/MultipleSelect'
import Instagram from '../images/icons/instagramColored.png'
import Facebook from '../images/icons/facebookColored.png'
import TikTok from '../images/icons/tiktok.png'

const genders = ['Select your gender','Male','Female','Other']

export default function PersonalDetails() {

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    // Country Code Variable ?
    const [phoneNumber, setPhoneNumber] = useState()
    const [birthDate, setBirthDate] = useState() // TODO save to the variable
    const [sexIndex, setSexIndex] = useState()

    const [availabilityHours, setAvailabilityHours] = useState('') // TODO save to the variable
    const [aboutMe, setAboutMe] = useState('')

    // Multiple Select Indexes
    const [interestsIndexes, setInterestsIndexes] = useState([])
    const [languagesIndexes, setLanguagesIndexes] = useState([])
    const [countriesIveVisitedIndexes, setCountriesIveVisitedIndexes] = useState([])
    const [countriesIveLivedIndexes, setCountriesIveLivedIndexes] = useState([])

    return (
        <div className='PersonalDetails'>
            <div className='PersonalDetailsHeader'>
                <span className='TwentyPx Bold'>Personal Details</span>
            </div>
            <div className='PersonalDetailsContent'>
                <div className='PersonalDetailsContentPhotoContainer'>
                    <div className='PersonalDetailsContentPhoto'>
                        <img alt='' src={ProfilePic} />
                    </div>
                    <div className='PersonalDetailsContentGetVerifiedBtn' style={{marginLeft:"2rem"}}>
                        Get Verified
                    </div>
                </div>
                <form className='PersonalDetailsContentInfo'>

                    <div className='PersonalDetailsContentInfoRow'>
                        <InputText 
                            label = 'First Name'
                            placeholder = 'First Name'
                            id = 'fname'
                            name = 'fname'
                            value = {fname}
                            setValue = {setFname}
                            width = "45%"
                        />
                        
                        <InputText 
                            label = 'Last Name'
                            placeholder = 'Last Name'
                            id = 'lname'
                            name = 'lname'
                            value = {lname}
                            setValue = {setLname}
                            width = "45%"
                        />
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <InputEmail 
                            label = 'Email'
                            placeholder = 'Email'
                            id = 'email'
                            name = 'email'
                            value = {email}
                            setValue = {setEmail}
                            width = "45%"
                        />
                        <InputNumber 
                            label = 'Phone Number'
                            placeholder = 'Phone Number'
                            id = 'phonenumber'
                            name = 'phonenumber'
                            value = {phoneNumber}
                            setValue = {setPhoneNumber}
                            width = "45%"
                            isTelephoneNumber = {true}
                            maxNumbers = {11}
                        />
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <InputDate 
                            label = 'Birth Date'
                            id = 'birthdate'
                            name = 'birthdate'
                            value = {birthDate}
                            setValue = {setBirthDate}
                            width = "45%"
                        />

                        <Select 
                            label = 'Gender'
                            id = 'gender'
                            name = 'gender'

                            items = {genders}
                            selectedIndex = {sexIndex}
                            setSelectedIndex = {setSexIndex}
                            width = "45%"
                        />
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <AvailableHours />
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <span className='TwentyPx Bold'>About Me</span>
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <InputTextArea 
                            placeholder = 'About Me'
                            id = 'aboutme'
                            name = 'aboutme'
                            value = {aboutMe}
                            setValue = {setAboutMe}
                            height = "10rem"
                        />
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <MultipleSelect 
                            label = 'Interests'
                            id = 'interests'
                            name = 'interests'

                            items = {["Snowboard", "Sky Diving", "Rafting"]}
                            defaultSelection = {"Interests"}
                            selectedIndexes = {interestsIndexes}
                            setSelectedIndexes = {setInterestsIndexes}
                            width = "45%"
                        />

                        <MultipleSelect 
                            label = 'Languages'
                            id = 'languages'
                            name = 'languages'

                            items = {["Greek", "English", "Spanish"]}
                            defaultSelection = {"Languages"}
                            selectedIndexes = {languagesIndexes}
                            setSelectedIndexes = {setLanguagesIndexes}
                            width = "45%"
                        />
                    </div>

                    <div className='PersonalDetailsContentInfoRow'>
                        <MultipleSelect 
                            label = "Countries I've Lived"
                            id = 'countrieslived'
                            name = 'countrieslived'

                            items = {["Greece", "America", "Spain"]}
                            defaultSelection = {"Countries I've Lived"}
                            selectedIndexes = {countriesIveLivedIndexes}
                            setSelectedIndexes = {setCountriesIveLivedIndexes}
                            width = "45%"
                        />

                        <MultipleSelect 
                            label = "Countries I've Visited"
                            id = 'countriesvisited'
                            name = 'countriesvisited'

                            items = {["Greece", "America", "Spain"]}
                            defaultSelection = {"Countries I've Visited"}
                            selectedIndexes = {countriesIveVisitedIndexes}
                            setSelectedIndexes = {setCountriesIveVisitedIndexes}
                            width = "45%"
                        />
                    </div>

                    <div className='PersonalDetailsContentInfoRow' style={{marginTop:"2rem"}}>
                        <div className='PersonalDetailsSocialBtn'>
                            <img alt='' src={Instagram} />
                            <span>Connect Instagram</span>
                        </div>
                        <div className='PersonalDetailsSocialBtn'>
                            <img alt='' src={Facebook} />
                            <span>Connect Facebook</span>
                        </div>
                        <div className='PersonalDetailsSocialBtn'>
                            <img alt='' src={TikTok} style={{width:"36px",height:"36px"}} />
                            <span>Connect Tik Tok</span>
                        </div>
                    </div>

                </form>
                <div className='PersonalDetailsContentBtnContainer'>
                    <div className='PersonalDetailsContentBtn'>Save Changes</div>
                </div>
            </div>
        </div>
    )
}
