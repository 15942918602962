import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import Reviews from '../components/Reviews';
import axios from '../api/axios';

export default function AgentReviews() {

    // const navigate = useNavigate();
    const {agencyId, aboutDetails} = useOutletContext()
    const {rate} = aboutDetails

    const [reviewsDetails, setReviewsDetails] = useState([])
    const {agencyReviews, ratingsCountResponseList} = reviewsDetails

    const fetchReviewsData = async () => {
        try {

            const response = await axios.get(`/agencies/${agencyId}/reviews?pageSize=10&pageNumber=1`);
  
            setReviewsDetails(response.data);
            console.log(response.data)

        } catch (error) {
            // navigate("/")
            console.error(error)
        }
    };

    useEffect(() => {
        fetchReviewsData()
    },[agencyId])


    return (
        <div className='AgentReviews'>
            <Reviews 
                rate = {rate}
                ratingsCountResponseList = {ratingsCountResponseList}
                reviews = {agencyReviews}
                reviewTypeName = "agency"
            />
        </div>
    )
}
