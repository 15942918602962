import React, { useEffect, useState } from 'react'
import './PackageDetailsCard.css'
import Calendar from '../images/icons/calendar.png'
import Seats from '../images/icons/seats.png'
import People from '../images/icons/people.png'
import CallUs from '../images/icons/callus.png'
import Clock from '../images/icons/clock.png'
import Inquiry from '../images/icons/inquiry.png'
import Hotel from '../images/icons/hotel.png'
import Flag from '../images/icons/flag.png'
import BlackOutlineHeart from '../images/icons/blackoutlineheart.png'
import FilledHeart from '../images/icons/filledheart.png'
import BlackOutlineShare from '../images/icons/blackoutlineshare.png'
import { formatDate } from '../utilities/dateUtils';
import RatingStars from './RatingStars'
import { Link, useSearchParams } from 'react-router-dom'
import Button from './form-components/Button'
import TravellersBoxes from './TravellersBoxes'
import ChangePopUp from './ChangePopUp'
import PaymentHotels from './PaymentHotels'
import PaymentDates from './PaymentDates'
import PaymentStartingPoints from './PaymentStartingPoints'
import axios from '../api/axios'

const iconSize = {
    width: "24px",
    height: "24px"
}

export default function PackageDetailsCard(props) {
    
    const { 
        packageId
    } = props

    // Get search params
    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const startingPointParam = searchParams.get('startingPoint');
    const adultsParam = searchParams.get('adults') || 1;
    const childrenParam = searchParams.get('children') || 0;
    const infantsParam = searchParams.get('infants') || 0;
    const petsParam = searchParams.get('pets') || 0;
    const hotelsParam = searchParams.get('hotels');

    // Dates Variables
    const [selectedDate, setSelectedDate] = useState(dateParam)

    // Starting Points Variables
    const [startingPointsPopupIsOpen, setStartingPointsPopupIsOpen] = useState(false)
    const [selectedStartingPoint, setSelectedStartingPoint] = useState({sp:parseInt(startingPointParam,10),isValid:true})

    // Travellers Variables
    const [travellersPopupIsOpen, setTravellersPopupIsOpen] = useState(false)
    const [adults,setAdults] = useState(parseInt(adultsParam,10))
    const [children,setChildren] = useState(parseInt(childrenParam,10))
    const [infants,setInfants] = useState(parseInt(infantsParam,10))
    const [pets,setPets] = useState(parseInt(petsParam,10))

    // Hotels Variables
    const [hotelsPopupIsOpen, setHotelsPopupIsOpen] = useState(false)
    const [selectedHotels, setSelectedHotels] = useState([])
    
    // Values after save
    // selectedDate for dates
    const [startingPoint, setStartingPoint] = useState(selectedStartingPoint)
    const [travellers, setTravellers] = useState({adults,children,infants,pets})
    const [hotels, setHotels] = useState([])

    // Component Variables
    const [packageInfo, setPackageInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [hotelsValuesValid, setHotelsValuesValid] = useState(false)
    const [startingPointValueValid, setStartingPointValueValid] = useState(false)
    const [startingPointText, setStartingPointText] = useState("")
    const [liked, setLiked] = useState(false)

    const {
        agency,
        agencyId,
        cities,
        countries,
        currency,
        dates,
        days,
        availableHotels,
        logoPhoto,
        name,
        nights,
        rate,
        startingPointInfoResponseList,
        priceFrom,
        availability,
        agencyCountryCode,
        agencyPhone,
        workingHours
    } = packageInfo

    const getInfo = async () => {
        try {
            const response = await axios.get(`/booking/${packageId}/bookInfo?date=${dateParam}`)
            
            console.log("PackageInfo:",response.data)
            setPackageInfo(response.data)
            initializeHotels(response.data.availableHotels)
            // Show Starting Point Text if searchparam exists
            response.data.startingPointInfoResponseList.some(spnt => spnt.id === selectedStartingPoint.sp) && setStartingPointValueValid(true)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getInfo()
    }, [packageId,dateParam]);

    const initializeHotels = (locations) => {
        
        const initialHotels = Array.from({ length: locations.length }, (_, index) => ({
            selectedHotel: { hotelId: -1, isHotelValid: true },
            selectedRooms: [],
        }));

        // url -> hotels=63-201-1,64-330-2,64-331-1
        // 1. CheckUrlKey
        // 2. CheckUrlValues
        // 3. CheckLocalStorageVariables

        if(hotelsParam){
            const hotelMap = [];
            hotelsParam.split(',').forEach(item => {
                const [hotelId, roomId, quantity] = item.split('-').map(Number);
                
                let hotel = hotelMap.find(h => h.hotelId === hotelId);

                if (!hotel) {
                    hotel = {
                        hotelId,
                        rooms: [],
                    };
                    hotelMap.push(hotel);
                }
                
                hotel.rooms.push({
                    roomId,
                    quantity,
                });
            });

            if(hotelMap.length === locations.length){

                hotelMap.forEach((item,index) => {
                    const {hotelId, rooms} = item

                    const hotelIdExists = locations[index].hotelList.some(hotel => hotel.hotelId === hotelId)
                    const hotelIdIndex = locations[index].hotelList.findIndex(hotel => hotel.hotelId === hotelId)
                    console.log("hotelIdExists",hotelIdExists,hotelIdIndex)
                    if(hotelIdExists){
                        const hotelListRoomIdsSet = new Set(locations[index].hotelList[hotelIdIndex].rooms.map(room => room.roomId));
                        const allRoomIdExist = rooms.every(room => hotelListRoomIdsSet.has(room.roomId))
                        // check quantity too

                        console.log("allRoomIdExist",allRoomIdExist)
                        if(allRoomIdExist){ 
                            initialHotels[index].selectedHotel.hotelId = hotelId

                            rooms.forEach(room => {

                                initialHotels[index].selectedRooms.push({
                                    roomId: room.roomId,
                                    quantity: room.quantity,
                                    isQtyValid: true
                                })
                            })

                            setHotelsValuesValid(true)
                                
                        } else {
                            // console.log("Couldnt find room id or quantity isnt valid")
                            // setInvalidMessage("Couldnt find room id or quantity isnt valid")
                        }
                    } else {
                        // console.log("Couldnt find hotel id")
                        // setInvalidMessage("Couldnt find hotel id")
                    }
                    
                });

            } else {
                // console.log("Not same length of hotel ids with locations")
                // setInvalidMessage("Not same length of hotel ids with locations")
            }

        }

        console.log("initialHotels",initialHotels)

        setSelectedHotels(initialHotels)
        setHotels(initialHotels)
    }

    const updateSearchParam = (key, value) => {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.set(key, value);
        setSearchParams(newSearchParams);
    };
    const updateSearchParams = (...args) => {
        if (args.length % 2 !== 0) {
            throw new Error("Arguments must be in pairs");
        }
    
        const newSearchParams = new URLSearchParams(searchParams.toString());
    
        for (let i = 0; i < args.length; i += 2) {
            const key = args[i];
            const value = args[i + 1];
            newSearchParams.set(key, value);
        }
    
        setSearchParams(newSearchParams);
    };

    const handleClickDate = (departureDate) => {
        // Update Search Params
        updateSearchParam("date",selectedDate);
       
        // Update Date
        setSelectedDate(departureDate)
    }

    const handleSelectedStartingPoint = (startingPoint) => {
        setSelectedStartingPoint({sp:startingPoint, isValid:true})
    }

    const handleSaveStartingPoint = () => {

        // Check Starting Point Validation
        let saveValidation = startingPointInfoResponseList.some(spnt => spnt.id === selectedStartingPoint.sp)

        if(saveValidation){
            setStartingPointsPopupIsOpen(false)
            setStartingPoint(selectedStartingPoint)
            // setting the SearchParams
            updateSearchParam("startingPoint",selectedStartingPoint.sp);

            setStartingPointValueValid(true)
        } else {
            setSelectedStartingPoint(prev => {
                return {
                    ...prev,
                    isValid: false
                }
            })
        }
    }

    const handleCancelStartingPoint = () => {
        setSelectedStartingPoint(startingPoint)
    }

    const handleSaveTravellers = () => {
        setTravellersPopupIsOpen(false)

        // Update Search Params
        updateSearchParams("adults",adults,"children",children,"infants",infants,"pets",pets)

        // Update Travellers
        setTravellers({adults,children,infants,pets})
    }
    const handleCancelTravellers = () => {
        // Initialize when closing popup
        setAdults(travellers.adults)
        setChildren(travellers.children)
        setInfants(travellers.infants)
        setPets(travellers.pets)
    }

    // For Room Selects
    const handleSelectChange = (locationIndex, hotelIndex, roomIndex, selectedIndex) => {
        // console.log("Select: ", locationIndex, hotelIndex, roomIndex, selectedIndex)

        setSelectedHotels(prev => {
            const updatedHotels = prev.map((location, locIndex) => {
                if (locIndex === locationIndex) {
                    return {
                        ...location,
                        selectedRooms: location.selectedRooms.map((room, rIndex) => {
                            if (rIndex === roomIndex) {
                                return {
                                    ...room,
                                    roomId: availableHotels[locationIndex].hotelList[hotelIndex].rooms[selectedIndex].roomId
                                };
                            }
                            return room;
                        })
                    };
                }
                return location;
            });
    
            // console.log("Before:", prev);
            // console.log("After:", updatedHotels);
            return updatedHotels;
        })
    };

    const sumUpSimilarRooms = (hotels) => {
        return hotels.map(hotel => {
            const roomMap = new Map();
    
            hotel.selectedRooms.forEach(room => {
                if (roomMap.has(room.roomId)) {
                    const existingRoom = roomMap.get(room.roomId);
                    existingRoom.quantity += room.quantity;
                } else {
                    roomMap.set(room.roomId, { ...room });
                }
            });
    
            return {
                ...hotel,
                selectedRooms: Array.from(roomMap.values())
            };
        });
    };

    const handleSaveHotels = () => {

        // Check Hotels & QTY Validation
        let saveValidation = !selectedHotels?.some(hotel =>
            hotel.hotelId === -1 || hotel.selectedRooms.length <= 0 || hotel.selectedRooms?.some(room => room.quantity < 1 || isNaN(room.quantity))
        );
        console.log("saveValidation",saveValidation)

        if(saveValidation){
            setHotelsPopupIsOpen(false)

            // Sum up the potential similar rooms and quantities
            const updatedHotels = sumUpSimilarRooms(selectedHotels);

            setSelectedHotels(updatedHotels)
            setHotels(updatedHotels)
            // setting the SearchParams

            let hotelsSearchParam = [];

            updatedHotels.forEach((hotel, hotelIndex) => {
                let rooms = hotel.selectedRooms.map(room => `${hotel.selectedHotel.hotelId}-${room.roomId}-${room.quantity}`);
                hotelsSearchParam.push(rooms.join(","));
            });

            updateSearchParam("hotels",hotelsSearchParam.join(","))

            setHotelsValuesValid(true)
            // TODO: Check this below
            // Replace %2C with ,
            let url = window.location.href;
            url = url.replace(/%2C/g, ",");
            window.history.replaceState({}, document.title, url);

        } else {
            // setting isHotelValid and/or isQtyValid to false
            setSelectedHotels(prevHotels => prevHotels.map(hotel => ({
                ...hotel,
                selectedHotel: {
                    ...hotel.selectedHotel,
                    isHotelValid: hotel.hotelId !== -1 && hotel?.selectedRooms?.length > 0
                },
                selectedRooms: hotel?.selectedRooms?.map(room => ({
                    ...room,
                    isQtyValid: room.quantity >= 1 && !isNaN(room.quantity)
                }))
            })));
        }

    }

    const handleCancelHotels = () => {
        // Initialize when closing popup
        setSelectedHotels(hotels)
    }

    useEffect(() => {
        getStartingPointName(packageInfo,startingPoint.sp)
    },[packageInfo,startingPoint])

    const getStartingPointName = (packageInfo, startingPoint) => {
        const startingPointObj = packageInfo.startingPointInfoResponseList?.find(item => item.id === startingPoint)
        console.log(startingPointObj)
        setStartingPointText(startingPointObj ? startingPointObj.name : "")
    };

    const handleBook = (event) => {
        if (!startingPointValueValid || !hotelsValuesValid) {
          event.preventDefault();
          alert("Complete your booking values");
        }
    };

    return (
        <div className='PackageDetailsCard'>
            <div className='FavoriteShare'>
                <div className='FavoriteShareBtn' onClick={()=>setLiked(v=>!v)} >
                    <img alt='' src={!liked ? BlackOutlineHeart : FilledHeart} style={{width: iconSize.width, height: iconSize.height}} />
                </div>
                <div className='FavoriteShareBtn'>
                    <img alt='' src={BlackOutlineShare} style={{width: iconSize.width, height: iconSize.height}} />
                </div>
            </div>
            <div className='BasicInfoAgentLogo'>
                <div className='BasicInfo'>
                    <span className='Bold' style={{fontSize:"24px",marginBottom:"1rem"}} >{name}</span>
                    <span style={{marginBottom:"1rem"}}>{days} days - {nights} nights - {countries} Country - {cities} Cities</span>
                    <div className='BasicInfoPriceFrom'>
                        <span className='Bold' style={{marginRight:".5rem"}}>From</span>
                        <span className='Bold' style={{fontSize:"24px",marginRight:".5rem"}} >€ {priceFrom}</span>
                        <span>/person</span>
                    </div>
                    <div className='BasicInfoReviews'>
                        <span style={{marginRight:".85rem"}}>{rate?.toFixed(1)}</span> {/*1rem - 0.15rem */}
                        <RatingStars rate={rate} />
                    </div>
                </div>
                <div className='AgentLogoCard'
                    style={{
                        backgroundImage: `url('${logoPhoto}')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                </div>
            </div>

            {/* Dates */}
            <div className='AvailableDates'>
                <div className='AvailableDatesIconTitle'>
                    <img alt='' src={Calendar} style={{width: iconSize.width, height: iconSize.height}} />
                    <span style={{marginLeft:"1rem"}}>Selected Date:</span>
                </div>
                <div className='AvailableDatesButtons'>
                    { isLoading ?
                    <></> // TODO: Loading for the whole card
                    :
                    <PaymentDates 
                        dates = {dates}
                        selectedDate = {selectedDate}
                        handleSelectedDate = {handleClickDate}
                    />
                    }
                </div>
            </div>

            {/* Travellers */}
            <div className='TravellersNumber'>
                <img alt='' src={People} style={{width: iconSize.width, height: iconSize.height,marginRight:"1rem"}} />
                <div 
                    className='TravellersNumberSelect'
                    onClick={()=>setTravellersPopupIsOpen(true)}
                >
                    {travellers.adults + travellers.children + " Travellers"}
                    {travellers.infants!==0 && (", " + travellers.infants + "Babies")}
                    {travellers.pets!==0 && (", " + travellers.pets + "Pets")}
                </div>

                <ChangePopUp
                    popupIsOpen = {travellersPopupIsOpen}
                    setPopupIsOpen = {setTravellersPopupIsOpen}
                    title = "Travellers"
                    handleSubmit = {handleSaveTravellers}
                    handleClear = {handleCancelTravellers}
                >
                    <TravellersBoxes
                        adults = {adults}
                        children = {children}
                        infants = {infants}
                        pets = {pets}
                        setAdults = {setAdults}
                        setChildren = {setChildren}
                        setInfants = {setInfants}
                        setPets = {setPets}
                    />
                </ChangePopUp>
            </div>

            {/* Hotels */}
            <div className='PackageDetailsCardHotels'>
                <img alt='' src={Hotel} style={{width: iconSize.width, height: iconSize.height,marginRight:"1rem"}} />
                <div 
                    className='PackageDetailsCardHotelsSelect'
                    onClick={()=>setHotelsPopupIsOpen(true)}
                >
                {
                    hotels.length > 0 && hotelsValuesValid ?
                        `${hotels.length} Selected Hotels`
                        :
                        "Choose Hotels"
                }
                </div>

                <ChangePopUp
                    popupIsOpen = {hotelsPopupIsOpen}
                    setPopupIsOpen = {setHotelsPopupIsOpen}
                    title = "Hotels"
                    handleSubmit = {handleSaveHotels}
                    handleClear = {handleCancelHotels}
                >
                    <PaymentHotels 
                        selectedHotels = {selectedHotels}
                        setSelectedHotels = {setSelectedHotels}
                        handleSelectChange = {handleSelectChange}
                        
                        locationsChoices = {availableHotels}
                    />
                </ChangePopUp>
            </div>

            {/* Starting Points */}
            <div className='PackageDetailsCardHotels'>
                <img alt='' src={Flag} style={{width: iconSize.width, height: iconSize.height,marginRight:"1rem"}} />
                <div 
                    className='PackageDetailsCardHotelsSelect'
                    onClick={()=>setStartingPointsPopupIsOpen(true)}
                >
                {
                    startingPointValueValid ?
                        startingPointText
                        :
                        "Choose Starting Point"
                }
                </div>

                <ChangePopUp
                    popupIsOpen = {startingPointsPopupIsOpen}
                    setPopupIsOpen = {setStartingPointsPopupIsOpen}
                    title = "Starting Points"
                    handleSubmit = {handleSaveStartingPoint}
                    handleClear = {handleCancelStartingPoint}
                >
                    <div style={{padding: "2rem 0",boxSizing: "border-box"}}>
                        <PaymentStartingPoints 
                            startingPoints = {startingPointInfoResponseList}
                            selectedStartingPoint = {selectedStartingPoint}
                            handleSelectedStartingPoint = {handleSelectedStartingPoint}
                        />
                    </div>
                </ChangePopUp>
            </div>

            {/* Available Seats */}
            <div className='AvailableSeatsStatus'>
                <img alt='' src={Seats} style={{width: iconSize.width, height: iconSize.height}} />
                <span style={{marginLeft:"1rem"}}>{availability}</span>
            </div>

            <div className='AgentContactDetails'>
                <span className='Bold'>Need Help ?</span>
                <div className='AgentContactDetailsIconText'>
                    <div className='AgentContactDetailsIconTextRow'>
                        <img alt='' src={CallUs} style={{width: iconSize.width, height: iconSize.height}} />
                        <span style={{margin:"0 1rem"}}>Call us on: </span>
                        <span className='Bold'>{agencyCountryCode}{" "}{agencyPhone}</span>
                    </div>
                    <div className='AgentContactDetailsIconTextRow'>
                        <img alt='' src={Clock} style={{width: iconSize.width, height: iconSize.height}} />
                        <span style={{margin:"0 1rem"}}>Timing: </span>
                        <span className='Bold'>{workingHours}</span>
                    </div>
                </div>
            </div>
            <div className='SendInquiry'>
                {/* <div className='SendInquiryBtn'>
                    <img alt='' src={Inquiry} style={{width: iconSize.width, height: iconSize.height,marginRight:"1rem"}} />
                    <span className='Bold TwentyPx'>Send Inquiry</span>
                </div> */}
                <Link 
                    to={`/reserve/${packageId}?date=${dateParam}&startingPoint=${startingPointParam}&adults=${adultsParam}&children=${childrenParam}&infants=${infantsParam}&pets=${petsParam}&hotels=${hotelsParam}`}
                    style={{textDecoration:"none"}}
                    onClick={handleBook}
                >
                    <Button 
                        name = "BOOK NOW"
                        width = "17rem"
                        height = "3.5rem"
                    />
                </Link>
            </div>
            
            
        </div>
    )
}
